.inputContainer {
  &.has-error {
    input, textarea {
      border-color: $brand-danger;
    }

    .error {
      color: $brand-danger;
    }
  }
}

input::placeholder {
  color: $input-color-placeholder;
}


.tiptoiDownloadSuccess {
  font-size: 0.875em;
  line-height: 1.5;

  > div {
    padding: 10px;
  }
}

.with-endereco {
  .inputContainer {
    &.street {
      display: none;
    }
  }
}

.inputContainer.endereco-suggestion {
  position: relative;
}

.endereco-suggest {
  position: absolute;
  z-index: 22;
  background: white;
  top: 0;
  left: 0;
  right: 0;
  border: 1px solid #d7d7d7;
  max-height: 50vh;
  overflow: auto;

  .item {
    > div {
      padding: 5px;
    }

    &.active, &:hover {
      background-color: #90c2e7;
    }
  }
}

input {
  color: $gray;
}

a.facetcheckbox {
  padding: 2px 0 9px 24px;
  background: url(/images/check-off.png) left 3px no-repeat;
  display: block;
  color: $font-color;

  &:hover {
    text-decoration: none;
  }

  &[data-checked="1"] {
    background-image: url(/images/check.png);
    color: $brand-primary;
  }
}

.radioCheckContainer {
  &.focused {
    outline: 5px auto -webkit-focus-ring-color;
  }
}