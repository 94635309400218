
.icon { 
	background: $icons-sprite;
	background-size: 330px 600px;   //if you change the values also change them at the end of the file
}

.icon.menu{
	background-position:0 0;
	width:60px;
	height:60px;	
}
.icon.closer{
	background-position:0 -60px;
	width:60px;
	height:60px;
	border:1px solid $menu-border;
}

.icon.closer.small{
	width: 30px;
	height: 30px;
	background-position: -16px -76px;
	transform: scale(0.5);
	position: relative;
	top: -3px;
	border: none;
	cursor: pointer;
	float: right;
}

.icon.logo{
	background-position: -156px -160px;
	width:174px;
	height:80px;
	float:left;
	display:block;
	background-color:$brand-primary;
	@include media-breakpoint-down(xs) {
		background-position: -156px -170px;
		height:60px;
	}
}

.icon.myAccount{
	background-position:0 -180px;
	width:60px;
	height:60px;
	display:block;
	position:absolute;
	top:0;
	right:67px;
	display:none !important;
	@include media-breakpoint-down(xs) {
      display: block !important;
    }
}

.icon.myAccountWhite{
	background-position:-108px -120px;
	width:48px;
	height:60px;
	float:left;
	display:block;
	cursor:pointer;
	//margin-top:10px;
	@include media-breakpoint-down(xs) {
      display: none;
    }
}

.icon.flagSphere{
	width:60px;
	height:60px;
	position:absolute;
	cursor: pointer;
	top:12px;
	right:125px;
	display:none !important;
	@include media-breakpoint-down(xs) {
      display: block !important;
    }
}

.icon.wishlist{
	background-position:0 -120px;
	width:60px;
	height:60px;
	display:block;
	position:absolute;
	top:0;
	right:7px;
	display:none !important;
	@include media-breakpoint-down(xs) {
      display: block !important;
    }
	
	.numberCircle{
		position:absolute;
		right:6px;
		top:10px;
		border-color:$white;
	}
}

.baseIcon {
	
	> .audio {
		background: url($icon-volume-up-white) no-repeat center /contain;
		height: 100px;
		width: 100px;
		margin: 0 auto;
	
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width: 45px;
			height: 45px;
		}
	}
	
	&.greyAudiobook {
		background: url($icon-audiobook-grey) no-repeat center /contain;
		height: 24px;
		width: 20px;
		float: right;
		margin-right: 10px;
		margin-top: 9px;
		
		@media (max-width: 615px) and (min-width: $tileBreakpoint) {
			width: 18px;
			height: 22px;
			margin-top: 6px;
			margin-right: 8px;
		}
	}
	
	.blink {
		animation: play 2s infinite;
		
	}
	@keyframes play {
		66% {background: url($icon-volume-down-white) no-repeat center /contain;}
		33% {background: url($icon-volume-none-white) no-repeat center /contain;}
		100% {background: url($icon-volume-up-white) no-repeat center /contain;}
	}
}

.whiteHoverBackground{
	position:absolute;
	top:70px;
	right:0px;
	width:350px;
	background-color:$white;
	padding:30px 15px 30px 30px;
	display:none;
	color:$font-color;
	
	@include media-breakpoint-down(xs) {
	  display:none !important;
	}
	
	-webkit-box-shadow: 0px 5px 7px -2px rgba(0,0,0,0.23);
	-moz-box-shadow: 0px 5px 7px -2px rgba(0,0,0,0.23);
	box-shadow: 0px 5px 7px -2px rgba(0,0,0,0.23);
}

#whislistContainer{
	float:left;
	position:relative;
	width:48px;
	height:70px;
	margin-top:10px;
	font-size: .875rem;
	
	.whiteHoverBackground{right:-58px;}
	
	@include media-breakpoint-down(xs) {
	  display: none;
	}
	
	.menuWhislistContainer{
		padding:15px 0;
		border-bottom:2px solid $light-grey;
		
		.shoppingCartButtonContainer{float:right;width:51px;}
		.deleteButtonContainer{float:right;width:70px}
		.imageSmall{float:left;width:75px}
		.titleAndPrice{float:left;width:220px;padding-left:10px;}
		.title{font-weight:bold;}
		.availableContainer{float:left;width:70%}
		.price{
			padding-top:15px;
			font-weight:bold;
			font-size: 1rem;
			float:left;width:30%;text-align:left;
			.oldPrice{color:$dark-grey;text-decoration:line-through;font-weight:normal;font-size: .875rem;}
			.newPrice{color:$orange3;}
		
		}
		.btn-xs{width:28px;height:28px;font-size:.8rem;padding:0.18rem 0.4rem;}
		a{
			text-decoration:none;
		}
		a:hover{
			text-decoration:none;
			font-weight:bold;
		}
	}
	
	
}//end whislistContainer

#myAccountContainer{
	float:left;
	position:relative;
	width:48px;
	height:70px;
	margin-top:10px;
	font-size: .875rem;
	
	a{
		text-decoration:none;
	}
	a:hover{
		text-decoration:none;
		font-weight:bold;
	}
	
	.whiteHoverBackground{right:-106px;}
	
	@include media-breakpoint-down(xs) {
	  display: none;
	}
	
	.menuWhislistContainer{
		padding:15px 0;
		border-bottom:2px solid $light-grey;
		
		.shoppingCartButtonContainer{float:left;width:51px;}
		.deleteButtonContainer{float:left;width:70px}
		.imageSmall{float:left;width:75px}
		.titleAndPrice{float:left;width:284px;padding-left:10px;}
		.title{font-weight:bold;}
		.availableContainer{float:left;width:70%}
		.price{
			font-weight:bold;
			float:right;width:30%;text-align:right;
			.oldPrice{color:$dark-grey;text-decoration:line-through;}
			.newPrice{color:$orange3;}
		
		}
	}
	
	
}//end myAccountContainer



#menuShoppingCart{
	float:left;
	position:relative;
	width:48px;
	height:70px;
	margin-top:10px;
	font-size: .875rem;
	@include media-breakpoint-down(xs) {
      margin-top:0px;
	  height:60px;
    }
	
	.whiteHoverBackground{
		right:-10px;
	}
	
	.menuShoppingCartContainer{
		padding:15px 0;
		border-bottom:2px solid $light-grey;
		width:305px;
	
		.deleteButtonContainer{float:left;width:70px}
		.imageSmall{float:left;width:75px}
		.number{float:left;width:51px;text-align:right}
		.titleAndPrice{float:left;width:220px;padding-left:10px;}
		.title{font-weight:bold;}
		.availableContainer{float:left;width:70%}
		.price{
			font-size: 1rem;
			font-weight:bold;
			float:left;width:40%;text-align:left;
			.oldPrice{color:$dark-grey;text-decoration:line-through;font-weight:normal;font-size: .875rem;}
			.newPrice{color:$orange3;}
		
		}
		.btn-xs{width:28px;height:28px;font-size:.8rem;padding:0.18rem 0.4rem;}
		.input_menge_header{padding:0}
		a{
			text-decoration:none;
		}
		a:hover{
			text-decoration:none;
			font-weight:bold;
		}
	}//end menuShoppingCartContainer
	
	.summaryContainer{
		margin-top:10px;
		width:480px;
		.titleLeft{float:left;padding-top:6px;font-weight:bold;}
		.priceRight{
			float:right;
			text-align:right;
			
			.price{font-size:1.625rem;font-weight:bold;}
			.btn{margin-top:10px;}	
		}
	}//end summaryContainer
}// end menuShoppingCart


.icon.search{
	background-position:-244px 0;
	width:48px;
	height:60px;
	float:left;
	display:none;
	cursor:pointer;
	margin-top:10px;
	@media (max-width: 1266px) {
	  display: block;
	}
	
	@include media-breakpoint-down(xs) {
      //display: block;
	  margin-top:0px;
    }
}

.icon.wishlistWhite{
	background-position:-108px -60px;
	width:48px;
	height:60px;
	float:left;
	display:block;
	cursor:pointer;	
	position:absolute;
	top:0;
	left:0;
}

.icon.menuShoppingCart{
	background-position:-108px 0;
	width:48px;
	height:60px;
	position:absolute;
	left:0;
	top:0;
	display:block;
}

.icon.searchIconWhiteSmall{
	background-position:-71px -16px;
	width:28px;
	height:29px;
}


.icon.arrowWhiteTop{
	background-position:-156px 0;
	width:28px;
	height:14px;
}

.icon.arrowWhiteTopWithGreyBorder{
	background-position:-184px 0;
	width:28px;
	max-height:53px;
}

.icon.arrowWhiteTopAbsolute, .icon.arrowWhiteTopWithGreyBorderAbsolute{
	position:absolute;
	
	display:none;
	
	@include media-breakpoint-down(xs) {
	  display:none !important;
	}
}
.icon.arrowWhiteTopAbsolute{bottom:-3px;}

.icon.arrowWhiteTopWithGreyBorderAbsolute{
	bottom:0;
	z-index:9002;
	height:34px;
	left:0;
}


.icon.playBig{
	width:80px;
	height:56px;
	background-position:-108px -240px;
	cursor:pointer;
}
.icon.playSmall{
	width:50px;
	height:35px;
	background-position:-188px -240px;
	cursor:pointer;
}

.icon.bookInteractiveIcon{
	width:28px;
	height:35px;
	background-position:-238px -240px;
	cursor:pointer;
}



.icon.firstEntryMenu, .icon.secondEntryMenu, .icon.firstEntryMenuOpen, .icon.secondEntryMenuOpen, .icon.thirdEntryMenu, .icon.thirdEntryMenuOpen{
	background-position: -60px -180px;
	width:48px;
	height:60px;
	position:absolute;
	right:12px;
	top:0px;
	display:block;
}

.icon.firstEntryMenuOpen, .icon.secondEntryMenuOpen, .icon.thirdEntryMenuOpen{display:none}

.icon.firstEntryMenuOpen{background-position:-60px -240px;}

.icon.secondEntryMenu, .icon.thirdEntryMenu{background-position:-60px -60px;}

.icon.secondEntryMenuOpen, .icon.thirdEntryMenuOpen{background-position:-60px -120px;}



.icon.star0, .icon.star1, .icon.star2{
	width:21px;
	height:20px;
	background-position:-156px -60px;
	cursor:pointer;
	display:inline-block;
}
.icon.star1{background-position:-177px -60px;}
.icon.star2{background-position:-198px -60px;}

.icon.arrowColoredDown, .icon.arrowColoredUp{
	width:30px;
	height:20px;
	cursor:pointer;
	background-position:-69px -80px;
}
.icon.arrowColoredUp{background-position:-69px -140px;}


.icon.gender, .icon.genderBlue{
	width:20px;height:20px;
	background-position:-156px -90px;
}
.icon.genderBlue{background-position:-156px -110px;}


.icon.age, .icon.ageBlue{
	width:18px;height:21px;
	background-position:-176px -90px;
}
.icon.ageBlue{background-position:-176px -111px;}


.icon.category, .icon.categoryBlue{
	width:16px;height:20px;
	background-position:-194px -90px;
}
.icon.categoryBlue{background-position:-194px -110px;}


.icon.occasion, .icon.occasionBlue{
	width:20px;height:22px;
	background-position:-210px -90px;
}
.icon.occasionBlue{background-position:-210px -112px;}


.icon.price, .icon.priceBlue{
	width:14px;height:19px;
	background-position:-230px -90px;
} 
.icon.priceBlue{background-position:-230px -109px;}


.icon.document{
	width:20px;height:25px;
	background-position:-273px -90px;
}
.icon.documentBlue{
	width:22px;height:25px;
	background-position:-273px -115px;
}

.icon.gift{
	width:25px;height:25px;
	background-position:-244px -90px;
}

.icon.lightbulb{
	width:28px;height:30px;
	background-position:-244px -119px;
}


.icon.boyBig{
	width:71px;height:78px;
	background-position:0px -490px;
}
.icon.girlBig{
	width:84px;height:79px;
	background-position:-123px -490px;
}
.icon.boySmall{
	width:52px;height:57px;
	background-position:-71px -490px;
}
.icon.girlSmall{
	width:61px;height:57px;
	background-position:-207px -490px;
}

svg.rv-icon {
	right: 0;
	position: fixed;
	bottom: 0;
	z-index:1990;
	pointer-events: none;
	width: 190px;
	height: 190px;

	@media(max-width: 1064px) {
		display: none;
	}

	path {
		pointer-events: fill;
	}
}

.icon.greyPdf{
	width:24px;height:30px;
	background-position:-190px -300px;
}
.icon.blueEBook{
	width:25px;height:30px;
	background-position:-219px -300px;
}

.icon.shoppingCart{
	width:55px;
	height:31px;
	background-position:-190px -330px;
}

.icon.envelopeWhite, .icon.envelopeBlue{
	width:24px;
	height:18px;
	background-position:-190px -361px;
}
.icon.envelopeBlue{background-position:-190px -379px;}


@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
  .icon { 
    background: $icons-sprite_2x; 
    background-size: 330px 600px; 
  } 
}

.header.numberCircle {
	position:absolute;
	right:0px;
	top:10px;
	color:white;
}
