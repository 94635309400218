.editorial, .productDetail {
	
	.showOnMobile {
		display: none;
		
		@media only screen
		and (min-device-width: 0px)
		and (max-device-width: $doubleTeaserSecondBreakpoint)
		and (-webkit-min-device-pixel-ratio: 1.3) {
			display: inline-block;
		}
		@media only screen
		and (min-device-width: 0px)
		and (max-device-width: $doubleTeaserSecondBreakpoint)
		and (min-resolution: 250dpi) {
			display: inline-block;
		}
	} 
	.socialShare-sticky {
		
		.hideOnMobile {
						
			@media screen
			and (min-device-width: 0px)
			and (max-device-width: $doubleTeaserSecondBreakpoint) {
				display: none;
			}
			@media screen
			and (min-device-width: 0px)
			and (max-device-width: $doubleTeaserSecondBreakpoint)
			and (-webkit-min-device-pixel-ratio: 1.3) { /*Faktor, um den aufgrund mehr DPI nach unten skaliert wird */
				display: none;
			}
			@media screen
			and (min-device-width: 0px)
			and (max-device-width: $doubleTeaserSecondBreakpoint)
			and (min-resolution: 250dpi) {
				display: none; 
			}
		}
		
		.icon.envelopeWhite {
			background: $icons-sprite_2x no-repeat center;
			background-position:-380px -722px;
			transform: scale(0.6, 0.6);
			width: 48px;
		    height: 38px;
		}	
		
		a {
			-webkit-transition: width 0.2s;
			transition: width 0.2s;
		}
		
		.grow a:hover{
			width: 64px;
		}
		
		.sr-email {
			a {
				background-color: rgba(26,93,174,1);
				border-color: rgba(26,93,174,1);
				
				i {
					margin-bottom: 4px;
				}
			}
		}
	}/* end socialShare-sticky */
	
	.socialShare-mobile {
		.shareButton {
			position: fixed;
			left: 10px;
			bottom: 10px;
			width: 60px;
			height: 60px;
			text-align: center;
			border-radius: 3px;
			z-index: 10001;
			background-color: rgba(26,93,174,.8);
				
		
			i {
				margin-top: 14px;
				color: #fff;
				font-size: 2em;
			}				
		}
		.shareBox {
			display: none;
		
			position: fixed;
			bottom: 80px;
			left: 10px;
			right: 10px;
			padding: 5px;
			background-color: #fff;
			text-align: center;
			border-radius: 3px;
			z-index: 10001;
		
			.iconContainer {
				
				 span {
					float: none;
				}
				.sr-email {
					margin-right: 0em;
					
					a {
						background-color: rgba(26,93,174,1);
						border-color: rgba(26,93,174,1);
						
						i{
							font-size: 0.9em;
						}
					}
				}
				.sr-facebook {
					a {
						color: #3b5998;
						border-color: #3b5998;
    					background-color: unset;
    					
    					i {	
	    					font-size: 1.55em;
    					}
					}
				}
				.sr-pinterest {
					a {
						i {
							margin-top: 4px;
						}
					}
				}
				.icon.envelopeWhite {
					width: 52px;
					height: 42px;
					background: $icons-sprite_2x no-repeat;
					background-position:-380px -720px;
					transform: scale(0.55, 0.55);
					margin-left: -3px;
				}
			}
			
			.w42 {					
				a {
					width: 42px;
					height: 42px;
					line-height: 38px;	
					font-size: 2em;
					border-radius: 3px;  
					-webkit-font-smoothing: antialiased;
  					-moz-osx-font-smoothing: grayscale;
				}
			}
			
			.mrg-10 > *{
			    margin: 5px 15px 5px 0px;
			}
			
		} /*end shareBox*/
		
		.darken {
			position: fixed;
			top:0px;
			left:0px;
			width: 100%;
			height: 100%;
			z-index: 8000;
			background-color: rgba(0,0,0,.5); 
			display: none;
		}
	} /*end socialShare-mobile*/
} /*end editorial & productPriceContainer*/


.socializer {
	padding: 0;
	margin: 0;
	display: inline-block
}

.socializer>* {
	float: left;
	list-style: none;
	padding: 0;
	margin: 0;
	display: inline-block;
	position: relative
}

.socializer a {
	font-family: sans-serif !important;
	display: inline-block;
	border: 0;
	text-align: center;
	text-decoration: none;
	width: 16px;
	height: 16px;
	line-height: 18px;
	font-size: 8px;
	transition: all 0.5s;
	-webkit-transition: all 0.2s;
	transition-timing-function: ease-out;
	-webkit-transition-timing-function: ease-out
}
 
.socializer a>* {
	display: inline-block;
	vertical-align: middle;
	padding: 0;
	margin: 0
}

.socializer:after {
	content: '';
	clear: both;
	display: block
} 

.sr-16px {
	min-height: 16px
}

.sr-16px a {
	width: 16px;
	height: 16px;
	line-height: 16px;
	font-size: 8px
}

.sr-16px.sr-font-sm a {
	font-size: 5.3333333333px
}

.sr-16px.sr-font-lg a {
	font-size: 10.6666666667px
}

.sr-32px {
	min-height: 32px
}

.sr-32px a {
	width: 38px;
	height: 38px;
	line-height: 38px;
	font-size: 19px
}

.sr-32px.sr-font-sm a {
	font-size: 10.6666666667px
}

.sr-32px.sr-font-lg a {
	font-size: 21.3333333333px
}

.sr-48px {
	min-height: 48px
}

.sr-48px a {
	width: 48px;
	height: 48px;
	line-height: 48px;
	font-size: 24px
}

.sr-48px.sr-font-sm a {
	font-size: 16px
}

.sr-48px.sr-font-lg a {
	font-size: 32px
}

.sr-64px {
	min-height: 64px;
	color: crimsonred;
} 

.sr-64px a {
	width: 64px;
	height: 64px;
	line-height: 64px;
	font-size: 32px
}

.sr-64px.sr-font-sm a {
	font-size: 21.3333333333px
}

.sr-64px.sr-font-lg a {
	font-size: 42.6666666667px
}

.socializer .sr-addtofavorites a, .socializer .sr-addtofavorites a:visited
	{
	color: #F9A600;
	border-color: #F9A600;
	background-color: #F9A600
}

.socializer .sr-behance a, .socializer .sr-behance a:visited {
	color: #1769ff;
	border-color: #1769ff;
	background-color: #1769ff
}

.socializer .sr-bitbucket a, .socializer .sr-bitbucket a:visited {
	color: #205081;
	border-color: #205081;
	background-color: #205081
}

.socializer .sr-blogger a, .socializer .sr-blogger a:visited {
	color: #FF6501;
	border-color: #FF6501;
	background-color: #FF6501
}

.socializer .sr-codepen a, .socializer .sr-codepen a:visited {
	color: #000;
	border-color: #000;
	background-color: #000
}

.socializer .sr-comments a, .socializer .sr-comments a:visited {
	color: #333;
	border-color: #333;
	background-color: #333
}

.socializer .sr-delicious a, .socializer .sr-delicious a:visited {
	color: #3274D1;
	border-color: #3274D1;
	background-color: #3274D1
}

.socializer .sr-deviantart a, .socializer .sr-deviantart a:visited {
	color: #475c4d;
	border-color: #475c4d;
	background-color: #475c4d
}

.socializer .sr-digg a, .socializer .sr-digg a:visited {
	color: #000;
	border-color: #000;
	background-color: #000
}

.socializer .sr-dribbble a, .socializer .sr-dribbble a:visited {
	color: #ea4c89;
	border-color: #ea4c89;
	background-color: #ea4c89
}

.socializer .sr-email a, .socializer .sr-email a:visited {
	color: #000;
	border-color: #000;
	background-color: #000
}

.socializer .sr-facebook a, .socializer .sr-facebook a:visited {
	color: #3e5b98;
	border-color: #3e5b98;
	background-color: #3e5b98
}

.socializer .sr-fbmessenger a, .socializer .sr-fbmessenger a:visited {
	color: #2998ff;
	border-color: #2998ff;
	background-color: #2998ff
}

.socializer .sr-flickr a, .socializer .sr-flickr a:visited {
	color: #1c9be9;
	border-color: #1c9be9;
	background-color: #1c9be9
}

.socializer .sr-github a, .socializer .sr-github a:visited {
	color: #333;
	border-color: #333;
	background-color: #333
}

.socializer .sr-google a, .socializer .sr-google a:visited {
	color: #3A7CEC;
	border-color: #3A7CEC;
	background-color: #3A7CEC
}

.socializer .sr-googleplus a, .socializer .sr-googleplus a:visited {
	color: #DB483B;
	border-color: #DB483B;
	background-color: #DB483B
}

.socializer .sr-hackernews a, .socializer .sr-hackernews a:visited {
	color: #FF6500;
	border-color: #FF6500;
	background-color: #FF6500
}

.socializer .sr-instagram a, .socializer .sr-instagram a:visited {
	color: #0d3c5f;
	border-color: #0d3c5f;
	background-color: #0d3c5f
}

.socializer .sr-linkedin a, .socializer .sr-linkedin a:visited {
	color: #0274B3;
	border-color: #0274B3;
	background-color: #0274B3
}

.socializer .sr-medium a, .socializer .sr-medium a:visited {
	color: #02b875;
	border-color: #02b875;
	background-color: #02b875
}

.socializer .sr-paypal a, .socializer .sr-paypal a:visited {
	color: #0070ba;
	border-color: #0070ba;
	background-color: #0070ba
}

.socializer .sr-pdf a, .socializer .sr-pdf a:visited {
	color: #E61B2E;
	border-color: #E61B2E;
	background-color: #E61B2E
}

.socializer .sr-pinterest a, .socializer .sr-pinterest a:visited {
	color: #CB2027;
	border-color: #CB2027;
	background-color: #CB2027
}

.socializer .sr-pocket a, .socializer .sr-pocket a:visited {
	color: #EF4056;
	border-color: #EF4056;
	background-color: #EF4056
}

.socializer .sr-print a, .socializer .sr-print a:visited {
	color: #6D9F00;
	border-color: #6D9F00;
	background-color: #6D9F00
}

.socializer .sr-reddit a, .socializer .sr-reddit a:visited {
	color: #FF5600;
	border-color: #FF5600;
	background-color: #FF5600
}

.socializer .sr-rss a, .socializer .sr-rss a:visited {
	color: #FF7B0A;
	border-color: #FF7B0A;
	background-color: #FF7B0A
}

.socializer .sr-shortlink a, .socializer .sr-shortlink a:visited {
	color: #333;
	border-color: #333;
	background-color: #333
}

.socializer .sr-snapchat a, .socializer .sr-snapchat a:visited {
	color: #FFFC00;
	border-color: #FFFC00;
	background-color: #FFFC00
}

.socializer .sr-soundcloud a, .socializer .sr-soundcloud a:visited {
	color: #f50;
	border-color: #f50;
	background-color: #f50
}

.socializer .sr-stackoverflow a, .socializer .sr-stackoverflow a:visited
	{
	color: #F48024;
	border-color: #F48024;
	background-color: #F48024
}

.socializer .sr-stumbleupon a, .socializer .sr-stumbleupon a:visited {
	color: #EB4823;
	border-color: #EB4823;
	background-color: #EB4823
}

.socializer .sr-quora a, .socializer .sr-quora a:visited {
	color: #b92b27;
	border-color: #b92b27;
	background-color: #b92b27
}

.socializer .sr-telegram a, .socializer .sr-telegram a:visited {
	color: #179cde;
	border-color: #179cde;
	background-color: #179cde
}

.socializer .sr-tumblr a, .socializer .sr-tumblr a:visited {
	color: #314358;
	border-color: #314358;
	background-color: #314358
}

.socializer .sr-twitch a, .socializer .sr-twitch a:visited {
	color: #4b367c;
	border-color: #4b367c;
	background-color: #4b367c
}

.socializer .sr-twitter a, .socializer .sr-twitter a:visited {
	color: #4da7de;
	border-color: #4da7de;
	background-color: #4da7de
}

.socializer .sr-vimeo a, .socializer .sr-vimeo a:visited {
	color: #00ADEF;
	border-color: #00ADEF;
	background-color: #00ADEF
}

.socializer .sr-vkontakte a, .socializer .sr-vkontakte a:visited {
	color: #4C75A3;
	border-color: #4C75A3;
	background-color: #4C75A3
}

.socializer .sr-wechat a, .socializer .sr-wechat a:visited {
	color: #7BB32E;
	border-color: #7BB32E;
	background-color: #7BB32E
}

.socializer .sr-whatsapp a, .socializer .sr-whatsapp a:visited {
	color: #60b82d;
	border-color: #60b82d;
	background-color: #60b82d
}

.socializer .sr-xing a, .socializer .sr-xing a:visited {
	color: #006567;
	border-color: #006567;
	background-color: #006567
}

.socializer .sr-yahoomail a, .socializer .sr-yahoomail a:visited {
	color: #4A00A1;
	border-color: #4A00A1;
	background-color: #4A00A1
}

.socializer .sr-youtube a, .socializer .sr-youtube a:visited {
	color: #cc181e;
	border-color: #cc181e;
	background-color: #cc181e
}

.socializer .sr-more>a, .socializer .sr-more>a:visited {
	color: green;
	border-color: green;
	background-color: green
}

.sr-text-in a {
	width: auto;
	padding: 0 1em;
	overflow: hidden
}

.sr-text-in a>* {
	padding-right: 0.5em
}

.sr-text-in a>*:last-child {
	padding-right: 0
}

.sr-text-out a {
	vertical-align: middle
}

.sr-text-out .text {
	font-size: 12px;
	margin-left: 0.5em;
	vertical-align: middle
}

.sr-text-below {
	text-align: center
}

.sr-text-below .text {
	display: block;
	font-size: 12px;
	margin-top: 0.5em
}

.sr-text-hover .text {
	display: none;
	position: absolute;
	background: #000;
	color: #fff;
	font-size: 12px;
	width: 100px;
	top: -3em;
	border-radius: 5px;
	text-align: center;
	line-height: 2em;
	margin-left: -25%;
	z-index: 9999
}

.sr-text-hover .text:before {
	position: absolute;
	content: '';
	border: 0.5em solid #F00;
	bottom: -1em;
	left: 1em;
	width: 0;
	height: 0;
	border-color: #000 transparent transparent transparent
}

.sr-text-hover:hover>.text {
	display: block
}

.sr-bdr-sm.socializer a {
	border: 1px solid
}

.sr-bdr-md.socializer a {
	border: 2px solid
}

.sr-bdr-lg.socializer a {
	border: 3px solid
}

.sr-bdr-none.socializer a {
	border: 0
}

.sr-bdr-white.socializer a, .sr-bdr-white.socializer a:visited {
	border-color: #fff
}

.sr-bdr-dark.socializer a, .sr-bdr-dark.socializer a:visited {
	border-color: #000
}

.sr-bdr-grey.socializer a, .sr-bdr-grey.socializer a:visited {
	border-color: #ccc
}

.sr-bg-white.socializer a, .sr-bg-white.socializer a:visited {
	background-color: #fff
}

.sr-bg-dark.socializer a, .sr-bg-dark.socializer a:visited {
	background-color: #333
}

.sr-bg-grey.socializer a, .sr-bg-grey.socializer a:visited {
	background-color: #ccc
}

.sr-bg-none.socializer a, .sr-bg-none.socializer a:visited {
	background-color: transparent
}

.sr-icon-white.socializer a, .sr-icon-white.socializer a:visited {
	color: #fff
}

.sr-icon-dark.socializer a, .sr-icon-dark.socializer a:visited {
	color: #333
}

.sr-icon-grey.socializer a, .sr-icon-grey.socializer a:visited {
	color: #ccc
}

.sr-sw-1.socializer a {
	box-shadow: 0 0 0.2em rgba(0, 0, 0, 0.3)
}

.sr-sw-2.socializer a {
	box-shadow: inset 0 -0.1em 0.1em rgba(0, 0, 0, 0.3)
}

.sr-sw-3.socializer a {
	box-shadow: 0 -0.1em 0.1em rgba(0, 0, 0, 0.3)
}

.sr-sw-icon-1.socializer a i {
	text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5)
}

.sr-circle a {
	border-radius: 50%
}

.sr-circle .sr-text-in a, .sr-circle.sr-text-in a {
	border-radius: 1em
}

.sr-squircle a, .sr-squircle .sr-text-in a, .sr-squircle.sr-text-in a {
	border-radius: 0.25em
}

.sr-squircle-2 a, .sr-squircle-2 .sr-text-in a, .sr-squircle-2.sr-text-in a
	{
	border-radius: 0 0.5em 0 0.5em
}

.sr-diamond a {
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.sr-diamond a>* {
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.sr-drop a {
	-moz-transform: rotate(45deg);
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	border-radius: 50% 50% 0 50%
}

.sr-drop a>* {
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.sr-ribbon a:after {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	border-style: solid;
	border-color: inherit;
	border-bottom-color: transparent;
	border-width: 0em 1em 0.5em 1em
}

.sr-fluid {
	display: table;
	width: 100%;
	table-layout: fixed
}

.sr-fluid>* {
	display: table-cell;
	float: none;
	width: 100%
}

.sr-fluid a {
	width: 100%;
	box-sizing: border-box;
	overflow: hidden;
	padding: 0 0.5em
}

.sr-fluid.sr-pad {
	border-spacing: 0.25em
}

.sr-fluid .sr-text-in a>*, .sr-fluid.sr-text-in a>* {
	margin-right: 0.5em
}

.sr-fluid .sr-text-in a>*:last-child, .sr-fluid.sr-text-in a>*:last-child
	{
	margin-right: 0
}

.sr-fluid .sr-text-in a:last-child, .sr-fluid.sr-text-in a:last-child {
	margin: 0
}

.sr-fluid.sr-squircle a {
	border-radius: 0.5em
}

.sr-fluid.sr-squircle-2 a {
	border-radius: 0 0.5em 0 0.5em
}

.sr-fluid .sr-more li {
	display: block;
	width: 100%
}

.sr-vertical {
	width: 16px
}

.sr-vertical.sr-16px {
	width: 16px
}

.sr-vertical.sr-16px>* {
	width: 16px
}

.sr-vertical.sr-32px {
	width: 32px
}

.sr-vertical.sr-32px>* {
	width: 32px
}

.sr-vertical.sr-48px {
	width: 48px
}

.sr-vertical.sr-48px>* {
	width: 48px
}

.sr-vertical.sr-64px {
	width: 64px
}

.sr-vertical.sr-64px>* {
	width: 64px
}

.sr-vertical.sr-pad>* {
	margin: 0 0 0.5em 0
}

.sr-vertical.sr-text-in a, .sr-vertical .sr-text-in a {
	padding: 0;
	width: inherit
}

.sr-vertical.sr-text-in a>*, .sr-vertical .sr-text-in a>* {
	padding: 0
}

.sr-vertical.sr-text-in .text, .sr-vertical .sr-text-in .text {
	display: none
}

.sr-opacity a:hover {
	opacity: 0.5
}

.sr-opacity a:active {
	opacity: 0.8
}

.sr-rotate a:hover {
	-moz-transform: rotate(15deg);
	-webkit-transform: rotate(15deg);
	transform: rotate(15deg)
}

.sr-rotate a:active {
	-moz-transform: rotate(0);
	-webkit-transform: rotate(0);
	transform: rotate(0)
}

.sr-zoom a:hover {
	-moz-transform: scale(1.2);
	-webkit-transform: scale(1.2);
	transform: scale(1.2);
	z-index: 9999
}

.sr-zoom a:active {
	-moz-transform: scale(0.8);
	-webkit-transform: scale(0.8);
	transform: scale(0.8)
}

.sr-shrink a:hover {
	-moz-transform: scale(0.8);
	-webkit-transform: scale(0.8);
	transform: scale(0.8)
}

.sr-shrink a:active {
	-moz-transform: scale(0.6);
	-webkit-transform: scale(0.6);
	transform: scale(0.6)
}

.sr-float a:hover {
	-moz-transform: translateY(-5px);
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px)
}

.sr-float a:active {
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.sr-sink a:hover {
	-moz-transform: translateY(5px);
	-webkit-transform: translateY(5px);
	transform: translateY(5px)
}

.sr-sink a:active {
	-moz-transform: translateY(0);
	-webkit-transform: translateY(0);
	transform: translateY(0)
}

.sr-fade-white a:hover {
	color: #000;
	background-color: #fff
}

.sr-fade-white a:active {
	opacity: 0.2
}

.sr-fade-black a:hover {
	color: #fff;
	background-color: #000
}

.sr-fade-black a:active {
	opacity: 0.2
}

.sr-pad>* {
	margin-right: 0.5em
}

.sr-multiline>* {
	margin-bottom: 0.5em
}

.sr-no-icon i {
	display: none
}

.sr-more ul {
	background: #fff;
	padding: 0.5em;
	position: absolute;
	display: none;
	box-shadow: 0 2px 6px -2px;
	z-index: 9999
}

.sr-more ul:before {
	content: '';
	display: inline-block;
	position: absolute;
	border: 0.25em solid;
	border-color: transparent transparent #fff transparent;
	top: -0.5em;
	left: 0.5em
}

.sr-more ul:after {
	content: '';
	clear: both;
	display: block
}

.sr-more ul li {
	margin: 0 0 0.5em 0
}

.sr-more ul li:last-child {
	margin-bottom: 0
}

.sr-more:hover ul {
	display: block
}

.sr-count-1 span.ctext {
	background: #FF5722;
	position: absolute;
	top: -0.6em;
	right: -0.6em;
	border-radius: 1em;
	font-size: 0.6em;
	line-height: 1;
	padding: 0.3em 0.5em;
	z-index: 99;
	color: #fff;
	box-shadow: 0 1px 3px -1px #000
}

.sr-count-2 span.ctext {
	font-size: 0.8em;
	margin: 0 0.5em 0 0;
	font-weight: bold
}

.sr-count-3 span.ctext {
	font-size: 0.8em;
	margin: 0 0.5em 0 0;
	font-weight: bold;
	line-height: 0.8em;
	background: rgba(0, 0, 0, 0.3);
	padding: 0.5em;
	border-radius: 1em
}

.sr-count-4 span.ctext {
	font-size: 0.8em;
	margin: 0 0.5em 0 0;
	font-weight: bold;
	line-height: 0.8em;
	border-left: 1px solid;
	padding: 0 0 0 0.8em
}

.sr-bb-1 .sr-text-in {
	margin-right: 3.5em
}

.sr-bb-1 a {
	position: relative;
	overflow: visible
}

.sr-bb-1 span.ctext {
	font-size: 0.8em;
	font-weight: bold;
	background: #fff;
	border-radius: 3px;
	color: #333;
	position: absolute;
	width: 3em;
	top: 0;
	bottom: 0;
	padding: 0;
	right: -3.5em;
	border: 1px solid #dfdfdf
}

.sr-sharebar {
	position: fixed;
	z-index: 9999
}

.sr-sharebar .socializer>*:last-child {
	margin: 0
}

.sr-sb-vl {
	top: 50%;
	transform: translateY(-50%)
}

.sr-sb-left {
	left: 0px
}

.sr-sb-right {
	right: 30px
}

.sr-sb-hl {
	left: 50%;
	transform: translateX(-50%)
}

.sr-sb-top {
	top: 30px
}

.sr-sb-bottom {
	bottom: 30px
}

.sr-sb-white {
	background: #fff;
	box-shadow: 0 1px 4px -2px;
	padding: 10px
}

.sr-sb-dark {
	background: #333;
	box-shadow: 0 1px 4px -2px;
	padding: 10px
}

.sr-sb-dark .text {
	color: #fff
}