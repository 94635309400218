#wrapper{
	position:relative;
	
	#toTop{
		display:none;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			display:block;
		}
		position:fixed;
		right:10px;
		bottom:10px;
		border-radius:3px;
		width:60px;
		height:60px;
		background-color:rgba(26,93,174,0.8);
		z-index:7999;
		cursor:pointer;
		text-align:center;
		
		i{
			z-index:10000;
			color:$white;
			display:block;
			margin-top:10px;
		}
		
		.no-touchevents &:hover{
			background-color:rgba(26,93,174,1);
		}
	}
	
	main{
		padding-top:95px; //when you change this value also change it in rv_header.js in open2ndmenu
		/*
		@media (max-width: $secondSearchBreakpoint) {
			padding-top:150px;
		}*/
		
		@include media-breakpoint-down(xs) {
			padding-top: 70px !important;
		}
		
		&.forBigTouchDivices{
			padding-top:95px;
		}
		
		&.themenspecial{
			padding-top:0 !important;
		}
	}
}//end wrapper


[id^="trbo-"] {
	display: none;
	margin: 12px 0;
	border-bottom: 2px solid red;
	text-align: center;
	color: red;
}


//for anker links to compensate fix header height
/*
:target:before {
	content:"";
	display:block;
	height:90px;
	margin:-90px 0 0;
}
*/

/* disabled SUP-624
body.header-flyout-open::after {
	content: " ";
	position: fixed;
	z-index: 20;
	background-color: rgba(0,0,0,0.5);
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
*/


a.skip-to-main-content {
	position: absolute;
	left: 0;
	top: 0;
	padding: 5px 10px;
	color: white;
	background-color: #1a5dae;
	&:focus {
		z-index: 9000;
	}
}

body.is-iframe {
	background: none;

	.whiteBox20_3 {
		padding-left: 0;
		padding-right: 0;
	}

	.editorial .content .innerTableContainer.first {
		padding-top: 0;
	}

	.btn-orange {
		background-color: #51626f;
		border-color: #51626f;
	}
}