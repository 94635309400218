.productConsultant{
	.content{
		max-width:1045px;
		margin:0 auto;
		font-size:0.875rem;
		
		h1{
			font-weight:bold;
			font-size:1.625rem;
		}
	
		@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
		
		@media (max-width: $tileBreakpoint) {width:340px;}
	}//content
}//productConsultant