.overflowHiddenVisible{overflow:visible !important}

.rasterContainer{
	// add a focus state for accessibility. use right now the same setting as the hover
	> a:focus {
		.zoomcontainer::before {
			z-index: -1;
		}
	}
	a.zoomcontainer:focus::before {
		z-index: -1;
	}
	.zoomcontainer:focus {
		outline-offset: 3px; // increase this because of the my account tiles which are with a blue background
	}

	width:340px;
	//height:1000px; /*delete later */
	margin:0 auto;
	position:relative;
	line-height:1.2;
	
	@media (min-width: 535px) {
		width:515px;
	}
	@media (min-width: 820px) {
		width:780px;
	}
	@media (min-width: 1085px) {
		width:1045px;	
	}
	@media (min-width: 1350px) {
		width:1310px;	
	}
	@media (min-width: 1615px) {
		width:1575px;	
	}
	@media (min-width: 1880px) {
		width:1840px;	
	}
	
	@media (min-width: 2145px) {
		width:2105px;	
	}
	@media (min-width: 2410px) {
		width:2370px;	
	}
	@media (min-width: 2675px) {
		width:2635px;	
	}
	
	//media + 265
	
	
	.tilesTable{
		display:table;
		&.fullWidth{width:100%;}
		&.fullHeight{height:100%;}
		
		.tilesTableRow{
			display:table-row;
			height: 70%;
			.tilesTableCell{
				display:table-cell;
			}
		}
	}


	&>div{overflow:hidden}
	
	.innerShadow{
		-webkit-box-shadow: inset 0px 0px 29px 0px rgba(0,0,0,0.29);
		-moz-box-shadow: inset 0px 0px 29px 0px rgba(0,0,0,0.29);
		box-shadow: inset 0px 0px 29px 0px rgba(0,0,0,0.29);	
	}
	
	.container_1_1, .container_1_2, .container_2_1, .container_2_2{
		top:-999px;left:-999px;
		
		.table{
			display:table;
			width:100%;
			height:100%;
			margin:0;
			.table-row{
				display:table-row;
				.table-cell{
					display:table-cell;
				}
			}
		}
		.btn {
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				font-size: .8rem;
				padding: .300rem .8rem;
				font-weight: 525;
			}
		
		}
		.btnContainer {
			text-align:center;
			display: table-row;
			height: 30%;
		}
		
		.btnWrapper {
			display: table-cell;
			vertical-align: middle;
			
			/*---- Button position fix for IE10+ ----*/	
			@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
				height:unset;
			}
			_:-ms-lang(x), .selector { 
				height:unset; 
			}
			/*----MS Edge Browser CSS----*/
			_:-ms-lang(x), _:-webkit-full-screen, .selector { 
				height:unset; 
			}
			@supports (-ms-ime-align:auto) { 
				height:unset; 
			}
		}	
	}
	
	.container_1_1{
		width:250px;
		height:250px;
		position:absolute;
		padding:15px;	
		@media (max-width: $tileBreakpoint) {
			width:165px;
			height:165px;
			padding:10px;
		}
		
		.tilesTableRow {
			height:68%;
		}
		
		.btnContainer {
		height: 32%;	
		}
	}
	
	.container_2_1{
		width:515px;
		height:250px;
		position:absolute;
		padding:15px;
		@media (max-width: $tileBreakpoint) {
			width:340px;
			height:165px;
			padding:10px;
		}
		
		.tilesTableRow {
			height:68%;
		}
		
		.btnContainer {
			height:32%;
		}
	}
	
	.container_1_2{
		width:250px;
		height:515px;
		position:absolute;
		padding:15px;
		@media (max-width: $tileBreakpoint) {
			width:165px;
			height:340px;
			padding:10px;
		}
		
		.tilesTableRow {
			height:82%;
		}
		
		.btnContainer {
			height:18%;
		}
		
	}
	
	.container_2_2{
		width:515px;
		height:515px;	
		position:absolute;
		padding:15px;
		@media (max-width: $tileBreakpoint) {
			width:340px;
			height:340px;
			padding:10px;
		}
		
		.tilesTableRow {
			height:65%;
		}
		
		.btnContainer {
			height:35%;
		}
	}
	
	.fillBlock{
		display:none;
		&.fillBlockVisible{display:block;}
	}
	.fillBlockVisible{display:block;}
	
	.textContainer{overflow-y:auto}
	
	.image{
		width:100%;
		height:100%;
		background-repeat:no-repeat;
		background-size:100% 100%
	}
	
	.containerBlock{
	
		&.download{
			font-size:1rem;
			h3{font-size:1.625rem;font-weight:bold;}
			padding:0;
			background-size:cover;	
			-webkit-background-size:cover;
			-moz-background-size:cover;
    		background-repeat: no-repeat;
			background-position:center center;
			>div{
				width:100%;
				height:100%;
				padding:15px;
				@media (max-width: $tileBreakpoint) {padding:10px;}
			}
			@media (max-width: $tileBreakpoint) {	
				font-size:0.625rem;
				h3{font-size:1rem}
			}
			
			.tilesTable{
				height:134px;
				@media (max-width: $tileBreakpoint) {height:85px}
			}
			
			.button{
				text-align:center;
				margin-top:18px;
				@media (max-width: $tileBreakpoint) {margin-top:15px}
			}

		}//download
		
		
		&.productStandard{
			background-color:$white;
			padding:0;
			>div{
				
				position:relative;
				padding:15px 15px 0 15px;
				
				.upper{
					height:130px;
					@media (max-width: $tileBreakpoint) {height:87px;}
					
					.imageContainer{
						height:120px;
						@media (max-width: $tileBreakpoint) {height:80px;}
						text-align:center;
						.table{
							display:table;
							margin-bottom:0;
							height:100%;
							.table-row{
								display:table-row;
								height:100%;
								.table-cell{
									display:table-cell;
									text-align:center;
									vertical-align:middle;
									a{display:block}
								}	//table-cell
							}//table-row
						}//table
						
						img{
							max-width:120px;
							max-height:120px;
							@media (max-width: $tileBreakpoint) {
								max-width:80px;
								max-height:80px;
							}
						}//img
					}//end imageContainer
				}//end upper
				
				.lower{
					.headline{
						height:45px;
						@media (max-width: $tileBreakpoint) {height:15px;}
						overflow:hidden;
						
						a, a:hover{
							color:$brand-primary;
							font-size:0.875rem;
							font-weight:bold;
							overflow:hidden;
							text-overflow: ellipsis;
							height: 45px;
							text-decoration:none;
							display:block;
							@media (max-width: $tileBreakpoint) {
								font-size:0.6875rem;
								height:24px;
							}
						}
						a:hover{color:black}
						a:focus-visible {
							outline-offset: -1px;
						}
					}//headline
				
				
				
					.table{
						display:table;
						margin-bottom:0;
						width:100%;

						.table-row{
							display:table-row;
							
							.table-cell{
								display:table-cell;
								width:50%;
								
								.btn{
									padding:0.375rem 0.8rem;
									@media (max-width: $tileBreakpoint) {padding:0.1rem 0.4rem;}
								}
								
								.priceContainer{
									.oldPrice{
										color:$dark-grey;
										font-size:0.875rem;
										@media (max-width: $tileBreakpoint) {font-size:0.75rem;}
										text-decoration:line-through;
										white-space:nowrap;
									}
									.oldPriceFF{
										color:$dark-grey;
										font-size:0.875rem;
										@media (max-width: $tileBreakpoint) {font-size:0.75rem;}
										white-space:nowrap;
									}
									.newPrice{
										font-weight:bold;
										color:$black;
										font-size:1rem;
										white-space:nowrap;
										@media (max-width: $tileBreakpoint) {font-size:0.875rem;}
										&.orange{color:$orange4}
									}
									
									.familyAndFriends-priceImage {
										float: left;
										padding-left:2px;
										
									}	
									
								}//end priceContainer


							}//table-cell
						}//table-row
					}//table
					
					&>.table{height:38px;}
				}//end lower
				
				/*
				
				*/
			}
		}
		
		&.productHero{
			background-color:$white;
			padding:0;
			>div{
				position:relative;padding:30px;
				@media (max-width: $tileBreakpoint) {padding:15px}
			
				.upper, .lower{
					height:415px;
					@media (max-width: $tileBreakpoint) {height:270px;}
					overflow:hidden;
					
					.table{
						display:table;
						width:100%;
						height:210px;
						margin:0;
						@media (max-width: $tileBreakpoint) {height:140px;}
	
						.table-row{
							display:table-row;
							
							.table-cell{
								display:table-cell;
								&.image{
									width:240px;
									padding-right:30px;
									text-align:center;
									vertical-align:middle;
									
									@media (max-width: $tileBreakpoint) {
										width:155px;
										padding-right:15px;
									}
									
									img{
										max-width:210px;
										max-height:210px;
										@media (max-width: $tileBreakpoint) {
											max-width:140px;
											max-height:140px;
										}
									}
								}
								
								&.description{
									width:215px;
									vertical-align:top;
									@media (max-width: $tileBreakpoint) {width:155px}

									a.headline, a.headline:hover{
										color:$brand-primary;
										font-size:1.625rem;
										font-weight:bold;
										overflow:hidden;
										text-overflow: ellipsis;
										max-height:130px;
										text-decoration:none;
										display:block;
										@media (max-width: $tileBreakpoint) {
											font-size:1.125rem;
											max-height:85px;	
										}
									}	
									a.headline:hover{color:black}
									
									a.category{
										font-size:0.875rem;
										display:inline;
										@media (max-width: $tileBreakpoint) {display:none}
									}
								}
								
								&.bottom{
									font-size:0.875rem;
									@media (max-width: $tileBreakpoint) {	font-size:0.75rem;}
									
									&.priceContainer{
										float: left;
										
										.oldPrice{
											color:$dark-grey;
											font-size:1rem;
											@media (max-width: $tileBreakpoint) {font-size:0.875rem;}
											text-decoration:line-through;
										}
										.oldPriceFF{
											color:$dark-grey;
											font-size:1rem;
											@media (max-width: $tileBreakpoint) {font-size:0.875rem;}
										}
										.newPrice{
											font-weight:bold;
											color:$black;
											font-size:1.625rem;
											@media (max-width: $tileBreakpoint) {font-size:1.125rem;}
											&.orange{color:$orange4}
										}
									}
								}
								
								
								
								
							}//end tablecell
						}//tablerow
					}//end table
					
					.desc{
						color:$black;
						font-size:1rem;
						margin-top:40px;
						height:100px;
						@media (max-width: $tileBreakpoint) {
							font-size:0.875rem;
							margin-top:30px;
							height:75px;
						}
						
						a{
							color:$black;
							text-decoration:underline;
						}
						a:hover{color:$brand-primary;}
					}//end desc
				}//end upper, lower
				
				.lower{height:auto}
				

			}//end >div
		}
		
		
		&.textOnly{
			font-size:0.875rem;
			h1, .tilesBigHeadline{font-size:1.875rem;font-weight:bold;}
			h2, .tilesSubheadline{font-size:1.125rem}
			.tilesSubheadline{line-height:1.1;margin-bottom:.5rem;}
			a.text{text-decoration:underline}
			a.text:hover{text-decoration:none;}
			padding:0;
			background-size:cover;	
			-webkit-background-size:cover;
			-moz-background-size:cover;
    		background-repeat: no-repeat;
			background-position:center center;
			>div{
				width:100%;
				height:100%;
				padding:15px;
				@media (max-width: $tileBreakpoint) {padding:10px;}
			}
			@media (max-width: $tileBreakpoint) {	
				font-size:0.75rem;
				h1, .tilesBigHeadline{font-size:1.125rem}
				h2, .tilesSubheadline{font-size:1rem}
			}
			
			&.container_1_1, &.container_1_2{
				h1, .tilesBigHeadline{font-size:1.625rem}
				h2, .tilesSubheadline{font-size:1rem}
				@media (max-width: $tileBreakpoint) {	
					h1, .tilesBigHeadline{font-size:1rem}
					h2, .tilesSubheadline{font-size:0.75rem}
				}
			}
			
			&.container_1_1{
				.btnContainer{
					a.btn {
						font-size: .875rem;
					}
				}
			}	
				
			&.container_1_2{
				@media (max-width: $tileBreakpoint) {	
					h1, .tilesBigHeadline{font-size:1.125rem}
					h2, .tilesSubheadline{font-size:0.875rem}
				}
			}
			
			&.container_2_1{
				h1, .tilesBigHeadline{font-size:1.875rem}
				h2, .tilesSubheadline{font-size:1.125rem}
				@media (max-width: $tileBreakpoint) {	
					h1, .tilesBigHeadline{font-size:1.125rem}
					h2, .tilesSubheadline{font-size:1rem}
				}
			}
		}
		
		
		
		&.youtubeLightbox, &.bookInteractive{
			cursor:pointer;
			.playBig, .playSmall{
				//margin:0;
				position:relative;
				margin-bottom:10px;
				//left:0 !important;
				top:0 !important;
					
			}
			
			
			//fonts identical with textonly
			/*
			font-size:1rem;
			h1, .tilesBigHeadline{font-size:1.625rem;font-weight:bold;}
			.tilesSubheadline{line-height:1.1;}
			&.container_2_1,&.container_2_2{
				font-size:1.125rem;
				h1, .tilesBigHeadline{font-size:1.875rem;}
			}
			
			@media (max-width: $tileBreakpoint) {	
				font-size:0.875rem !important;
				h1, .tilesBigHeadline{font-size:1.125rem !important;}
				
				&.container_1_1{
					font-size:0.75rem;
					h1, .tilesBigHeadline{font-size:1rem;}
				}
			}
			*/
			
			font-size:0.875rem;
			h1, .tilesBigHeadline{font-size:1.875rem;font-weight:bold;}
			h2, .tilesSubheadline{font-size:1.125rem}
			
			@media (max-width: $tileBreakpoint) {	
				font-size:0.75rem;
				h1, .tilesBigHeadline{font-size:1.125rem}
				h2, .tilesSubheadline{font-size:1rem}
			}
			
			&.container_1_1, &.container_1_2{
				h1, .tilesBigHeadline{font-size:1.625rem}
				h2, .tilesSubheadline{font-size:1rem}
				@media (max-width: $tileBreakpoint) {	
					h1, .tilesBigHeadline{font-size:1rem}
					h2, .tilesSubheadline{font-size:0.75rem}
				}
			}
			
			&.container_1_2{
				@media (max-width: $tileBreakpoint) {	
					h1, .tilesBigHeadline{font-size:1.125rem}
					h2, .tilesSubheadline{font-size:0.875rem}
				}
			}
			
			&.container_2_1{
				h1, .tilesBigHeadline{font-size:1.875rem}
				h2, .tilesSubheadline{font-size:1.125rem}
				@media (max-width: $tileBreakpoint) {	
					h1, .tilesBigHeadline{font-size:1.125rem}
					h2, .tilesSubheadline{font-size:1rem}
				}
			}

			
		}
		
		&.bookInteractive{
			.bookInteractiveIcon{
				position:relative;
				margin-bottom:10px;
				top:0 !important;
				left: 50%;
				margin-left: -12px;
			}
		}//bookInteractive
		
		&.contentSearch, &.faq{
			font-size:1rem;
			h1, .tilesBigHeadline{font-size:1.625rem;font-weight:bold;}
			
			@media (max-width: $tileBreakpoint) {	
				font-size:0.875rem;
				h1, .tilesBigHeadline{font-size:1.125rem}
			}
		}
		
		&.faq{
			form{width:300px;}
			.selectContainer{width:300px;}
			.buttonContainer{margin-top:20px}
			.formContainer{
				margin:0 auto;
				margin-top:20px;
				width:300px;
			}
			
			@media (max-width: $tileBreakpoint) {
				&.container_2_1{
					h1, .tilesBigHeadline{margin-bottom:3px}
					.buttonContainer, .formContainer{margin-top:3px !important}
				}
			}
		}
		
		&.textProductCombi{
			padding:0;
			
			background-size:cover;	
			-webkit-background-size:cover;
			-moz-background-size:cover;
    		background-repeat: no-repeat;
			background-position:center center;
			
			
			&.container_1_2>div, &.container_2_1>div{
				position:relative;
			
				h1, .tilesBigHeadline{
					font-weight:bold;
					font-size:1.625rem;
				}
				font-size:1rem;
				
				@media (max-width: $tileBreakpoint) {
					h1, .tilesBigHeadline{font-size:1rem;}
					font-size:0.6875rem;
				}
			}	
			
			&.container_1_2>div{
				.tableOuterContainer{
					width:250px;
					height:449px;
					overflow:hidden;
					@media (max-width: $tileBreakpoint) {
						width:165px;
						height:300px;
					}
					
					.tableContainer{
						width:20000em;
						
						>table{
							width:250px;
							height:449px;
							float:left;
							@media (max-width: $tileBreakpoint) {
								width:165px;
								height:300px;
							}
							
							tr.halfHeight{
								height:50%;
								td{
									padding:0 15px;
									img{
										max-width:220px;
										max-height:215px;
										@media (max-width: $tileBreakpoint) {
											max-width:135px;
											max-height:140px;
										}
									}
									
									.circleContainer{
										position:relative;
										.circle{
											width:180px;
											height:180px;
											background-color:$white;
											border-radius:90px;
											-webkit-border-radius:90px;
											-moz-border-radius:90px;
											z-index:5;
											position:relative;
											img{
												max-width:115px;
												max-height:115px;
											}
											
											@media (max-width: $tileBreakpoint) {
												width:126px;
												height:126px;
												border-radius:63px;
												-webkit-border-radius:63px;
												-moz-border-radius:63px;
												img{
													max-width:80px;
													max-height:80px;
												}
											}
										}
										
										table{
											width:100%;
											height:100%;
										}
										
										.shadow-circle{
											background:url(/images/shadow-circle.png);
											width:213px;
											height:60px;
											background-size:213px 60px;
											position:absolute;
											top:145px;
											left:3px;
											z-index:1;
											
											@media (max-width: $tileBreakpoint) {
												display:none;
											}
										}//end shadow-circle
										
									}//circleContainer							
									
								}//td		
							}
						}//table
					}//tableContainer
				}//tableOuterContainer	
				
				.left{
					position:absolute;
					left:0px;
					top:0px;
					cursor:pointer;
					width:50px;
					height:515px;
					
					i{
						color:$white;
						padding:243px 0 0 10px;
						@media (max-width: $tileBreakpoint) {
							padding-top:156px;
						}
					}
					
					@media (max-width: $tileBreakpoint) {font-size:0.6875rem}
				}
				
				.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
				
				.right{
					position:absolute;
					right:0px;
					top:0px;
					cursor:pointer;
					width:50px;
					height:515px;
					text-align:right;
					
					i{
						color:$white;
						padding:243px 10px 0 0;
						@media (max-width: $tileBreakpoint) {
							padding-top:156px;
						}
					}
					
					@media (max-width: $tileBreakpoint) {font-size:0.6875rem}
				}
				
				.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}
					
			}//end container_1_2 >div
			
			&.container_2_1>div{
				.dotAbsoluteContainer{
					position:absolute;
					left:0;
					bottom:0;
					width:515px;
					@media (max-width: $tileBreakpoint) {width:340px;}
				}
			
				.tableOuterContainer{
					width:515px;
					height:225px;
					overflow:hidden;
					@media (max-width: $tileBreakpoint) {
						width:340px;
						height:150px;
					}
					
					.tableContainer{
						width:20000em;
						
						>table{
							width:515px;
							height:225px;
							float:left;
							@media (max-width: $tileBreakpoint) {
								width:340px;
								height:150px;
							}
							
							td.halfWidth{
								width:50%;
								padding:0 15px;
								&.first{padding-left:25px;}
								&.second{padding-right:25px;}
								img{
									max-width:220px;
									max-height:215px;
									@media (max-width: $tileBreakpoint) {
										max-width:135px;
										max-height:140px;
									}
								}
								
								.circleContainer{
									position:relative;
									.circle{
										width:180px;
										height:180px;
										background-color:$white;
										border-radius:90px;
										-webkit-border-radius:90px;
										-moz-border-radius:90px;
										z-index:5;
										position:relative;
										img{
											max-width:115px;
											max-height:115px;
										}
										
										@media (max-width: $tileBreakpoint) {
											width:126px;
											height:126px;
											border-radius:63px;
											-webkit-border-radius:63px;
											-moz-border-radius:63px;
											img{
												max-width:80px;
												max-height:80px;
											}
										}
									}
									
									table{
										width:100%;
										height:100%;
									}
									
									.shadow-circle{
										background:url(/images/shadow-circle.png);
										width:213px;
										height:60px;
										background-size:213px 60px;
										position:absolute;
										top:145px;
										left:3px;
										z-index:1;
										
										@media (max-width: $tileBreakpoint) {
											display:none;
										}
									}//end shadow-circle
								}//circleContainer							
							}//td		
						}//table
					}//tableContainer
				}//tableOuterContainer	
				
				.left{
					position:absolute;
					left:0px;
					top:0px;
					cursor:pointer;
					width:50px;
					height:515px;
					
					i{
						color:$white;
						padding:111px 0 0 10px;
						@media (max-width: $tileBreakpoint) {
							padding-top:69px;
						}
					}
				}
				
				.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
				
				.right{
					position:absolute;
					right:0px;
					top:0px;
					cursor:pointer;
					width:50px;
					height:515px;
					text-align:right;
					
					i{
						color:$white;
						padding:111px 10px 0 0;
						@media (max-width: $tileBreakpoint) {
							padding-top:69px;
						}
					}
				}
				
				.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}
			}//end container_2_1 >div
		}//textProductCombi
		
		
		&.topseller{
			padding:30px 0;
			@media (max-width: $tileBreakpoint) {padding:20px 0px;}
			h1, .tilesBigHeadline{
				font-weight:bold;
				font-size:1.625rem;
				text-align:center;
				@media (max-width: $tileBreakpoint) {font-size:1.125rem;}
			}
			.selectContainer{width:300px;margin:15px auto 25px}
			
			.topsellerOuterContainer{
				width:515px;
				overflow:hidden;
				@media (max-width: $tileBreakpoint) {
					width:340px;
				}
				
				
				.topsellerInnerContainer{
					width:20000em;
					height:325px;
					@media (max-width: $tileBreakpoint) {height:185px;}
					overflow:hidden;
					
					.topsellerDouble{
						float:left;
						width:515px;
						padding:0 30px;	
						@media (max-width: $tileBreakpoint) {width:340px;padding:0 20px;}
						
						.first, .second{
							float:left;
							width:220px;
							@media (max-width: $tileBreakpoint) {width:145px}
							
							
							>div{
								text-align:center;
								position:relative;
								font-size:1.125rem;
								@media (max-width: $tileBreakpoint) {font-size:0.75rem}
								font-weight:bold;
							
								.circleBack{
									width:180px;
									height:180px;
									border-radius:90px;
									-webkit-border-radius:90px;
									-moz-border-radius:90px;
									z-index:2;
									background-color:$white;
									margin:0 auto 30px;
									
									@media (max-width: $tileBreakpoint) {
										width:124px;
										height:124px;
										border-radius:62px;
										-webkit-border-radius:62px;
										-moz-border-radius:62px;
										margin-bottom:5px;
									}
									
									table{
										width:100%;
										height:100%;
										img{
											max-width:115px;
											max-height:115px;
											@media (max-width: $tileBreakpoint) {
												max-width:80px;
												max-height:80px;
											}
											
										}//end img
									}//end table
								}//end circleBack
								
								.priceContainer{
									margin-top:5px;
									z-index:3;
									position:relative;
									.oldPrice{font-weight:normal;text-decoration: line-through;}
									.oldPriceFF{font-weight:normal;}
								}
								
								.text{position:relative;z-index:3}
								
								.shadow-circle{
									background:url(/images/shadow-circle.png);
									width:213px;
									height:60px;
									background-size:213px 60px;
									position:absolute;
									top:164px;
									left:3px;
									z-index:1;
									
									@media (max-width: $tileBreakpoint) {
										display:none;
									}
								}//end shadow-circle
								
								a, a:hover{
									color:$white;
									text-decoration:none
								}
							}
							
							
						}
						.first{float:left}
						.second{float:right}
					}//topsellerDouble
				}//topSellerInnerContainer
			}//topSellerOuterContainer
			
			.left{
				position:absolute;
				left:0px;
				top:0px;
				cursor:pointer;
				width:50px;
				height:515px;
				
				i{
					color:$white;
					padding:243px 0 0 10px;
					@media (max-width: $tileBreakpoint) {
						padding-top:156px;
					}
				}
				
				@media (max-width: $tileBreakpoint) {font-size:0.6875rem}
			}
			
			.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
			
			.right{
				position:absolute;
				right:0px;
				top:0px;
				cursor:pointer;
				width:50px;
				height:515px;
				text-align:right;
				
				i{
					color:$white;
					padding:243px 10px 0 0;
					@media (max-width: $tileBreakpoint) {
						padding-top:156px;
					}
				}
				
				@media (max-width: $tileBreakpoint) {font-size:0.6875rem}
			}
			
			.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}
		}//end topseller
		
		&.pressNews{
			padding:30px 0;
			@media (max-width: $tileBreakpoint) {padding:20px 0px;}
			h1, .tilesBigHeadline{
				font-weight:bold;
				font-size:1.625rem;
				text-align:center;
				@media (max-width: $tileBreakpoint) {font-size:1.125rem;}
			}
			
			.newsContainer{
				width:515px;
				height:280px;
				margin:25px 0;
				overflow:hidden;
				@media (max-width: $tileBreakpoint) {
					width:340px;
					height:170px;
					margin:18px 0;	
				}
				
				.newsInnerContainer{
					width:20000em;
					>div{
						float:left;
						width:515px;
						padding:0 30px;	
						@media (max-width: $tileBreakpoint) {width:340px}
						
						.image{
							float:left;
							width:120px;
							margin-right:30px;
							position:relative;
							z-index:20;
							@media (max-width: $tileBreakpoint) {display:none;}
						}
						.text{
							width:305px;
							@media (max-width: $tileBreakpoint) {width:280px}
							float:left;
							font-size:0.875rem;
							
							.category{
								margin-bottom:10px;
								font-size:0.75rem;
								@media (max-width: $tileBreakpoint) {font-size:0.625rem;}
							}
							
							h2{
								margin:0;
								padding:0;
								font-size:1.125rem;
								font-weight:bold;
								overflow:hidden;
								width:265px;
								height:40px;
								
								@media (max-width: $tileBreakpoint) {
									font-size:0.875rem;
									width:280px;
									height:16px;	
								}
							}
							
							.lowerText{
								width:265px;
								height:40px;
								
								@media (max-width: $tileBreakpoint) {
									width:280px;
									height:34px;	
								}
							}
						}//end text
					}//end >div
					
					.divider{
						height:20px;
						margin-top:20px;
						padding:0 60px;
						>div{border-top:1px solid $black;}
						
						@media (max-width: $tileBreakpoint) {
							height:12px;
							margin-top:13px;
							padding:0 25px;
						}
					}//end divider
					
					a,a:hover{
						color:$white;
						text-decoration:underline;
					}
					a:hover{color:$black}
					
				}//end newsInnerContainer
			}
			
			.buttonToTheNews{
				text-align:center;
				margin-top:25px;
				@media (max-width: $tileBreakpoint) {margin-top:15px;}
			}
			.left{
				position:absolute;
				left:0px;
				top:0px;
				cursor:pointer;
				width:50px;
				height:515px;
				
				i{
					color:$white;
					padding:243px 0 0 10px;
					@media (max-width: $tileBreakpoint) {
						padding-top:156px;
					}
				}
				
				@media (max-width: $tileBreakpoint) {font-size:0.6875rem}
			}
			
			.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
			
			.right{
				position:absolute;
				right:0px;
				top:0px;
				cursor:pointer;
				width:50px;
				height:515px;
				text-align:right;
				
				i{
					color:$white;
					padding:243px 10px 0 0;
					@media (max-width: $tileBreakpoint) {
						padding-top:156px;
					}
				}
				
				@media (max-width: $tileBreakpoint) {font-size:0.6875rem}
			}
			
			.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}

		}//end pressnews
		
		&.imageWithText, &.imageWithYoutubeLightbox, &.youtubeInside{
			background-size:cover;	
			-webkit-background-size:cover;
			-moz-background-size:cover;
    		background-repeat: no-repeat;
			background-position:center center;
			padding:0;
			
			//fonts
			h1, .tilesBigHeadline{font-size:1.625rem;font-weight:bold;
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					font-size: 1rem;
				}
			}
			font-size:1rem;
			@media (max-width: $tileBreakpoint) {
				h1, .tilesBigHeadline{font-size:1rem;}
				font-size:0.75rem;
				
				&.container_2_2, &.container_2_1{
					h1, .tilesBigHeadline{font-size:1.125rem;}
					font-size:0.875rem;
				}
			}
			
			
			&>div{
				padding:15px;
				width:100%;
				height:100%;
				position:relative;
				@media (max-width: $tileBreakpoint) {
					padding:10px;
				}
				
				&>div{
					border-radius:5px;
					-webkit-border-radius:5px;
					-moz-border-radius:5px;
					padding:5px;
					position:absolute;
					bottom:20px;
					left:20px;
					right:20px;
					text-align:center;
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						bottom:10px;
						left:10px;
						right:10px;
					}
					
					a.text{text-decoration:underline}
					a.text:hover{text-decoration:none;}
				}
			}
			
			&.container_2_2>div>div{
				bottom:70px;
				left:70px;
				right:70px;
				@media (max-width: $tileBreakpoint) {
					bottom:10px;
					left:10px;
					right:10px;
				}
			}
		}
		
		&.imageWithText{
			h1, .tilesBigHeadline{
				margin-bottom:0;
			}
		}
		
		&.youtubeInside{
			&>div{
				h1, .tilesBigHeadline{font-size:1.625rem;font-weight:bold;}
			}
			
			.iframe-wrapper{
				position:relative;
				padding-bottom:56.25%;
				height:0;
				margin-top:20px;
				
				iframe{
					position:absolute;
					left:0;
					top:0;
					width:100%;
					height:100%;
				}
			}
		}
		
		&.imageWithYoutubeLightbox{
			cursor:pointer;
		}
		
		&.contentProductNew{
			padding:0;
			
			background-size:cover;	
			-webkit-background-size:cover;
			-moz-background-size:cover;
    		background-repeat: no-repeat;
			background-position:center center;
			
			&.container_2_1>div{
				position:relative;
			
				h1, .tilesBigHeadline{
					font-weight:bold;
					font-size:1.625rem;
				}
				font-size:1rem;
				
				@media (max-width: $tileBreakpoint) {
					h1, .tilesBigHeadline{font-size:1rem;}
					font-size:0.6875rem;
				}
				
				.left{
					position:absolute;
					left:0px;
					top:0px;
					cursor:pointer;
					width:50px;
					height:515px;
					
					i{
						color:$white;
						padding:111px 0 0 10px;
						@media (max-width: $tileBreakpoint) {
							padding-top:69px;
						}
					}
				}
				
				.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
				
				.right{
					position:absolute;
					right:0px;
					top:0px;
					cursor:pointer;
					width:50px;
					height:515px;
					text-align:right;
					
					i{
						color:$white;
						padding:111px 10px 0 0;
						@media (max-width: $tileBreakpoint) {
							padding-top:69px;
						}
					}
				}
				
				.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}
			
				.dotAbsoluteContainer{
					position:absolute;
					left:0;
					bottom:0;
					width:515px;
					@media (max-width: $tileBreakpoint) {width:340px;}
				}
			
				.tableOuterContainer{
					width:515px;
					height:225px;
					overflow:hidden;
					@media (max-width: $tileBreakpoint) {
						width:340px;
						height:150px;
					}
					
					.tableContainer{
						width:20000em;
						
						>table{
							width:515px;
							height:225px;
							float:left;
							@media (max-width: $tileBreakpoint) {
								width:340px;
								height:150px;
							}
							
							td.halfWidth{
								width:50%;
								padding:0 15px;
								&.first{padding-left:25px;}
								&.second{padding-right:25px;}
								img{
									max-width:220px;
									max-height:215px;
									@media (max-width: $tileBreakpoint) {
										max-width:135px;
										max-height:140px;
									}
								}
								
								.circleContainer{
									position:relative;
									.circle{
										width:180px;
										height:180px;
										background-color:$white;
										border-radius:90px;
										-webkit-border-radius:90px;
										-moz-border-radius:90px;
										z-index:5;
										position:relative;
										img{
											max-width:115px;
											max-height:115px;
										}
										
										@media (max-width: $tileBreakpoint) {
											width:126px;
											height:126px;
											border-radius:63px;
											-webkit-border-radius:63px;
											-moz-border-radius:63px;
											img{
												max-width:80px;
												max-height:80px;
											}
										}
									}
									
									table{
										width:100%;
										height:100%;
									}
									
									.shadow-circle{
										background:url(/images/shadow-circle.png);
										width:213px;
										height:60px;
										background-size:213px 60px;
										position:absolute;
										top:145px;
										left:3px;
										z-index:1;
										
										@media (max-width: $tileBreakpoint) {
											display:none;
										}
									}//end shadow-circle
								}//circleContainer							
							}//td		
						}//table
					}//tableContainer
				}//tableOuterContainer	
			}//end container_2_1 >div
			
		}//contentProductNew
		
		
		&.contentProduct{
			font-size:0.875rem;
			h1, .tilesBigHeadline{font-size:1.875rem;font-weight:bold;}
			h2{font-size:1.125rem}
			padding:0;
			@media (max-width: $tileBreakpoint) {
				font-size:0.6875rem;
				h1, .tilesBigHeadline{font-size:1rem;}
			}
			
			.inner-circle-container{
				width:290px;
				height:290px;
				position:relative;
				margin:10px 0 20px 0;
				
				@media (max-width: $tileBreakpoint) {
					width:210px;
					height:210px;
					margin-bottom:10px;
				}
				
				
				.inner-circle{
					width:290px;
					height:290px;
					border-radius:290px;
					-webkit-border-radius:290px;
					-moz-border-radius:290px;
					overflow: hidden;
					background-color:$white;
					z-index:2;
					position:relative;	
					@media (max-width: $tileBreakpoint) {
						width:210px;
						height:210px;
						border-radius:210px;
						-webkit-border-radius:210px;
						-moz-border-radius:210px;
					}
					
					.innerElementContainer{
						width: 20000em;
						/*position: absolute;*/
						list-style: outside none none;
						margin: 0px;
						padding: 0px;
					
						
						li{
							float:left;
							overflow:hidden;
							width:290px;
							height:290px;
							@media (max-width: $tileBreakpoint) {
								width:210px;
								height:210px;
							}
							
							
							a{
								width:290px;
								height:290px;
								display: block;
								@media (max-width: $tileBreakpoint) {
									width:210px;
									height:210px;
								}
								
								table{ //flex not possible because of ie9
									width:100%;
									height:100%;
								}
								
								img{
									max-width:167px;
									max-height:167px;
									
									@media (max-width: $tileBreakpoint) {
										max-width:140px;
										max-height:140px;
									}
								}
							}
							
						}
					}//end innerELementContainer
				}//end inner-circle
				
				
				.shadow-circle{
					background:url(/images/shadow-circle.png);
					width:313px;
					height:64px;
					position:absolute;
					bottom:-37px;
					left:-11px;
					z-index:1;
					
					@media (max-width: $tileBreakpoint) {
						width:222px;
						height:39px;
						background-size:222px 39px;
						left:-6px;
						bottom:-23px;
					}
				}
				
			}//end inner-circle-container
			
			.left{
				position:absolute;
				left:0px;
				top:0px;
				cursor:pointer;
				width:50px;
				height:515px;
				
				i{
					color:$white;
					padding:243px 0 0 10px;
					@media (max-width: $tileBreakpoint) {
						padding-top:156px;
					}
				}
			}
			
			.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
			
			.right{
				position:absolute;
				right:0px;
				top:0px;
				cursor:pointer;
				width:50px;
				height:515px;
				text-align:right;
				
				i{
					color:$white;
					padding:243px 10px 0 0;
					@media (max-width: $tileBreakpoint) {
						padding-top:156px;
					}
				}
			}
			
			.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}
			
			.imageTextContainer{
				display: flex;
				align-items: center;
				//justify-content: center;
				width:100%;
				height:100%;
			}	
			
			.ellipsis{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
			
			.textContainerOuter{
				width:515px;
				overflow:hidden;
				
				@media (max-width: $tileBreakpoint) {
					width:340px;
				}
				
				.textContainer{
					width: 20000em;
					
					&>div{
						width:515px;
						float:left;
						padding:0 15px;
						@media (max-width: $tileBreakpoint) {
							width:340px;
						}
					}
				}
			}
		
		
		
			//other values for smaller tiles
			&.container_1_2{
				font-size:0.875rem;
				h1, .tilesBigHeadline{font-size:1.625rem;font-weight:bold;}
				h2{font-size:1.125rem}
				@media (max-width: $tileBreakpoint) {
					font-size:0.6875rem;
					h1, .tilesBigHeadline{font-size:1rem;}
				}
				
				.inner-circle-container{
					width:180px;
					height:180px;
					
					@media (max-width: $tileBreakpoint) {
						width:125px;
						height:125px;
						//margin-bottom:10px;
					}
					
					
					.inner-circle{
						width:180px;
						height:180px;
						border-radius:180px;
						-webkit-border-radius:180px;
						-moz-border-radius:180px;
						@media (max-width: $tileBreakpoint) {
							width:125px;
							height:125px;
							border-radius:125px;
							-webkit-border-radius:125px;
							-moz-border-radius:125px;
						}
						
						.innerElementContainer{
							li{
								width:180px;
								height:180px;
								@media (max-width: $tileBreakpoint) {
									width:125px;
									height:125px;
								}
								
								a{
									width:180px;
									height:180px;
									@media (max-width: $tileBreakpoint) {
										width:125px;
										height:125px;
									}
									
									img{
										max-width:125px;
										max-height:125px;
										
										@media (max-width: $tileBreakpoint) {
											max-width:85px;
											max-height:85px;
										}
									}
								}
							}
						}//end innerELementContainer
					}//end inner-circle
				}//end inner-circle-container		
			
				.shadow-circle{
					width:213px;
					height:60px;
					background-size:213px 60px;
					bottom:-37px;
					left:-16px;
					
					@media (max-width: $tileBreakpoint) {
						width:137px;
						height:37px;
						background-size:137px 37px;
						left:-6px;
						bottom:-23px;
					}
				}
			
				.textContainerOuter{
					width:250px;
					@media (max-width: $tileBreakpoint) {
						width:165px;
					}
					
					.textContainer>div{
						width:250px;
						@media (max-width: $tileBreakpoint) {
							width:165px;
						}
					}
				}//end textContainerOuter
			}//end &.container_1_2
			
			
			&.container_1_1{
				h1, .tilesBigHeadline{font-size:1rem;font-weight:bold;}
				h2{font-size:1rem}
				@media (max-width: $tileBreakpoint) {
					h1, .tilesBigHeadline{font-size:0.6875rem;margin:0}
				}
				
				.inner-circle-container{
					width:140px;
					height:140px;
					
					@media (max-width: $tileBreakpoint) {
						width:100px;
						height:100px;
						margin:2px 0;
					}
					
					
					.inner-circle{
						width:140px;
						height:140px;
						border-radius:140px;
						-webkit-border-radius:140px;
						-moz-border-radius:140px;
						@media (max-width: $tileBreakpoint) {
							width:100px;
							height:100px;
							border-radius:100px;
							-webkit-border-radius:100px;
							-moz-border-radius:100px;
						}
						
						.innerElementContainer{
							li{
								width:140px;
								height:140px;
								@media (max-width: $tileBreakpoint) {
									width:100px;
									height:100px;
								}
								
								a{
									width:140px;
									height:140px;
									@media (max-width: $tileBreakpoint) {
										width:100px;
										height:100px;
									}
									
									img{
										max-width:95px;
										max-height:95px;
										
										@media (max-width: $tileBreakpoint) {
											max-width:68px;
											max-height:68px;
										}
									}
								}
							}
						}//end innerELementContainer
					}//end inner-circle
				}//end inner-circle-container		
			
				.shadow-circle{
					width:175px;
					height:59px;
					background-size:175px 59px;
					bottom:-37px;
					left:-17px;
					
					@media (max-width: $tileBreakpoint) {
						width:114px;
						height:38px;
						background-size:114px 38px;
						left:-7px;
						bottom:-23px;
					}
				}
			
				.textContainerOuter{
					width:250px;
					@media (max-width: $tileBreakpoint) {
						width:165px;
					}
					
					.textContainer>div{
						width:250px;
						padding:0 7px;
						@media (max-width: $tileBreakpoint) {
							width:165px;
						}
					}
				}//end textContainerOuter
				
				.left>i,.right>i{
					padding-top:111px;
					@media (max-width: $tileBreakpoint) {
						padding-top:69px;
					}	
				}
			}//end &.container_1_1
			
			
		}//end contentProduct
		
	}// end containerBlock
	
	p{margin:0;padding:0}

	
	
	.table.icons{
		margin:auto;
		height:auto;
		background-color:transparent;
		padding:5px 0;
		
		.table-cell{
			width:106px;
			height:auto;
			padding:0 10px 10px 10px;
			text-align:center;
			vertical-align:top;
			font-size:0.6875rem;
			font-weight:bold;
		}
	}
	
	.specificationContainer{
		height:220px;
		@media (max-width: $tileBreakpoint) {height:145px;}	
		overflow:hidden;
	
		.specificationEntry{
			margin-bottom:8px;
			font-size:0.875rem;
			
			&.floatLeft{
				float:left;
				width:242px;
				@media (max-width: $tileBreakpoint) {width:160px;}	
			}
			
			.specificationHeadline{font-weight:bold;}
			a, a:hover{color:$white}
			a{text-decoration:underline}
		}//specificationEntry
	}//specificationContainer
	
	.gameManuals{
		font-size:1rem;
		@media (max-width: $tileBreakpoint) {font-size:0.875rem}
		.headline{
			font-weight:bold;
			font-size:1.125rem;
			@media (max-width: $tileBreakpoint) {font-size:1rem;}
		}
		
		span.flagContainer{
			display:inline-block;
			background-repeat:no-repeat;
			background-position:center center;
			width:30px;
			height:30px;
			border:1px solid $white;
			border-radius:15px;
			-webkit-border-radius: 15px;
			-moz-border-radius: 15px;
			cursor:pointer;
			margin:0 5px;
		}
		span.flagContainer:hover, span.flagContainer.active{border-color:$orange;}
	
		.buttonContainer{
			display:none;
			a.btn{font-size:0.875rem}
			&.active{display:block}
		}
		
		.table-row {
			.table-cell {
				.dlIcon {
					margin:25px 0 15px 0;
				
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						margin: -4px 0 0px 0;
						transform: scale(0.65,0.65);
					}
				}
				
				
			}
		}
	}//gameManuals
	
	.similarTopics{
		height:220px;
		@media (max-width: $tileBreakpoint) {height:145px;}	
		overflow:hidden;
		.headline{font-weight:bold;margin-bottom:10px;}
		.btnContainer{
			height: auto;
			display: block;
			margin-bottom:10px;
			font-size:0.875rem;
			&.floatLeft{
				>a{font-size:0.875rem}
				float:left;
				width:242px;
				padding:0 5px;
				@media (max-width: $tileBreakpoint) {width:160px;}	
			}
		}
	}
		
	.zoomcontainer::before  {
		content:"";
		position:absolute; top:0;right:0;bottom:0;left:0;
	  	background:inherit;
	  	transition: all .5s ease;
	}		

	a > .zoomcontainer, a.zoomcontainer {
		overflow: hidden;
		display: inline-block;
	}

}//end rasterContainer

.loadContainer{
	margin:10px auto;
	height:40px;
	display:none;
	
	.load{
		padding:10px;
		
		color:white;
		cursor:pointer;	
		text-align:center;
		
		margin:0 auto;
		display:table;
		border-radius:5px;
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		
		//&:hover{background-color:#39C}
	}	
	
	.loadSpinner{
		width:160px;
		height:24px;
		margin:0 auto;
		background:url(/images/loading.gif) no-repeat;
		display:none;	
	}
}

.topImage{
	max-width:100%;
	height:auto;
}
.editorial .priceContainer .familyAndFriends-priceImage {
 img {
 	
	@media (max-width: $doubleTeaserSecondBreakpoint) {
			 content:url("https://via.placeholder.com/30");
			 width:15px;
			 height:32px;
		}
	}
}