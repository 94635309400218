.productFinder{
	max-width:1045px;
	margin:0 auto;
	font-size:0.875rem;
	color:$font-color;
	
	h1{
		font-weight:bold;
		font-size:1.625rem;
		@media (max-width: $tileBreakpoint) {font-size:1rem}
	}

	@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
	
	@media (max-width: $tileBreakpoint) {width:340px;}
	
	
	.headline{
		font-size:1.625rem;
		@media (max-width: $doubleTeaserBreakpoint) {font-size:1rem}
	}
	
	.secondHeadline{
		text-align:center;
		font-style:italic;
		font-size:1.625rem;
		font-weight:bold;
		padding-bottom:60px;
		@media (max-width: $doubleTeaserBreakpoint) {
			font-size:1.5rem;
			padding-bottom:30px;	
		}
	}
	
	&.chooseGender{
		.ring{
			width:210px;
			height:210px;
			text-align:center;
			border-radius:155px;
			-webkit-border-radius:155px;
			-moz-border-radius:155px;
			border:6px solid $white;
			background:url(/images/backgroundffffff.jpg);
			-webkit-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.44);
			-moz-box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.44);
			box-shadow: inset 0px 0px 10px 0px rgba(0,0,0,0.44);
			
			padding-top:60px;
			cursor:pointer;
			&.active{border-color:$orange2 !important}
			&:hover{border-color:$light-grey}

			
			@media (max-width: $doubleTeaserBreakpoint) {
				width:140px;
				height:140px;
				border-radius:70px;
				-webkit-border-radius:70px;
				-moz-border-radius:70px;
				padding-top:38px;
			}
			
			.icon.boyBig{
				display:inline-block !important;
				@media (max-width: $doubleTeaserBreakpoint) {display:none !important}
			}
			.icon.girlBig{
				display:inline-block !important;
				@media (max-width: $doubleTeaserBreakpoint) {display:none !important}
			}
			.icon.boySmall{
				display:none !important;
				@media (max-width: $doubleTeaserBreakpoint) {display:inline-block !important}
			}
			.icon.girlSmall{
				display:none !important;
				@media (max-width: $doubleTeaserBreakpoint) {display:inline-block !important}
			}
			
		}
		
		.genderContainer{
			.ringContainer{float:left}
			
			.ringContainer{
				width:472px;
				padding-left:20px;
				&.first{
					width:473px;
					padding-left:243px;	
				}
				
				@media (max-width: $doubleTeaserBreakpoint) {
					width:237px;
					padding-left:10px;
					&.first{
						width:238px;
						padding-left:88px;	
					}
				}
				
				@media (max-width: $tileBreakpoint) {
					width:150px;
					padding-left:10px;
					&.first{width:150px;padding-left:0}
				}
			}
			
			
		}//genderContainer
		
	}//chooseGender
	
	&.chooseAge{
		.agePriceContainer{
			.agePriceInnerContainer{
				width:945px;
				float:left;
				@media (max-width: $doubleTeaserBreakpoint) {width:100%;}
				
				.valueContainer{
					width:362px;
					margin:0 auto 60px auto;
					@media (max-width: $doubleTeaserBreakpoint) {
						width:300px;
						margin:0 auto 50px auto;	
					}
					
					.inputContainer{
						width:136px;
						float:left;
						@media (max-width: $doubleTeaserBreakpoint) {width:120px}
						input{
							text-align:center;
							font-weight:bold;
							color:$brand-primary;
						}
						.fakeInput{
							font-weight:bold;
							color:$brand-primary;
							text-align: center;
							padding: 10px;
							line-height: 1.2rem;
							border: 2px solid #d7d7d7;
							border-radius: 4px;
							-moz-appearance: none;
						}
					}//inputContainer
					
					.textContainer{
						width:90px;
						text-align:center;
						float:left;
						font-size:1.5rem;
						padding-top:5px;
						@media (max-width: $doubleTeaserBreakpoint) {width:60px}
					}//textContainer
					
				}//valueContainer
				
				.slider{
					.ui-widget-header{
						background-color:$orange2;
						border:2px solid $light-grey;
						height:20px;
					}
					
					span.ui-slider-handle{
						background-color:$orange2;
						border:6px solid $white;
						width:48px;
						height:48px;
						border-radius:24px;
						-webkit-border-radius:24px;
						-moz-border-radius:24px;
						-webkit-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.17);
						-moz-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.17);
						box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.17);
						top:-0.95em;
						cursor:pointer;
					}
					
					
					&.ui-widget-content{
						background:none transparent;
						border:none transparent;
					}
					
				}//slider
				
			}//ageInnerContainer
			
			.smallViewSlider{
				width:300px;
				background:url(/images/sliderBar300.png) no-repeat;
				height:20px;
				display:none;
				margin:0 auto;
				@media (max-width: $doubleTeaserBreakpoint) {display:block}
				>div{width:270px;}
			}
			
			.bigViewSlider{
				width:520px;
				height:20px;
				background:url(/images/sliderBar520.png) no-repeat;
				display:block;
				margin:0 auto;
				@media (max-width: $doubleTeaserBreakpoint) {display:none}
				>div{width:490px;}
			}
			
			.leftArrow, .rightArrow{
				height:140px;
				a{
					i{
						padding-top:44px;
					}
				}
			}
		}//ageContainer
	}//chooseAge
	
	&.categoryOccasion{
		.categoryOccasionContainer{
			.categoryOccasionInnerContainer{
				width:945px;
				float:left;
				@media (max-width: $doubleTeaserBreakpoint) {width:100%;float:none}
				
				.valueContainer{
					width:545px;
					margin:0 auto 60px auto;
					@media (max-width: $doubleTeaserBreakpoint) {
						width:300px;
						margin:0 auto 50px auto;	
					}
					
					.chooseButton{
						border:2px solid $light-grey;
						border-radius:4px;
						-webkit-border-radius:4px;
						-moz-border-radius:4px;
						padding:10px;
						display:none;
						cursor:pointer;
						@media (max-width: $doubleTeaserBreakpoint) {display:block}
						&.active{border-color:$brand-primary}
						
						.text{
							width:236px;
							float:left;
							overflow:hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							font-style:italic;
							color:$dark-grey;
							font-weight:normal;
							line-height:2.4rem;
							
							&.active{
								font-weight:bold;
								font-style:normal;
								color:$brand-primary;
							} 
						}
						
						.button{
							float:right;
							width:40px;
							text-align:right;
							
							.fa-close{
								display:none;
							}
							
						}
					}
					
					
					.leftCol{
						width:245px;
						margin-right:55px;
						float:left;
						
						@media (min-width: $doubleTeaserBreakpoint+1) {display:block !important;}
						@media (max-width: $doubleTeaserBreakpoint) {
							display:none;
							float:none;
							width:100%;
							margin-right:0;
						}
					}//leftCol

					.rightCol{
						width:245px;
						float:left;
						@media (min-width: $doubleTeaserBreakpoint+1) {display:block !important;}
						@media (max-width: $doubleTeaserBreakpoint) {
							display:none;
							float:none;
							width:100%;
						}
					}
					
					.readyButton{
						@media (min-width: $doubleTeaserBreakpoint+1) {display:none !important;}
						@media (max-width: $doubleTeaserBreakpoint) {display:none}
					}
					
					.entry{
						line-height:1.5rem;
						border-bottom:1px solid $light-grey;
						padding:17px 0;
						font-weight:bold;
						cursor:pointer;
						
						&.active{
							color:$brand-primary;
							.check{color:$brand-primary;}	
						}
						
						.name{
							float:left;
							width:215px;
						}
						
						.check{
							float:right;
							color:$dis;
						}
					}
					
				}//valueContainer
				
				
				
			}//categoryOccasionInneContainer
		}//categoryOccasionContainer
	}//categoryOccasion
	
	
	
	.leftArrow, .rightArrow{
		float:left;
		display:block;
		height:210px;
		width:30px;
		@media (max-width: $doubleTeaserBreakpoint) {display:none}
		a{
			display:block;
			height:210px;
			i{
				padding-top:84px;
			}
		}
	}
	
	.naviBottom{
		width:300px;
		height:40px;
		margin:0 auto;
		background-color:$light-grey;
		border-radius:4px;
		-webkit-border-radius:4px;
		-moz-border-radius:4px;
		margin-top:60px;
		
		@media (max-width: $doubleTeaserBreakpoint) {margin-top:60px;}
		
		
		
		>div{
			float:left;
			width:60px;
			height:40px;
			text-align:center;
			border-bottom:4px solid $light-grey;
			line-height:3rem;
			
			&.first{
				-webkit-border-top-left-radius: 4px;
				-webkit-border-bottom-left-radius: 4px;
				-moz-border-radius-topleft: 4px;
				-moz-border-radius-bottomleft: 4px;
				border-top-left-radius: 4px;
				border-bottom-left-radius: 4px;
			}
			
			&.last{
				-webkit-border-top-right-radius: 4px;
				-webkit-border-bottom-right-radius: 4px;
				-moz-border-radius-topright: 4px;
				-moz-border-radius-bottomright: 4px;
				border-top-right-radius: 4px;
				border-bottom-right-radius: 4px;
			}
			
			&.active{border-bottom:4px solid $brand-primary;}
			>div{display:inline-block;}
		}
	}//naviBottom
	
	
	.backButton, .nextButton, .productButtonSmall{
		width:230px;
		@media (max-width: $tileBreakpoint) {width:145px}
	}
	
	.productButtonSmall{
		display:block;
		margin-left:10px;
		@media (max-width: $doubleTeaserBreakpoint) {display:none;}
	}
	
	.productButtonBig{
		margin-top:10px;
		display:none;
		@media (max-width: $doubleTeaserBreakpoint) {display:block;}
	}
	
}//productFinder