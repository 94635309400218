.productlist{
	margin:0 auto;
	width:340px;

	.bigOuterContainer{display:none}
	.smallOuterContainer{display:block}
	@media (min-width: $tileBreakpoint) {
		width:515px;
		.bigOuterContainer{display:block}
		.smallOuterContainer{display:none}
	}
	@media (min-width: 820px) {
		width:780px;
	}
	@media (min-width: 1085px) {
		width:1045px;	
	}
	@media (min-width: 1350px) {
		width:1310px;	
	}
	@media (min-width: 1615px) {
		width:1575px;	
	}
	@media (min-width: 1880px) {
		width:1840px;	
	}	
	@media (min-width: 2145px) {
		width:2105px;	
	}
	@media (min-width: 2410px) {
		width:2370px;	
	}
	@media (min-width: 2675px) {
		width:2635px;
	}
	
	
	.filterMainContainer{
		float:none;
		width:340px;
		margin-right:0;
		margin-bottom:10px;
		position:relative;
		
		@media (min-width: $tileBreakpoint) {
			width:515px;
			margin-bottom:15px;
		}
		@media (min-width: $bigPagerBreakpoint) {
			float:left;
			margin-right:15px;
			width:250px;
		}
		
		.categoryContainer{
			display:none;
			@media (min-width: $bigPagerBreakpoint) {display:block;}
			
			width:250px;
			margin-right:15px;
			//margin-bottom:20px; comment out because of the delete of the string filter below
			
			.categoryBox{
				background-color:$white;
				border-radius: 3px;
				-moz-border-radius: 3px;
				-webkit-border-radius: 3px;
				padding:20px;
				
				a, a:hover{
					color:$font-color;
					font-size:0.875rem;
					font-weight:bold;
					display:block;
				}
				
				.menu2ndLevel{
					margin:10px 0 0 5px;
					color:$font-color;
					font-size:0.875rem;
					font-weight:bold;
					display:block;
					&.first{margin-top:0;}
				}
				.menu3ndLevel{margin-left:10px}
				
			}//end categoryBox
		}//end categoryContainer
	}//end filterMainContainer
	
	
	//.filterHeadline, .sortHeadline, .filterContentHeadline
		.filterHeadline, .sortHeadline, .filterContentHeadline{
			position:relative;
			width:165px;
			height:60px;
			border-radius: 3px;
			-moz-border-radius: 3px;
			-webkit-border-radius: 3px;
			background-color:$brand-primary;
			color:$white;
			float:left;		
			cursor:pointer;
			padding:10px;
			font-weight:bold;
			font-size:1rem;
			
			@media (min-width: $tileBreakpoint) {width:250px;}
			
			&.opened{
				border-radius: 3px 3px 0px 0px;
				-moz-border-radius: 3px 3px 0px 0px;
				-webkit-border-radius: 3px 3px 0px 0px;
			}
			
			.table{
				display:table;
				width:100%;
				height:100%;
				
				.table-cell{
					display:table-cell;
					vertical-align:middle;
					
					&.first{
						width:113px;
						line-height:0.9rem;
						padding-right:5px;
						@media (min-width: $tileBreakpoint) {width:203px;}
					}
					&.last{
						width:32px;
						text-align:right;
					}
					
					.fa-chevron-down, .fa-chevron-up{
						font-size:2rem;
						@media (min-width: $bigPagerBreakpoint){font-size:1.5rem;}
					}
					.fa-chevron-up{display:none}
				}//table-cell
			}//table
		}//filterHeadline,sortHeadline
		
		
		.filterContentHeadline{
			width:340px;
			float:none;
			padding:0;
			@media (min-width: $tileBreakpoint) {width:515px;}
			@media (min-width: $bigPagerBreakpoint) {width:250px;}
			background-color:$white;
			color:$brand-primary;	
			font-size:0.875rem;
			
			@media (max-width: $bigPagerBreakpoint - 1){
				border-radius: 0px;
				-moz-border-radius: 0px;
				-webkit-border-radius: 0px;
				
				border-bottom:2px solid $light-grey;
				font-size:1rem;
			}
			
		}
		
		.filterHeadline{
			@media (min-width: $bigPagerBreakpoint){display:none;}
		}
		
		.sortHeadline{
			display:block;
			margin-left:10px; //normally 10px
			background-color:$white;
			color:$brand-primary;
			@media (min-width: $tileBreakpoint) {margin-left:15px;} //normally 15px
			@media (min-width: $bigPagerBreakpoint) {display:none;}
		}
		
		//extra view for the small SortHeadline
		.smallSortHeadline {
			.table{
				table-layout:fixed;
				.table-cell{
					height:38px;
					overflow:hidden;
					text-overflow:ellipsis;
					white-space:nowrap;
					
					&.first{
						width:108px;//because of table-layout fixed
						@media (min-width: $tileBreakpoint) {width:198px;}
					}
				}
			}
		}
		
		.filterHeadline .badge{
			background-color:$white;
			color:$brand-primary;
		}
	//end .filterHeadline, .sortHeadline, .filterContentHeadline
	
	#filterContainer{
		display:none;
		
		//filtermaincontainer: TODO . comment out because of the wrong behavior on autor page
		@media (min-width: $bigPagerBreakpoint) {
			display: block !important;
		}
		
		.filterContentHeadline{	
			@media (min-width: $bigPagerBreakpoint) {
				//display: block !important; TODO filtermainContainer
				margin-top:15px;
			}
		
			.badge{
				background-color:$brand-primary;
				color:$white;
				margin-left:10px;
			}		
			
			.fa-chevron-down, .fa-chevron-up, .fa-times{font-size:1.5rem;}
			.fa-chevron-up{display:none}
			.fa-times{
				position:absolute;
				left:20px;
				top:17px;
				display:none;
			}
			.table{padding:10px 20px;}
			.table-cell.deleteOption{padding-left:32px;}
			.table-cell.first{line-height:auto;}
				
		}//filterContentHeadline	
		
		.filterContent{
			display:none;
			background-color:$white;
			padding:20px;
			border-radius:0px 0px 3px 3px ;
			-moz-border-radius: 0px 0px 3px 3px;
			-webkit-border-radius: 0px 0px 3px 3px;
			font-size:0.875rem;
			border-top:2px solid $light-grey;
			border-bottom:none;
			overflow-y:auto;
			max-height:265px;
			
			@media (max-width: $bigPagerBreakpoint - 1){
				border-top:none;
				border-radius: 0px;
				-moz-border-radius: 0px;
				-webkit-border-radius: 0px;
				
				border-bottom:2px solid $light-grey;
				font-size:1rem;
			}
			
			.filterContentTable{
				display:table;
				width:100%;
				
				.filterContentTableRow{
					display:table-row;
					
					.filterContentTableCell{
						display:table-cell;
						
						&:first-child{width:30px;}
						&.cursor{cursor:pointer;}
					}
				}
			}
			
			
			
		}//filterContent
		
		.filterEnd{
			background-color:$white;
			padding:20px;
			border-radius:0px 0px 3px 3px ;
			-moz-border-radius: 0px 0px 3px 3px;
			-webkit-border-radius: 0px 0px 3px 3px;
			@media (min-width: $bigPagerBreakpoint){
				display:none;
			}
			
			&>div:first-child{margin-bottom:20px;}
		}

	}//filterContainer
	
	.sortContainer{
		display:none;
		background-color:$white;
		border-radius:0px 0px 3px 3px ;
		-moz-border-radius: 0px 0px 3px 3px;
		-webkit-border-radius: 0px 0px 3px 3px;
		
		.sortEntry{
			//padding: 20px;
			//cursor:pointer;
			
			a{
				text-decoration:none;
				color:$brand-primary;
				display:block;
				padding:20px;
				&:hover{
					background-color:$orange2;
					color:$white;	
				}
			}
			
			&:last-child{
				border-radius:0px 0px 3px 3px ;
				-moz-border-radius: 0px 0px 3px 3px;
				-webkit-border-radius: 0px 0px 3px 3px;
			}
			
		}
	}
	
	.smallSortContainer{
		@media (min-width: $bigPagerBreakpoint){
			display:none !important;
		}
	}
	
	.bigSortHeadline{
		position:relative;
		
		.bigSortContainer{
			position:absolute;
			left:0;
			top:60px;
			min-width:250px;
			z-index:1000;
			-webkit-box-shadow: 0px 14px 5px -4px rgba(0,0,0,0.3);
			-moz-box-shadow: 0px 14px 5px -4px rgba(0,0,0,0.3);
			box-shadow: 0px 14px 5px -4px rgba(0,0,0,0.3);
			
			.sortEntry{
				white-space:nowrap;
			}
		}
	}
	
	
	.shadowBottom{
		-webkit-box-shadow: inset 0px -16px 19px -25px rgba(117,117,117,1);
		-moz-box-shadow: inset 0px -16px 19px -25px rgba(117,117,117,1);
		box-shadow: inset 0px -16px 19px -25px rgba(117,117,117,1);
	}
	
	.shadowBottomDark{
		-webkit-box-shadow: inset 0px -16px 19px -25px rgba(0,0,0,1);
		-moz-box-shadow: inset 0px -16px 19px -25px rgba(0,0,0,1);
		box-shadow: inset 0px -16px 19px -25px rgba(0,0,0,1);
	}
	
	h1.filterH1, h1.categoryH1{
		display:none;
		font-size:1.625rem;
		font-weight:bold;
		@media (min-width: $bigPagerBreakpoint) {display:block;}
	}
	
	h1.categoryH1Small{
		font-size:1rem;
		font-weight:bold;
	}
	.searchContainerCategoryH1{
		width:100%;
		@media (min-width: $bigPagerBreakpoint) {width:50%}
		h1.categoryH1{display:block !important}
	}
	h1.filterH1{margin-bottom:-5px;}
	
	.bigOuterContainer{
		@media (min-width: $tileBreakpoint) {
			float:none;
		}
		@media (min-width: $bigPagerBreakpoint) {
			float:left;		
		}
		
		.bigPagerContainer{
			display:none;
			@media (min-width: $bigPagerBreakpoint) {display:block;}
			min-height:75px;
			ul{
				margin-bottom:0;
				margin-top:0.5rem;
			}
			.sortHeadline{
				@media (min-width: $bigPagerBreakpoint) {display:block;margin-left:0}
			}
		}
		
		
		.teaserContainer{
			display:none;
			@media (min-width: $bigPagerBreakpoint) {display:block;}
			height:250px;
			overflow:hidden;
			margin-bottom:15px;
		}
		
		
		
		.products, .teaserContainer{
			
			.container_2_2, container_2_1{display:none}
			
			@media (min-width: $tileBreakpoint) {
				.container_2_2, container_2_1{display:block}
			}
			
			@media (min-width: $bigPagerBreakpoint) {
				width:515px;
			}
			@media (min-width: 1085px) {
				width:780px;	
			}
			@media (min-width: 1350px) {
				width:1045px;	
			}
			@media (min-width: 1615px) {
				width:1310px;	
			}
			@media (min-width: 1880px) {
				width:1575px;	
			}	
			@media (min-width: 2145px) {
				width:1840px;	
			}
			@media (min-width: 2410px) {
				width:2105px;	
			}
			@media (min-width: 2675px) {
				width:2370px;	
			}
		}//end products
			
		.searchProducts {
			
			.container_2_2, container_2_1{display:none}
			
			@media (min-width: $tileBreakpoint) {
				.container_2_2, container_2_1{display:block}
			}
			
			@media (min-width: 535px) {
				width:515px;
			}
			@media (min-width: 820px) {
				width:780px;
			}
			@media (min-width: 1085px) {
				width:1045px;	
			}
			@media (min-width: 1350px) {
				width:1310px;	
			}
			@media (min-width: 1615px) {
				width:1575px;	
			}
			@media (min-width: 1880px) {
				width:1840px;	
			}
			
			@media (min-width: 2145px) {
				width:2105px;	
			}
			@media (min-width: 2410px) {
				width:2370px;	
			}
			@media (min-width: 2675px) {
				width:2635px;	
			}

		}//end searchProducts
		
	}//end big
	
	.smallOuterContainer{
	
		.container_2_1, .container_2_2{
			position:relative;
			margin-bottom:10px;
		}
		
		.smallContainer{
			width:340px;
			padding:10px;
			background-color:$white;
			position:relative;
			margin-bottom:10px;
			
			
			.table{
				display:table;
				width:315px;
				height:100%;
				margin:0;
				
				.table-row{
					display:table-row;
					height:100%;
					
					.table-cell{
						display:table-cell;
						
						&.image{
							width:155px;
							height:140px;
							padding-right:15px;
							display:table-cell;
							text-align:center;
							vertical-align:middle;
							img{
								max-height:140px;
								max-width:140px;
							}
						}//iamge
						
						&.descriptionContainer{
							width:165px;
							height:140px;
							.table{
								display:table;
								width:100%;
								margin:0;
								height:100%;
								
								.table-row{
									display:table-row;
									height:50%;
									
									.table-cell{
										display:table-cell;
										
										&.first{
											height:100%;
											a.headline, a.headline:hover{
												text-decoration:none;
												font-weight:bold;
											}
										}
										
										&.last{
											height:100%;
											vertical-align:bottom;
											.table-cell{vertical-align:bottom;}
										}
										
										.btn{
											width:40px;
											height:40px;
											padding: 0.375rem 0.5rem;
										}	
										.oldPrice{				
											color: $dark-grey;
											font-size: 0.875rem;
											text-decoration: line-through;
											//white-space: nowrap;
											//line-height:0.5rem;
										}
										.oldPriceFF{				
											color: $dark-grey;
											font-size: 0.875rem;
										}
										
										.newPrice{
											font-weight:bold;
										}
									}//end table-cell
								}
							}//table
						}//descriptionContainer
					}
				}
			}

			
			
			
			.triangleBlue{
				border-width:45px 45px 0 0;
				
				.rotateText{
					font-size:0.7rem;
					
					&.new{margin:-28px 0 0 6px}
				}
			}
			
			.availableContainer{font-size:0.75rem}
			
		}//smallContainer
	}//end small

	h1 {
		background-color: $light-grey;
		display: inline-block;
	}

}//end productlist

