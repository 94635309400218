footer{
	//margin-top:20px;
	@include media-breakpoint-down(xs) {margin-top:10px}
	background:$bg-footer repeat;
	
	
	.newsletterContainer{
		background:$bg-newsletterContainer repeat;
		text-align:center;
		color:$white;
		font-size:1.125rem;
		@include media-breakpoint-down(xs) {display:none}
		
		.newsletterInnerContainer{
			display:table;
			max-width:1075px;
			margin:0 auto;
			padding:0 15px;
			
			.cellLeft, .cellRight{
				display:table-cell;
				vertical-align:middle;
				text-align:right;
			}
			.cellRight{text-align:left;}
		}//newsletterInnerContainer
	}//newsletterContainer
	
	.buttonOuterContainer{
		display:none;
		@media (max-width: 1095px) {display:block}
		//@include media-breakpoint-down(xs) {display:block}
		padding:15px 0;
		
		.buttonInnerContainer{
			display:table;
			
			width:340px;
			margin:0 auto;
			
			.buttonRow{
				display:table-row;
				
				.buttonCell{
					display:table-cell;
					width:170px;
					padding:5px 0;
					height:40px !important;
					vertical-align:top;
					
					&:first-child{padding-right:5px;}
					&:last-child{padding-left:5px}
					
					.btn{
						white-space:normal;
						height:40px !important;
						font-size:0.75rem;
						font-weight:normal;
						display:table;
						padding:0;
						
						span{
							display:table-cell;
							height:40px;
							width:170px;
							text-align:center;
							vertical-align:middle;
						}
					}
				}
			}
		}//buttonInnerContainer
	}//buttonOuterContainer
	
	
	.paymentOuterContainer{
		padding:30px 0;
		
		background:$bg-paymentContainer repeat;
		color:$white;
		
		.hideMobile{display:block; @include media-breakpoint-down(xs) {display:none}}
			
		.showMobile{display:none; @include media-breakpoint-down(xs) {display:block}}
		
		.paymentInnerContainer{
			display:block;
			width:1030px; //1000 + 2*15
			margin:0 auto;
			padding:0 15px;
			
			
			@media (max-width: 1050px) {
				width:570px; 	
			}
			
			@media (max-width: 590px) {
				width:340px; //320 + 2*10
				padding:0 10px;	
			}
			
			
			.paymentIcons, .shippingIcons{
				display:block;
				width:210px;
				@include media-breakpoint-down(xs) {display:none}
			}
			
			
			
			.paymentContainer{
				float:left;
				margin-right:10px;
				&.last{margin-right:0}
				min-height:130px;
				@include media-breakpoint-down(xs) {
					margin:0;
					float:none;
					width:340px;
					margin: 0 auto;
					min-height:auto;
					&.last{margin-top:20px;}
				}
				
				.iconOuterContainer{
					float:left;
					margin:0 10px 10px 0;
				
					.iconContainer{
						display:table;
						width:59px;
						height:33px;
						background-color:$white;
						-webkit-border-radius: 3px;
						-moz-border-radius: 3px;
						border-radius: 3px;
						span {
							display:table-cell;
							text-align:center;
							vertical-align:middle;	
						}
					}
				}//iconOuterContainer

				.legal-info {
					width:320px;
					margin-top: 10px;
					a {
						color: white;
						text-decoration: underline;
					}
				}
			}//paymendContainer
			
			.imageContainer{
				width:210px;
				@include media-breakpoint-down(xs) {
					width:309px;
					margin:0 auto;
				}
			}
			
			.hintContainer{
				display:table;
				width:320px;
				margin-top:-3px;
				
				.hintContainerRow{
					display:table-row;
					
					.hintContainerCell{
						display:table-cell;
						width:155px;
						vertical-align:top;
						font-size:0.75rem;
						
						&.check{width:20px;font-size:1rem;}
						&:not(.check){padding:3px 10px 0 5px;}
						&.first{width:170px;}
						&.last{width:115px}
					}
				}
			}
			
		}//paymentInnerContainer
	}//paymentContainer
	
	
	.topicCloudOuterContainer{
		max-width:1075px;
		margin:0 auto;
		padding:0 15px 15px 15px;
		@media (max-width: 1095px) {display:none}
		
		.topicCloudContainer{
			float:left;
			
			.topicCloudInnerContainer{
				padding-right:10px;
				&.last{padding-right:0;}
				
				.h3{font-weight:bold;color:$brand-primary;font-size:0.875rem;padding:0;margin:30px 0 7px 0}
				a, a:hover{
					color:$font-color;
					text-decoration:none;
					display:block;
					padding:2px 0;
					font-size: 0.75rem
				}
				a:hover{color:$black;}
				
			}
		}
	}//topicCloudOuterContainer
	
	.recommendationOuterContainer{

		border-top:1px solid $light-grey;
		background-color:$white;
		
		.recommendationInnerContainer{
		
			max-width:1075px;
			margin:0 auto;
			padding:15px;
		
			.recommendationContainer{
				float:left;
				
				.checkoutFooterContainer{
					float:left;
					font-weight:bold;
					color:$brand-primary;
					padding-top:22px;
					&.marginRight{
						margin-right:50px;
					}
				}
			}//recommendationContainer
			
			.visitContainer{
				float:right;
				@media (max-width: 1095px) {float:left}
			}//visitContainer
			
			.customRecommendationContainerLeft{
				
				float:left;
					
				.checkoutFooterContainer{
					float:left;
					font-weight:bold;
					color:$brand-primary;
					padding-top:22px;
					&.marginRight{
						margin-right:50px;
					}
				}
			}
			.customRecommendationContainerRight{
				float:right;
				a, a:hover{ 
				
					img.maxHeight{max-height:inherit}
				}
				
				@media (max-width: 1095px) {float:left}
				
			}
				
			.headline{
				float:left;
				font-weight:bold;
				padding:22px 15px 0 0;
				color:$brand-primary;
			}
			
			.icons{float:left}
			
			a, a:hover, span{
				text-decoration:none;
				display:inline-block;
				float:left;
				
				img.maxHeight{max-height:35px}
			}
		}//recommendationInnerContainer
	}//recommendationOuterContainer
	
	.impressumOuterContainer{
		height: 150px;
		background-color: inherit;
		
		.impressumInnerContainer{
			background-color:$footer-impressum-color;
			color: white; // needed for the accessibility tool aqa!
			margin:0 auto;
			padding:15px;
			text-align:center;
			
			
			a, a:hover{
				display:inline-block;
				text-decoration:none;
				color:$white;
				font-size:0.875rem;
			}
			a:hover{
				text-decoration:underline;
			}
			.separator{
				display:inline-block;
				padding:0 24px;
				@include media-breakpoint-down(xs) {padding:0 5px;}
			}
			span{display:inline-block;color:$white;font-size:0.875rem;}
		}
	}
}

#Logo_Facebook{
	height: 35px;
}

#Logo_YouTube{
	height: 50px;
}