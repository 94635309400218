#pressDetailContainer{
	.content{
		max-width:1045px;
		margin:0 auto;
		
		h1{
			font-weight:bold;
			font-size:1.625rem;
		}
	
		@media (max-width: $doubleTeaserBreakpoint) {
			width:515px;
			font-size:0.875rem;	
			h1{font-size:1.375rem;}
		}
		
		@media (max-width: $tileBreakpoint) {width:340px;}
		
		.pressDetail{
		
			img.imageLeftFloated{
				float:left;
				margin:0 10px 10px 0;
				width:515px;
				@media (max-width: $doubleTeaserBreakpoint) {
					float:none;
					margin:0 0 10px 0;
				}
				@media (max-width: $tileBreakpoint) {width:340px;}
			}
			
			img.imageRightFloated{
				float:right;
				margin:0 0 10px 10px;
				width:515px;
				@media (max-width: $doubleTeaserBreakpoint) {
					float:none;
					margin:0 0 10px 0;
				}
				@media (max-width: $tileBreakpoint) {width:340px;}
			}
			
			.morePressText{
				display:none;
				@media (max-width: $tileBreakpoint) {display:block;margin-bottom:20px}
			}
			
			.pressReleaseText{
				height:auto;
				position:relative;
				@media (max-width: $tileBreakpoint) {height:250px;overflow:hidden;}
				
				.overlap{
					display:none;
					position:absolute;
					bottom:0;
					left:0;
					width:340px;
					height:50px;
					@include gradient-y(rgba(255,255,255,0.1),rgba(255,255,255,1));
					@media (max-width: $tileBreakpoint) {display:block;}
				}
			}
			
			
		}//pressDetail
		
		.leftAuthorBox{
			float:left;
			@media (max-width: $doubleTeaserBreakpoint) {float:none;}	
		}
		
		.rightAuthorBox{
			float:right;
			@media (max-width: $doubleTeaserBreakpoint) {float:none;}	
		}
		
		.authorBox{
			background-color:$authorBoxBackground;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			margin:20px 0;
			padding:35px;
			width:515px;
			font-size:0.875rem;
			@media (max-width: $tileBreakpoint) {font-size:0.625rem;width:340px;padding:25px;}
			
			.authorText{
				float:left;
				padding-right:10px;
				width:325px;
				@media (max-width: $tileBreakpoint) {width:210px;}
			}
			
			.authorImage{
				float:left;
				width:120px;
				@media (max-width: $tileBreakpoint) {width:80px;}
			}
			
			
			img{
				width:120px;
				@media (max-width: $tileBreakpoint) {width:80px;}
			}
		}//authorBox
		
	}//content


}//pressDetailContainer

.publicationDate{font-size:0.75rem;margin-bottom:10px;}

.rasterPressDetailContainer{
	
	
	//reset rasterContainer for tiles
	.rasterContainer{
	
		width:1045px;
		margin:0 auto;
		@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
		@media (max-width: $tileBreakpoint) {width:340px;}	
	
		height:auto;
		
		.containerBlock{
			display:block;
		}
		
		.container_1_1{
			position:relative;
			margin:0 15px 15px 0;
			float:left;
			left:0;
			top:0;
			@media (max-width: $tileBreakpoint) {margin:0 10px 10px 0}
			
			&.last{margin-right:0;}
			@media (max-width: $doubleTeaserBreakpoint) {
				&.second{margin-right:0;}
			}
		}
	}//end rasterContainer
	
}//end rasterPressDetailContainer



.pressOverview{
	.content{
		max-width:1045px;
		margin:0 auto;
		
		h1{
			font-weight:bold;
			font-size:1.625rem;
		}
	
		@media (max-width: $doubleTeaserBreakpoint) {
			width:515px;
			font-size:0.875rem;	
			h1{font-size:1.375rem;}
		}
		
		@media (max-width: $tileBreakpoint) {width:340px;}
		
		
		.pressFilterBox{
			background-color:$pressFilterBoxBackground;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			padding:30px 20px;
			margin-top:20px;
			
			.table{
				display:table;
				width:100%;
				background:none;
				margin:0;
				font-size:0.875rem;
				
				.table-row{
					display:table-row;
				
					.table-cell{
						display:table-cell;
						text-align:center;
						vertical-align:middle;
						height:100%;
						
						&.text,&.searchfield{width:50%;padding:0 5px;}
						&.text{
							text-align:right;
							span{display:block}	
						}
						
						@media (max-width: $doubleTeaserBreakpoint) {
							&.text,&.searchfield{
								display:block;
								text-align:left;	
								width:100%;
							}
							
							&.text{
								margin-bottom:10px;
								span{display:inline}	
							}
						}
						
						.filterBoxContainerSwitch{
							display:none;
							@media (max-width: $doubleTeaserBreakpoint) {display:block}
							
							border-top:2px solid $pressFilterGrey;
							margin:5px 0;
							cursor:pointer;
							color:$brand-primary;
							font-weight:bold;
							padding-top:8px;
							
							
							.filterPlus{
								display:none;
								@media (max-width: $doubleTeaserBreakpoint) {display:block}
							}
							
							.filterMinus{
								display:block;
								@media (max-width: $doubleTeaserBreakpoint) {display:none}								
							}
						}
						
						.filterBoxContainer{
							@media (min-width: $doubleTeaserBreakpoint + 1) {display:block !important}; 
							@media (max-width: $doubleTeaserBreakpoint) {display:none}
						}
					}//table-cell
				}//tabel-row
			}//table
			
			.searchOuterContainer{
				width:75%;
				@media (max-width: $doubleTeaserBreakpoint) {width:100%;padding:0;}	
			}
		}//pressFilterBox

	
	.pressEntries{
		.pressEntry{
			font-size:0.875rem;
			h2{font-size:1.125rem;font-weight:bold}
			//height:250px;
			background-color:$pressFilterBoxBackground;
			-webkit-border-top-left-radius: 4px;
			-webkit-border-bottom-left-radius: 4px;
			-moz-border-radius-topleft: 4px;
			-moz-border-radius-bottomleft: 4px;
			border-top-left-radius: 4px;
			border-bottom-left-radius: 4px;
			
			margin-bottom:15px;
			@media (max-width: $doubleTeaserBreakpoint) {margin-bottom:10px;height:auto;}
			
			.table{
				display:table;
				width:100%;
				height:100%;
				background:none;
				margin:0;
				font-size:0.875rem;
				
				.table-row{
					display:table-row;
				
					.table-cell{
						display:table-cell;
						text-align:center;
						vertical-align:middle;
						
						&.text{
							width:540px;
							padding:20px;
							text-align:left;
							@media (max-width: $doubleTeaserBreakpoint) {
								padding:15px;
								width:515px;
							}
							@media (max-width: $tileBreakpoint) {
								padding:10px;
								width:340px;	
							}
							
							.pressText{
								max-height:150px;
								overflow:hidden;
								@media (max-width: $doubleTeaserBreakpoint) {max-height:none}
								a{text-decoration:underline}
							}
						}
						
						&.download{
							width:515px;
							@media (max-width: $tileBreakpoint) {
								width:340px;	
							}
						}
						
					}//table-cell
				}//table-row
			}//table	
			
			@media (max-width: $doubleTeaserBreakpoint) {
				&>.table>.table-row>.table-cell{display:block;width:100%}	//only first table cell should be display block
			
			}
			.downloadbox{
				background-color:$brand-primary;
				padding:15px;
				@media (max-width: $tileBreakpoint) {padding:10px;}
			
				&.short{
					width:250px;
					height:250px;
					margin-right:15px;
					@media (max-width: $tileBreakpoint) {
						width:165px;
						height:165px;
						margin-right:10px;
					}
				}//short
				&.long{
					width:515px;
					height:250px;
					@media (max-width: $tileBreakpoint) {
						width:340px;
						height:165px;
					}
				}//long
				
				a, a:hover{
					text-decoration:none;
					display:block;
					width:100%;
					height:100%;
				}
				
				h3{
					color:$white;
					font-size:1.625rem;
					font-weight:bold;
					@media (max-width: $tileBreakpoint) {font-size:1.125rem}
				}
				
				@media (max-width: $tileBreakpoint) {
					.btn{font-size:0.75rem}
				}
			
			}//downloadbox
			
			.imageBlock{
				background-size:cover;	
				-webkit-background-size:cover;
				-moz-background-size:cover;
	    		background-repeat: no-repeat;
				background-position:center center;
			
				width:250px;
				height:250px;
				@media (max-width: $tileBreakpoint) {
					width:165px;
					height:165px;
				}
				
				a, a:hover{
					text-decoration:none;
					display:block;
					width:100%;
					height:100%;
				}
			}
			
		}//pressEntry
	}//pressEntries




	}//content
}//pressoverview










