html {
   -ms-overflow-style: scrollbar;
   overflow-y: auto;
}

body{
	background:$bg-body repeat;
	position:relative;
	font-family: 'Open Sans', sans-serif;
	color:$font-color;
}

#foo{
	position:fixed;
	z-index:9999;
	background-color:rgba(255,255,255,0.6);
	font-size:10px;
	padding:2px;
}

::-webkit-scrollbar {
    width: 15px;
    height: 15px;
    border-bottom: 1px solid #eee; 
    border-top: 1px solid #eee;
}
::-webkit-scrollbar-thumb {
    border-radius: 8px;
	-webkit-border-radius:8px;
	-moz-border-radius:8px;
    background-color: #C3C3C3;
    border: 2px solid #eee;
}

::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2); 
}

#viewTileHelper{ //show or hides the div in small or desktop view
	display:block;
	@media (max-width: $tileBreakpoint) {display:none}
}

#viewEditorialHelper{ //show or hides the div in small or desktop view
	display:block;
	@media (max-width: $doubleTeaserSecondBreakpoint) {display:none}
}

a{border:0 none;}
.clear{clear:both}
h2{font-size:1.625rem;}


//buttons
.btn-round, .btn-square{
	width:38px;
	height:38px;
	padding:0.375rem;
	
}

.btn-round{
	border-radius:19px;
	-webkit-border-radius:19px;
	-moz-border-radius:19px;
}

.btn-dis:hover{cursor:default !important;background-color:$white !important;}

.btn{overflow:hidden;text-overflow:ellipsis}

.heart{
	color:$heartColor;
	&:hover{color:$brand-primary}
}

.orange, .orange4{color:$orange4 !important;}
.orange1{color:$orange !important}
.white{color:$white;}
.brand-primary{color:$brand-primary !important;}
.fakeLink{color:$brand-primary !important;text-decoration:underline;cursor:pointer}

.badge{
	 padding:0 0.5rem;
	 border-radius:0.7rem;
	-webkit-border-radius:0.7px;
	-moz-border-radius:0.7px;
}

.bold{font-weight:bold}
.green-check{color:$green-check}
.blue-check{color:$blue-check}

.smallText0625{font-size:0.625rem}
.smallText075{font-size:0.75rem;}

.bigText1125{font-size:1.125rem}
.bigText125{font-size:1.25rem}
.bigText1625{font-size:1.625rem}

.disabledColor{color:$dark-grey}

a.userLinkInContent{
	font-weight: 700;
	&:hover{text-decoration:none !important;}
}


.tileBreakpointVisible{
	display:none;
	@media (max-width: $tileBreakpoint) {display:inline;}
}

.tileBreakpointHide{
	display:inline;
	@media (max-width: $tileBreakpoint) {display:none;}
}

.doubleTeaserSecondBreakpointVisible{
	display:none;
	@media (max-width: $doubleTeaserSecondBreakpoint) {display:inline;}
}

.doubleTeaserSecondBreakpointHide{
	display:inline;
	@media (max-width: $doubleTeaserSecondBreakpoint) {display:none;}
}


.doubleTeaserBreakpointVisible{
	display:none;
	@media (max-width: $doubleTeaserBreakpoint) {display:inline;}
}

.doubleTeaserBreakpointHide{
	display:inline;
	@media (max-width: $doubleTeaserBreakpoint) {display:none;}
}


.freeGrey{color:$freeGrey}

.infoIcon{
	cursor:help;
	color:$infoIcon;
	
}

.errorBox, .successbox{
	color:$white;
	background-color:$inputError;
	padding:20px;
	font-weight:bold;
	font-size:1rem;
	border-radius:3px;
	-webkit-border-radius:3px;
	-moz-border-radius:3px;
	display:block;
}
.errorBorder{border-color:$inputError !important;}
.errorBorder2{border:2px solid $inputError;}
.errorText{color:$inputError !important;}

.successbox{
	background-color:$successboxColor;
}

.bigHeadline{
	font-size:1.625rem;
	font-weight:bold;
	&.marginbigHeadlineTop{
		margin-top:55px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {margin-top:30px;}
	}
}


.hideSmall{
	display:block;
	@media (max-width: $doubleTeaserBreakpoint) {display:none}
}

.showSmall{
	display:none;
	@media (max-width: $doubleTeaserBreakpoint) {display:block}
}

//----------------------------------------------
//triangles

.triangleBlue{
	position:absolute;
	left:0;
	top:0;
	width: 0;
	height: 0;
	border-style: solid;
	border-width: 60px 60px 0 0;
	border-color: $triangle-blue transparent transparent transparent;
	
	.rotateText{
		color:$white;
		font-weight:bold;
		display:block;					
		-moz-transform: rotate(-45deg);
		-ms-transform: rotate(-45deg);
		-o-transform: rotate(-45deg);
		-webkit-transform: rotate(-45deg);
		
		transform: rotate(-45deg);
		
		&.new{margin:-35px 0 0 6px}
	}
}//end triangleBlue

//----------------------------------------------
//dots

ul.dots-vertical-container{
	margin-bottom:0px;	
	margin-top:5px;
	padding:0;
	text-align:center;
	z-index:5; //because of the shadow 
	position:relative; //because of the shadow 
	
	li.dot-vertical{
		width:16px;
		height:16px;
		margin:0 7px;
		border-radius:8px;
		-webkit-border-radius:8px;
		-moz-border-radius:8px;
		border:3px solid $white;
		background-color:$white;	
		cursor:pointer;
		list-style:none;
		display:inline-block;
	}	
	
	&.orange{
		li.dot-vertical{
			border-color:$orange2;
			background-color:$orange2;
		}
	}
}
/* Bullet point fix for product sliders displayed in a flyout */
.flyoutContainer {
	
	.container_1_1 {
		.dots-vertical-container {
			display:table;	
		}
	}	
	
	.dot-vertical {
		margin-right: 7px !important;
		margin-left: 7px;
		display:inline;
	}

	.container_2_1 {
		.dotAbsoluteContainer {
			width: auto !important;
			left: 50% !important;
		}
		
		.dots-vertical-container {
			left: -50%;
			display: block;
		}
	}
}

	
.no-touchevents li.dot-vertical:hover, li.dot-vertical-active{
	background-color:$brand-secondary !important;	
}

.no-touchevents .orange li.dot-vertical:hover, .orange li.dot-vertical-active{
	background-color:$white !important;	
}

@media (max-width: $tileBreakpoint) {
	li.dot-vertical{
		width:10px !important;
		height:10px !important;
		border-radius:5px !important;
		-webkit-border-radius:5px !important;
		-moz-border-radius:5px !important;
		margin:0 5px !important;
		border-width: 2px !important;
	}
}


#dots{
	position:fixed;
	right:20px;
	z-index:1999;
	/*
	border:1px solid #fff;
	background-color:rgba(255,255,255,0.5);
	width:30px;	
	border-radius:15px;
	-webkit-border-radius:15px;
	-moz-border-radius:15px;*/
	
	top:-9999px;
	//padding:0 4px;
	@include media-breakpoint-down(xs) {display:none}
}

.dot{
	width:16px;
	height:16px;
	margin-bottom:16px;
	border-radius:8px;
	-webkit-border-radius:8px;
	-moz-border-radius:8px;
	//border:2px solid rgba(255,255,255,0.5);
	background-color:rgba(255,255,255,0.5);	
	cursor:pointer;
	z-index:2000;
}

.no-touchevents .dot:hover, .dotActive{
	background-color:rgba(255,255,255,1);	
}


/*-------------------------------------------------------*/

//checkbox and radio buttons design

.radioCheckContainer2{
		label {width: 100%;} 
		input[type="checkbox"], 
		input[type="radio"]{
		  	position: absolute;
		  	width: 20px;
		  	height: 20px;
		  	margin: 0;
		 	border: 1px solid transparent;
		}
		label.labelcheckbox:before {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			position: absolute;
			left: 0;
			background-color: white;
			border-radius: 3px;
			box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(200, 200, 255, .8);
		}
		label.labelradio:before {
			content: "";
			display: inline-block;
			width: 20px;
			height: 20px;
			position: absolute;
			left: 0;
			background-color: white;
			border-radius: 1rem;
			box-shadow: inset 0px 2px 3px 0px rgba(0, 0, 0, .3), 0px 1px 0px 0px rgba(200, 200, 255, .8);
		}
		label.labelcheckbox, 
		label.labelradio {	
			cursor: pointer;
			position: relative;
			padding-left: 30px;
			margin-right: 15px;
		}
		input[type="checkbox"]:checked + label.labelcheckbox:before {  
		    background-image: url(/images/check.png);
		}
		input[type="radio"]:checked + label.labelradio:before {  
		    background-image: $icon-radio;
		} 
		.checkbox label.labelcheckbox {
			margin-bottom: 10px;
		}
		 .checkbox label:before {  
		    border-radius: 3px;  
		}  
		.radio label.labelradio {
			margin-bottom: 10px;
		}
		 .radio label:before {  
		    border-radius: 3px;  
		}
		span.image{
    		float: right;
    		display: block;
		}
		span.secondline {
		    float: left;
		    display: block;
		}					 		
		span.firstline {
		    display: block;
		}		
} 


//remove standard behavior if possible
select,input[type='checkbox'],
input[type='radio'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border:none;
  border-radius: 0;
-webkit-border-radius:0px;
-moz-border-radius:0px;
  font-size: 1em;
  width: 100%
}

//styling

//select boxes
select {
	width:100%;
	//border: 1px solid #bbb;
	padding:.60em 3em .5em 1em;
	height:40px;
	//box-shadow: 0 2px 1px 0 rgba(0,0,0,0.2);
	background-color:white;
	background-image:$icon-select;
	@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
		background-image: $icon-select_2x;
	}
	background-position: right;
	background-repeat: no-repeat;
	background-size: 30px 13px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	cursor:pointer;
	color:$dark-grey;
	font-style:italic;
}

select:disabled {
	background-image:url('/images/select-gray.png');
}

select:hover {
	//box-shadow: 0 1px 1px 0 rgba(0,0,0,0.1);
}

/* hide browser-styling (arrow) in IE10, for lte ie9 there is a conditional css file lte_ie9.css where the background image is none and a fix for padding */
select::-ms-expand {
  display:none;
}

//own select Container
.selectContainer{
	width:100%;
	height:40px;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	background-color:$white;
	cursor:pointer;
	color:$dark-grey;
	font-style:italic;
	text-align:left;
	position:relative;
	z-index:20;
	
	&.setBottomBorderToZero{
		-webkit-border-bottom-right-radius: 0px;
		-webkit-border-bottom-left-radius: 0px;
		-moz-border-radius-bottomright: 0px;
		-moz-border-radius-bottomleft: 0px;
		border-bottom-right-radius: 0px;
		border-bottom-left-radius: 0px;
	}
	
	.selectMain{
		padding:.75em 3em .5em 1em;
		white-space:nowrap;
		overflow-x:hidden;
		text-overflow:ellipsis;
	}
	
	.selectCloser, .selectOpener, .selectCloserWhite, .selectOpenerWhite {
		background-image:$icon-select;
		@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
			background-image: $icon-select_2x;
		}
		background-position: right;
		background-repeat: no-repeat;
		background-size: 30px 13px;
		position:absolute;
		right:0;
		top:14px;
		width:30px;
		height:13px;
	}
	
	.selectCloser{
		background-image: $icon-selectClose;
		@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
			background-image: $icon-selectClose_2x;
		}
		display:none;
	}
	
	.selectCloserWhite{
		background-image:url('/images/selectCloseWhite.png');
		@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
			background-image:url('/images/selectCloseWhite@2x.png');
		}
		display:none;
	}
	
	.selectOpenerWhite{
		background-image:url('/images/selectWhite.png');
		@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
			background-image:url('/images/selectWhite@2x.png');
		}
	}
	
	.selectEntryContainer{
		-webkit-border-bottom-right-radius: 4px;
		-webkit-border-bottom-left-radius: 4px;
		-moz-border-radius-bottomright: 4px;
		-moz-border-radius-bottomleft: 4px;
		border-bottom-right-radius: 4px;
		border-bottom-left-radius: 4px;
		position:absolute;
		left:0;
		top:40px;
		display:none;
		z-index:100;
		background-color:$white;
		border-top:2px solid $dark-grey;
		max-height:250px;
		overflow-y:auto;
		
		.selectEntry{
			padding:.75em 1em .5em 1em;
			
			&:hover, &.active{
				background-color:$brand-secondary;
				color:$white;
			}
		}
	}//select EntryContainer
	
	&.white{
		border:2px solid $pressFilterGrey;
		font-style:normal;
		color:$menu-first-text-color;
		height:44px;
		
		.selectEntryContainer{
			left:-2px;
			top:36px;
			border-top:2px solid $pressFilterGrey;
			border-bottom:2px solid $pressFilterGrey;
			
			.selectEntry{
				border-left:2px solid $pressFilterGrey;
				border-right:2px solid $pressFilterGrey;
			}
		}
		
	}	
}



.radioCheckContainer:not(#foo) > input[type='radio'], .radioCheckContainer:not(#foo) > input[type='checkbox'] {
    /* Hide the input, but have it still be clickable */
    opacity: 0;
    float: left;
    width: 20px;
    margin-left:-20px;
}


.radioCheckContainer:not(#foo) > input[type='radio'] + label, .radioCheckContainer:not(#foo) > input[type='checkbox'] + label {
    margin: 0;
    clear: none;
    /* Left padding makes room for image */
    padding:2px 0 9px 24px;
    //padding: 5px 0 4px 24px;
    /* Make look clickable because they are */
    cursor: pointer;
    background: url(/images/radio-off.png) left 3px no-repeat;
    
    &.right{
    	padding: 5px 24px 4px 0;
    	background-position: right 3px;
    }
}

.radioCheckContainer:not(#foo) > input[type='checkbox'] + label{background-image: url(/images/check-off.png);}
.radioCheckContainer:not(#foo) > input[type='radio']:checked + label {background-image: $icon-radio;}
.radioCheckContainer:not(#foo) > input[type='checkbox']:checked + label {background-image: $icon-check;}


input[type='text'], input[type='password'], textarea{
	/* Remove First */
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    
	width:100%;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	border:2px solid $pressFilterGrey;
	padding:10px;
	line-height:1.2rem;
}

/*
.checkboxOuterContainer{
	padding:3px 0 0 25px;
	position:relative;
	.checkboxInnerContainer{
		position:absolute;
		left:0;
		top:0;
	}
}
*/
//end checkbox and radio buttons design

/*--------------------------------------------------------------------*/

.available{
	width:16px;
	height:16px;
	display:inline-block;
	-webkit-border-radius:8px;
	-moz-border-radius:8px;
	border-radius:8px;
	vertical-align:middle;
	
	&.green{background-color:$available-green}
	&.yellow{background-color:$available-yellow}
	&.red{background-color:$available-red}
}

.playBig, .playSmall{
	position:absolute;
	left:50% !important;
	top:50% !important;
	margin-left:-40px;
	margin-top:-28px;
	
	
}

.playSmall{
	margin-top:-17px;
	margin-left:-25px;
	
	@media (max-width: $tileBreakpoint) {
		top:10px !important;
		margin-top:0;
	}	
}


a.category{
	color:$black;
	text-decoration:underline;
	font-size:0.75rem;
	display:block;
}
a.category:hover{color:$brand-primary;}

//pagination, overwrite the bootstrap pagination with extra class rvPagination
.pagination{
	&.rvPagination{
		.page-item{
			.page-link{
				background-color:transparent;
				border:0 none;
				
				color:$font-color;
				//font-size:1.5rem;
				//@media (min-width: $bigPagerBreakpoint) {
					font-size:0.875rem;
				//}	
				&:hover{
					text-decoration:none;
					color:$black;
				}
				
				&.active{color:$brand-primary;font-weight:bold;}
			}//end page-link
			
			//show hide for tablet view e.g. productlist first pagination in big view
			&.showPageItem{
				display:inline;
				@media (min-width: 1085px) {display:none}
			}
			&.hidePageItem{
				display:none;
				@media (min-width: 1085px) {display:inline}
			}
			
			&:first-child .page-link{
			
			}
		}
	}
}

.puzzlePointsCircle{
	width:40px;
	height:40px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	color:$white;
	background-color:$brand-primary;
	font-size:0.6875rem;
	text-align:center;
	padding-top:12px;
}
a.puzzlePoints, a.puzzlePoints:hover{
	font-weight:bold;
	font-size:0.875rem;
	text-decoration:none;
}


.filterBoxContainer{
						
	ul{
		list-style-type:none;
		margin:0;
		padding:0;
		
		li{
			display:inline-block;
			
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			
			a.filterbox, div.filterbox{
				background-color:$white;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				border-bottom:4px solid $pressFilterGrey;
				margin:8px;
				padding:9px 15px 8px 16px;
				color:$black;
				white-space:nowrap;
				font-weight:bold;
				display:block;
				text-decoration:none !important;
				
				@media (max-width: $doubleTeaserBreakpoint) {
					padding:3px 0;
					margin:5px 0;
				}
				
				span{
					display:inline-block;
					vertical-align:6px;
					padding-right:10px;	
				}
				i{
					color:$pressFilterGrey;
					text-decoration:none;
				}
				
				&.active{
					color:$brand-primary;
					border-bottom-color:$brand-primary;
					i{color:$brand-primary;}
				}
				
				&.deactivate{
					color:$pressFilterGrey;
					&:hover{border-bottom-color:$pressFilterGrey;}
				}
				
			}// a
			
			div.filterbox{
				color:$font-color;
				&.active{
					border-bottom-color:$pressFilterGrey;
					.blueBackgroundNumber{
						background-color:$brand-primary;
					}
				}
				&.passed{
					border-bottom-color:$brand-primary;
				}
				/*
				.blueBackgroundNumber{
					background-color:$brand-primary;
				}*/
			}
			
			a.filterbox{
			 	&:hover{
					color:$brand-primary;
					border-bottom-color:$brand-primary;
					i{color:$brand-primary;}
				}
			}
			
			
			&.alphabet{
				margin-right:-4px;
				a.filterbox, div.filterbox{
					margin-left:0;
					margin-right:0;
					-webkit-border-radius: 0px;
					-moz-border-radius: 0px;
					border-radius: 0px;
					
					span{padding-right:0}
					
					&.first{
						-webkit-border-bottom-left-radius: 4px;
						-moz-border-radius-bottomleft: 4px;
						border-bottom-left-radius: 4px;
					}
					
					&.last{
						-webkit-border-bottom-right-radius: 4px;
						-moz-border-radius-bottomright: 4px;
						border-bottom-right-radius: 4px;
					}
					
				}
			
			}
			
			
		}//li
	}//ul
}//filterBoxContainer



.whiteBox20_3, .brightBlueBox20_3, .primaryBlueBack20_3{
	background-color:$white;
	border-radius: 3px;
	-moz-border-radius:3px;
	-webkit-border-radius:3px;
	padding:20px;
}

.whiteBox20{
	background-color:$white;
	padding:0 20px;
}

.whiteBox{
	background-color:$white;
}

.whiteBox20_3_top{
	-webkit-border-top-left-radius: 3px;
	-webkit-border-top-right-radius: 3px;
	-moz-border-radius-topleft: 3px;
	-moz-border-radius-topright: 3px;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	background-color:$white;
	height:20px;
}

.whiteBox20_3_bottom{
	-webkit-border-bottom-left-radius: 3px;
	-webkit-border-bottom-right-radius: 3px;
	-moz-border-radius-bottomleft: 3px;
	-moz-border-radius-bottomright: 3px;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	background-color:$white;
	height:20px;
}

.brightBlueBox20_3{background-color:$brightBlueBack;}
.primaryBlueBack20_3{background-color:$brand-primary;color:$white}


.bigBubble{
	background-color:$brand-primary;
	border-radius: 75px;
	-moz-border-radius:75px;
	-webkit-border-radius:75px;
	width:150px;
	height:150px;
	text-align:center;
	color:$white;
	font-size:4rem;
	font-weight:bold;
	padding-top:25px;
}//bigBubble


.tooltipIcon{
	max-width:300px;
	font-size:1rem;
	.headline{text-align:center;font-weight:bold;color:$black;margin-bottom:10px;}	
	.text{color:$iconTooltipTextColor}
}

