.form .table.single .table-cell.second {width:265px;}

.icon.logo.logoSmall {display:none;}
@media (max-width: $tileBreakpoint) {
	.icon.logo.logoSmall {display:block;}
	.icon.logo.logoBig {display:none;}
}
#myAccountContainer{
	.whiteHoverBackground{right:-10px;}
}

#mobileMenu{
	.scrollable{
		 ul.languageEntryContainer{
		 	li{
			    color: #1a5dae;
			    background-color: #fff;
			    
			    a {
				    padding: 17px 20px 17px 50px;
				    color: #1a5dae;
				}		
			}
		}
	}
}			

#flagsSwitchContainer{
		position: relative;
		float: left;
		width: 68px;
		height: 70px;
		margin-top: 10px;
		font-size: .875rem;
		margin-left: 10px;
	
	a.numberCircle{
		position:absolute;
		right:0px;
		top:10px;
		color:white;
		text-decoration:none;
	}
	
	a{
		text-decoration:none;
	}
	a:hover{
		text-decoration:none;
		font-weight:bold;
	}
	
	.whiteHoverBackground{
		right:-106px;
 		padding: 20px 15px 10px 30px;		
	}
	
	@include media-breakpoint-down(xs) {
	  display: none;
	}	

	.menuWhislistContainer {
    	border-bottom: 2px solid #e9e9e9;
   		cursor: pointer; 	
	}
	
	.radioCheckContainer{
		margin: 10px 0;
	}
		
} //end flagsSwitchContainer

.icon.flagWhitebelgium{
				background-position:-255px -283px;
				width:64px;
				height:51px;
				float:left;
				display:block;
				cursor:pointer;
				@include media-breakpoint-down(xs) {
			      display: none;
			    }
}	

.icon.flagWhitecanada{
				background-position:-255px -333px;
				width:64px;
				height:51px;
				float:left;
				display:block;
				cursor:pointer;
				@include media-breakpoint-down(xs) {
			      display: none;
			    }				
}

.icon.flagbelgium{
				background-position:-255px -284px;
				width:64px;
				height:51px;
				display:block;
				cursor: pointer;
				position:absolute;
				top:3px;
				right:120px;
				display:none !important;
				@include media-breakpoint-down(xs) {
			      display: block !important;
			    }
}	
.icon.flagcanada{
				background-position:-255px -334px;
				width:64px;
				height:51px;
				display:block;
				cursor: pointer;
				position:absolute;
				top:3px;
				right:120px;
			    display:none !important;
				@include media-breakpoint-down(xs) {
			      display: block !important;
			    }
}
#menuHeader{	
	position: relative;
	z-index: 9000;
	top: 0;
 
    .whiteHoverBackground{
			right:-106px;
	 		padding: 10px 15px 10px 30px;	
	 		display: block !important;	
	}	
	
	.menuWhislistContainer {		
	    	border-bottom: 2px solid #e9e9e9;
	   		cursor: pointer;
	}	 	
} 

#displayLangMobile{
	position: relative;
	z-index: 9000;
	top: 0;
}
#blackOverlayLanguage{
	position:fixed;
	left:0;
	top:0;
	width:0;
	height:0;
	background-color:rgba(0,0,0,0);
	z-index:8900;
}

#blackOverlayLanguage.blackOverlayActive{
	width:inherit;
	height:100%;
	background-color:rgba(0,0,0,0.5);
} 	
#wrapper #toTop {
	background-color: $orange2;
}
.no-touchevents #wrapper #toTop:hover {background-color: $btn-orange-bg-hover;}

#search_suggest_big #editorialContainer {padding-bottom: 0px;}
#search_suggest_big #editorialContainer a {padding: 5px 5px 0px 0px; }

#mobileMenu .scrollable ul li ul.secondMenuEntryContainer li a, #mobileMenu .scrollable ul li ul.secondMenuEntryContainer li a:hover,#mobileMenu .scrollable ul li ul.secondMenuEntryContainer li {color:$menuEntryLink;}
#mobileMenu .scrollable ul li ul.secondMenuEntryContainer li ul.thirdMenuEntryContainer li a, #mobileMenu .scrollable ul li ul.secondMenuEntryContainer li ul.thirdMenuEntryContainer li a:hover {color:$menuEntryLink;}

@import "rvmicro";