.modal {
  z-index: 8992;
}
.modal-backdrop.in {
  z-index: 8991;
}
.modal-title {
  font-weight: bold;
  font-size: 1.5rem;
  color: #333;
  width: 95%;
}
.modal-body {
  font-size: 0.75rem;
  color: #888;
  max-height: 500px;
  overflow: auto;
  @media(max-height: 500px) {
    max-height: 300px;
  }
  @media(max-height: 600px) {
    max-height: 400px;
  }
}

.modal-footer {
  font-size: 0.875rem;
}

.modal-cookie-consent {
  .modal-header {
    border-bottom: 0;
  }
  .modal-footer {
    border-top: 0;
    .grey-text-link {
      color: #888;
      text-decoration: underline;
      padding-top: 8.5px;
    }
    .btn {
      font-weight: 600;
      font-size: 0.875rem;
      padding: 10px 30px;
      border-radius: 10px;
    }
  }
}