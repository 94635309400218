.form{
	.whiteBox>div{padding:0 20px;}
		
	.inputContainer{margin-top:15px;}
	.inputContainerWithoutHeadline{margin-top:38px;}
		
	max-width:1045px;
	margin:0 auto;
	font-size:0.875rem;
	color:#656565;
	
	h1, h2, h3{
		font-weight:bold;
		font-size:1.625rem;
		@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:1rem}
	}
	
	h2{font-size:1.25rem}
	h3{font-size:1rem}

	@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {width:340px;}
	
	.table{
		display:table;
		margin-bottom:0;
		.table-row{
			display:table-row;
			
			.table-cell{
				display:table-cell;
				vertical-align:top;
			}
			
			.table-cell.first, .table-cell.third{width:515px}
			.table-cell.second{width:15px;}
		}
		
		&.outerTableContainer{margin-bottom:20px;}
		
		&.single{
			.table-cell.first, .table-cell.third{width:475px}
			.table-cell.second{width:55px;}
		}
	}
		
	@media (max-width: $doubleTeaserBreakpoint) {
		.table.outerTableContainer{
			display:block;
			.table-row{
				display:block;
				.table-cell{
					display:block;
					width:100%;
					&.second, &.third{display:none}
				} 
			}
		}
		
		.table.single{
			display:block;
			.table-row{
				display:block;
				.table-cell{
					display:block;
					width:100%;
					&.second{display:none}
					&.additionalPadding{padding-top:40px;}
				} 
			}
		}
	}
	
	.recipient{
		width:475px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {width:300px}
	}
	
	.firstname{
		float:left;
		width:230px;
		margin-right:15px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			margin-right:0;
			width:100%
		}
	}
	
	.lastname{
		float:right;
		width:230px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:100%
		}
	}
	
	.street{
		float:left;
		width:330px;
		margin-right:15px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			margin-right:0;
			width:100%
		}
	}
	
	.hnr{
		float:right;
		width:130px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:100%
		}
	}
	
	.plz{
		float:left;
		width:100px;
		margin-right:15px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			margin-right:0;
			width:100%
		}
	}
	
	.city{
		float:right;
		width:360px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:100%
		}
	}
	
	.geb{
		float:left;
		width:100px;
		margin-right:15px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:60px;
		}
	}
}



.customerAccount{
	max-width:1045px;
	margin:0 auto;
	font-size:0.875rem;
	color:$font-color;
	
	h1{
		font-weight:bold;
		font-size:1.625rem;
		@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:1rem}
	}

	@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {width:340px;}
	
	
	.table{
		display:table;
		margin-bottom:0;
		.table-row{
			display:table-row;
			
			.table-cell{
				display:table-cell;
			}
		}
	}
	
//-----------------------------------------------------------
	
//common

	.createAccount, .addresses, .wishlist{
		.inputContainer{margin-top:15px;}
		.inputContainerWithoutHeadline{margin-top:38px;}
	}
	
	
//-----------------------------------------------------------	

	.createAccount{
		.whiteBox>div{padding:0 20px;}
		
		
		.table{
			.table-row{
				.table-cell.first, .table-cell.third{width:515px}
				.table-cell.second{width:15px;}
			}
			
			&.outerTableContainer{margin-bottom:20px;}
		}
		
		@media (max-width: $doubleTeaserBreakpoint) {
			.table.outerTableContainer{
				display:block;
				.table-row{
					display:block;
					.table-cell{
						display:block;
						width:100%;
						&.second, &.third{display:none}
					} 
				}
			}
		}
		
		
		
		.firstname{
			float:left;
			width:230px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.lastname{
			float:right;
			width:230px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:100%
			}
		}
		
		.street{
			float:left;
			width:330px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.hnr{
			float:right;
			width:130px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:100%
			}
		}
		
		.plz{
			float:left;
			width:100px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.city{
			float:right;
			width:360px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:100%
			}
		}
		
		.geb{
			float:left;
			width:100px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:60px;
			}
		}
	}//createAccount
	
	
//-----------------------------------------------------------


	.addresses{
		.firstname{
			float:left;
			width:495px;
			margin-right:15px;
			@media (max-width: $doubleTeaserBreakpoint) {
				width:230px;
			}
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.lastname{
			float:right;
			width:495px;
			@media (max-width: $doubleTeaserBreakpoint) {
				width:230px;
			}
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:100%
			}
		}
		
		.street{
			float:left;
			width:330px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.hnr{
			float:right;
			width:130px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:100%
			}
		}
		
		.plz{
			float:left;
			width:100px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.city{
			float:right;
			width:890px;
			@media (max-width: $doubleTeaserBreakpoint) {
				width:360px;
			}
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:100%;
				margin-top:10px;
			}
		}
		
		.geb{
			float:left;
			width:100px;
			margin-right:15px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:60px;
			}
		}
	}//addresses
	
//-----------------------------------------------------------	
	
	.orderStatus{
		.bigView{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
			
			.table{
				margin-bottom:0;
				
				&.headline, &.entry{
					
					.table-cell{
						width:20%;
						text-align:center;
						&.order{text-align:left;}
					}
				}
				
				&.entry{
					cursor:pointer;
					
					.fa-chevron-down{display:none}
					
					.table-cell{font-size:0.75rem;}	
				}
				
				&.detail{
					display:none;
					.article{width:40%;}
					.price,.number,.priceAll{width:20%;text-align:center;font-size:0.75rem;vertical-align:middle}
					
					img{width:115px;height:auto}
				}
				
				&.detailOpen{display:table}
			}//table
			
			hr.detail{display:none}
			
			
			
		}//bigView
		
		.smallView{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:0.6875rem}
			
			.table.entry{
				cursor:pointer;
				.table-cell{
					&.dates{width:80%}
					&.arrow{width:20%;text-align:right}
				}
				.fa-chevron-down{display:none}
			}
			
			.detail{
				display:none;
				img{width:67px;height:auto}
				margin-top:10px;
			}
			
		}//smallView
		
		hr{
			margin-top:0.4rem;
			margin-bottom:0.4rem;
		}
	}//orderStatus
	
//-----------------------------------------------------------

	
	.giftvoucher{
	
		.bigView{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
			
			.table{
				margin-bottom:0;
				
				&.headline, &.entry{
					width:100%;
					table-layout:fixed;
					.table-cell{
						&.iconContainer{width:20px;}
						&.number, &.boughtDate, &.sum, &.status{
							width:calc(25% - 5px);
							text-align:center;
						}
					}
				}
			}
			
			h3 {
				font-size: 1rem;
    			font-weight: 700;
    			margin: 25px 0 10px;
			}
			
		}//bigView
		
		.smallView{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			
			.table.entry{
				width:100%;
				cursor:pointer;
				.table-cell{
					&.iconContainer{width:20px;}
					&.dates{width:calc(100% - 40px);}
					&.arrow{width:20px;text-align:right}
				}
				.fa-chevron-down{display:none}
			}
			
			.detail{
				display:none;
				margin-left:20px;
			}
			
			h3 {
				font-size: 1rem;
    			font-weight: 700;
    			margin: 25px 0 10px;
			}
			
		}//smallView
		
		
	}//giftVoucher
	
	
//-----------------------------------------------------------
	.puzzlePoints{
		.limitButtons{
			display:inline;
			margin-right:10px;
			@media (max-width: $doubleTeaserBreakpoint) {display:block;margin-bottom:5px;margin-right:0;}
		}
		
		.ticket{
			float:left;
			width:70px;
			margin-top:27px;
		}
		
		.ticketDescription{
			float:left;
			width:calc(100% - 70px);
		}
	}//puzzlePoints

//-----------------------------------------------------------


	.reviews{
	
		.bigView{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
			
			.table{
				margin-bottom:0;
				
				&.headline, &.entry{
					width:100%;
					table-layout:fixed;
					.table-cell{
						&.iconContainer{width:20px;cursor:pointer;}
						&.deleteButton{width:60px;}
						&.productName, &.date, &.rating{
							width:calc((100% - 80px) / 3);
							text-align:center;
							cursor:pointer;
						}
						&.productName{text-align:left}
					}
				}
			}
			
			.detail, .delete{
				display:none;
				margin-left:40px;
			}
			
			.fa-chevron-down{display:none}
			
		}//bigView
		
		.smallView{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			
			.table.entry{
				width:100%;
				table-layout:fixed;
				cursor:pointer;
				.table-cell{
					&.dates{width:calc(100%-20px);}
					&.arrow{width:20px;text-align:right}
				}
				.fa-chevron-down{display:none}
			}
			
			.detail, .delete{
				display:none;
				margin-top:10px;
			}
			
			
		}//smallView
		
		
		.pagination{margin:0}
		
	}//reviews
	
	
//-----------------------------------------------------------

	.whislist{
		.bigView{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
			
			.table{
				margin-bottom:0;
				
				&.headline, &.entry{
					width:100%;
					table-layout:fixed;
					.table-cell{
						&.image{width:125px;margin-right:10px;vertical-align:top}
						&.article{text-align:left;width:calc(50% - 125px);vertical-align:top}
						&.date{text-align:center;width:25%;vertical-align:bottom;}
						&.buttons{width:25%;text-align:right;vertical-align:bottom;}
					}
				}
			}
			
		}//bigView
		
		.smallView{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			
			.table.entry{
				width:100%;
				table-layout:fixed;
				cursor:pointer;
				.table-cell{
					&.image{width:125px;margin-right:10px;vertical-align:top}
					&.article{width:175px;vertical-align:top}
				}
			}
			
		}//smallView
	}//whislist
	
	.deleteCustomer{
		.deleteInputContainer{
			float:left;
			width:500px;
			margin-right:15px;
			@media (max-width: $doubleTeaserBreakpoint) {
				width:100%;
				margin-right:0;
			}
		}
		
		.deleteButtonContainer{
			float:left;
			width:270px;
			margin-top:30px;
			@media (max-width: $doubleTeaserBreakpoint) {
				width:100%;
				margin-top:20px;	
			}
		}
	}//deleteCustomer
	
	.newsletterAdministration{
		.buttonContainer{
			width:460px;
			@media (max-width: $doubleTeaserBreakpoint) {width:auto;}
		}
		
		.smallTextContainer{
			width:500px;
			@media (max-width: $doubleTeaserBreakpoint) {width:auto;}
		}
	}//newsletterAdministration

}//customerAccount

//-------------------------------------------------------------------------------
//CustomerReviewPage

.reviewFormContainer {
	.whiteBox20_3 {
		margin-bottom: 20px;
		overflow: hidden;
	}

	.orderReviewBox {
		.orderQuestions {
			float:left;	
			line-height: 2.2rem;
			font-weight: 650;
			
			.ratingBlockLabel {
				display:inline-block;
			}
		}
		.ratingBlock {
			display: inline-block;
			margin-left: 20px;
			
			.orderRatingContainer{
				margin-top: 4px;
				
				.iconStar {
					margin-right: 5px;
					width: 25px;
				}
			}
			.deliveryRatingContainer{
				margin-top: 4px;
				
				.iconStar {
					margin-right: 5px;
					width: 25px;
				}
			}
			.productsRatingContainer{
				margin-top: 4px;
				
				.iconStar {
					margin-right: 5px;
					width: 25px;
				}
			}
		}
		.inputContainer {
			clear: left;

			#orderText {
				max-width: 400px;
				display: block;
			}
		}
	}/*orderReviewBox*/

	.productReviewBox {	
		.productImage {
			float: left;
			max-width: 150px;
			clear: left; /*preventing position change due to jquery slideDown jump fix*/
		}
		.formContainer {
			margin: 20px 20px 20px 0px;
			display: block;
			margin-left: 150px;
			max-width: 650px;
		
			.h5 {
				color: #1a5dae;
				
				@media (max-width: $doubleTeaserSecondBreakpoint){
					display: table;
					padding: 5px 0px 0px 12px;
				}
			}
			
			.charCountText {
				color: grey;
				float: right;
				font-weight: 250;
				font-size: 0.8rem;
			}
			#textTooLong {
				color:#E4300F;
				display:none;
			}

			.pflichtfelder {
				font-weight: 250;
				margin-top: 10px;
				visibility: visible;
				color: grey;
			}

			.reviewTextArea {
				float: left; /*preventing jquery slideDown jump*/
				max-width: 650px;		
			}
			@media (max-width: $doubleTeaserBreakpoint) {
				margin-left: 115px;
			}
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin: 20px 0px 20px 0px;
			}
		}/*formContainer*/
		
		#userNameArea {
			float: left; /*preventing jquery slideDown jump*/
			margin-left: 150px;
			max-width: 650px;
			width: 100%;
			
			.submit {
				float: right;
				
				#submitBtn {
					margin: 0 auto;
					display: block;
				}
				
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width: 100%;
					margin-top: 20px;	
				}
			}
			
			.btn-primary {
				transition: 0.5s;
				
				&:disabled {
					background-color:#d3d7cf;
					border-color:#d3d7cf;
				}
			}
			
			@media (max-width: $doubleTeaserBreakpoint) {
				margin-left: 115px;
				margin-right: 20px;
				width: auto;
			}
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin: 0px;
			}
		}/*userNameArea*/
		
		.textArea {
			display:none;
		}
		.productRatingContainer {
			margin: 20px 10px 20px 0px;
		
			.iconStar { 
				width: 50px;
				margin-right: 12px;

			@media (max-width: $doubleTeaserBreakpoint) {
				width: 48px;
				margin-right: 5px;
			}
			}
					
			@media (max-width: $doubleTeaserSecondBreakpoint) {
			    margin-top: 10px;
			    text-align: center;
			    width: 100%;
			    display: inline-block;
			}		
		}
		.lightviewLink {
			float: right;
			cursor: pointer; 
			text-decoration: underline;
			color: #1a5dae;
		}
	}/*productReviewBox*/	
	
	.iconContainer {
		display: inline-block;
	
		/*Viewbox attribute needs to be set for scaling SVG images by width property*/
		.iconStar { 
			overflow: visible;
			display: block;
			cursor: pointer; 
			
			& path {
				fill: none;
				stroke: #eee;
				stroke-width: 2;
			}
			.hover {
				fill: #ffb04d;
				stroke: #ffb04d;
			}
			.selected {
				fill: #ff8e00;
				stroke: #ff8e00;
			}
		}/*iconStar*/
	}/*iconContainer*/
	.asterix {
		visibility: hidden;
		color: grey;
	}
	.pflichtfelder, .asterix {
		@-webkit-keyframes red-fade {   
		   0% {color: #a40000;}
		   100% {color: #5a5a5a;}
		}
		
		@keyframes red-fade {
		   0% {color: #a40000;}
		   100% {color: #5a5a5a;}
		}
		
		&.highlight {	
		   	-webkit-animation: red-fade 2s 1;
		   	animation: red-fade 2s 1;
		}
	}
}/*reviewFormContainer*/

	.reviewSuccessBox {
	span {
		display: block;
	}
	.successHeading {
		font-size: 2rem;
		font-weight: 700;
		color: #2db92d;
		line-height: 2rem;
	}
	.btn-primary {
		margin-top: 20px;
		
	}
	.dankePNG {
		text-align: center;
		margin-top: 20px;
	}
}
