.editorial{
	.hiddenAnswer{
		display: none;
	}
	#carousel-wrapper {
		width: 100%;
		height: fit-content;

		font-family: 'Open Sans', sans-serif;
		@media (max-width: 1270px) {

		}
		@media (max-width: $tileBreakpoint) {

		}
		
		.headline, .subheadline {
			color: #656565;
		}
		
		.headline{
			margin-top: 10px;
			font-weight: bolder;
			font-size: 2.0rem;
			
			@media (max-width: 1360px) {
				font-size: 1.5rem;
			}
			@media (max-width: 120px) {
				font-size: 1.2rem;
			}
		}
		.subheadline{
			margin-top: 5px;
			font-size: 1.425em;
			margin-bottom: 10px;
			font-weight: normal;
			
			@media (max-width: 820px) {
				font-size: 1.25rem;
			}
		}
		
		.anchor {
		    justify-content: center;
		
			.anchorElementContainer {
				max-width: 261.25px;
				
				.anchorElement {
					font-size: 0.68em;
					align-items: center;
					text-align: left;
				}
				:hover {
 				 	background-color: #009fe3;
		 			cursor: default;
		 			text-decoration: none;
	 		}
			}
		}
							
		#carousel-slider {
		    left: 0;
		
			.carousel-inner {
				/*position: relative;*/
    			width: 100%;
    			height: 0;
    			padding-bottom: 35.79%;
				
			    @media (max-width: 1555px) {
					height: 550px;
			   		padding-bottom: unset;
			   	}
				@media (max-width: $doubleTeaserBreakpoint) {
				    height: 0;
					padding-bottom: unset;
					overflow: inherit;
				}
			
				@media (max-width: $doubleTeaserSecondBreakpoint) {					
					overflow: inherit;
				}

				.carousel-item  {
				  	background-size: cover;
					background-position: center bottom;
				  	background-repeat: no-repeat;				  	
				
				@media (min-width: $doubleTeaserBreakpoint) {
			 	    position: absolute;
				    top: 0;
				    bottom: 0;
				    left: 0;
				    right: 0;
				}    
				    @media (max-width: $doubleTeaserBreakpoint) {
						background-position: center top;	
						background-size:100%;
				    }
				    
					.carousel-slide {
						@media (min-width: $doubleTeaserBreakpoint) {
							    	width: 100%;
							    	height: 100%;
							    	
						}
					
						.caption-wrapper {
						    position: absolute;
					    	top: 120px;
						    left: 160px;
						    width: 600px;
						    height: 120px;
						    
						    @media (max-width: $doubleTeaserBreakpoint) {
						    	top:0px;
						    	position:relative;
						    	left: 8%;
						    	width: fit-content;
						    }
						    .content-big-button{
						    	@media (max-width: $doubleTeaserBreakpoint ) {
									display: none;
								}
						    }
							.caption {
								width: 100%;
								color: $black;
								text-align: left;
							    border-radius: 8px;
							    background-color: rgba(255,255,255,1);

								@media (max-width: 1360px) {
									width: 80%;
								}
								
								@media (max-width: $doubleTeaserBreakpoint ) {
									display: none;
								}

								>div {
									margin-left: 25px;
								    height: 100%;
								    display: flex;
								    align-items: center;
								    justify-content: center;
								    flex-flow: column;
								
									>* {
										align-self: flex-start;
									}
								}
							}/* end caption*/
	
							.campaignPrice-wrapper {
								position: relative;
    							left: 55px;
								display:inline-block;
								width:auto;
								min-width: 250px;
								
								@media (max-width: 1360px) {
									min-width: 200px;
								}
								@media (max-width: $doubleTeaserBreakpoint ) {
									left: 0px;
									top: 0px;
								}
								
								.campaignPrice-container {
									position: relative;
									height: 110px;
									line-height: 110px;
									padding: 0px 25px;
									background: $orange2;
									color: white;
									text-align: center;
									font-weight: bold;
									
									@media (max-width:  $doubleTeaserSecondBreakpoint) {
									    height: 65px;
									    line-height: 65px;
									}
												
									>span {
										font-size: 1.5em;
										
										@media(max-width: $tileBreakpoint) {
											font-size: 1.25em;
										}
									}
									.price {
										font-size: 2.875em;
										display: inline-block;
										font-weight: bold;	
										margin-left:6px;									
										
										@media(max-width: $bigPagerBreakpoint) { /*1360px*/
											font-size: 2.250em;
										}
									}
								}
								.frond {
									height: 20px;
									background-color:$orange2;
									clip-path: polygon(100% 0, 100% 100%, 50% 0, 0 100%, 0 0);
									
									@media(max-width:1360px) {
										height: 15px;
									}
								}
								
							}/* end campaignPrice-wrapper */
							>a {
								position: relative;
								top: 50px;
							    font-size: 24px;
							    padding: 1rem 6rem;
							    border-radius: 8px;
								
								@media (max-width: 1530px) {
								    font-size: 20px;
								    padding: 1rem 5rem;
								    border-radius: 8px;
								}
								@media (max-width: $doubleTeaserBreakpoint) {
								    display: none;
								}
							}
						} /* end captionWrapper */

						.configuration-wrapper {
							position: absolute;
							width: 350px;
							right:10%;
							
							@media (max-width: $doubleTeaserBreakpoint) {
								position:relative;
								width:515px;
								margin-top: 13px;
								margin:0 auto;
								left:0;
								right:0;
								float:none;
								top:0px;
							}
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								margin-top: 13px;
								margin:0 auto;
								width:100%;
								max-width:340px;		
							}
							
							.configuration-listing {
								height: auto;
								border-radius: 8px 8px 0px 0px;
								background-color: $white;
								
								@media (max-width: $doubleTeaserBreakpoint) {
									width: 100%;
									left: 0px;
									height:auto;
								}

								.listEntry {
									display: table;
									width: 100%;
									padding:6px 13px 6px 13px;
									line-height:1.3;
									font-size: $font-size-sm;
									font-weight: 700;
									color: $triangle-blue;
									
									input[type="radio"]:checked+label span:first-of-type { font-weight: bolder; }
									
									.radioCheckContainer {
										display: table-row;
									}
													
									label {
										display:table-cell;	
										width: 100%;								
										background-position: left center; 
										padding-bottom: 2px;
									}
									
									&:nth-of-type(odd) {
										background: rgba(0, 159, 227, .05);
										
										&:nth-of-type(even) {
											background-color: white;
										}
										
										&:nth-of-type(1) {
											border-radius: 8px 8px 0px 0px;
																						
											@media (max-width: $doubleTeaserBreakpoint) {								
												border-radius: 5px 5px 0px 0px;												
											}									
										}
									}
									
									.textWithOldPrice {
										display:table-cell;
										width: 100%;			
										padding-right: 20px;						    	
										vertical-align:middle;
										margin-bottom: auto;	
										
										@media (max-width: $doubleTeaserBreakpoint) {											
											text-align:left;
										}	
										
										@media (max-width: $doubleTeaserSecondBreakpoint) {
											margin:0 auto;											
											text-align:inherit;
										}
									}
									
									.textWithoutOldPrice {
										display:table-cell;
										width: 100%;				
										padding-right: 20px;					
										vertical-align:middle;
										margin-bottom: auto;		
																	
										@media (max-width: $doubleTeaserBreakpoint) {
											text-align:left;
										}
									}
				 			
									#otherSelectionText {
										text-decoration-line: underline;
										
										
										@media (max-width: $doubleTeaserBreakpoint) {								
											margin-left: -8px;													
										}
									}
									
									&#otherSelection {
										@media (max-width: $doubleTeaserBreakpoint) {								
												display:none;												
										}
									}
									&#smallOtherSelection {
										display: none;
										cursor:pointer;
										
										.filterHeadline{
											width:100%;
										}
										#otherSelectionTextSmall {
											text-decoration-line: underline;
											vertical-align: text-top;	
  											padding-left: 6px;									
										}
										
										#lessSelection {
											display:none;
											.closer {
												height: 15px;
												width: 20px;												
												vertical-align: middle;
											}
											#lessSelectionText {											
												text-decoration-line: underline;	
												padding-left: 5px;									
											}
										}
										@media (max-width: $doubleTeaserBreakpoint) {								
											display:block;	
											
											input[type=radio]{
												float: left;
												width: 20px;												
												margin-left: -20px;									
												opacity: 0;
											}												
											label {cursor:pointer; margin-left: 2px;}

											input[type = radio] + label:before {												
  												content:url(/images/select_1.png);
  												display: table-cell;
  												padding-left: 2px;
  												padding-right: 5px;
											}								
										}										
									}
																		
									.listPrice {
										display:table-cell;
										width:auto;
										color: $orange4;	
										vertical-align: middle;
										white-space: nowrap;															
									}
									.oldPrice {									
										display:table-cell;
										width:auto;
										color: $dark-grey;
										text-decoration-line: line-through;	
										padding-right: 5px;			
										vertical-align: middle;
										white-space:nowrap;					
									}
									
									&#listCase {
										display:flex;
										text-align:center;
										padding: 17px 13px;
										
										.radioCheckContainer{
											display: block;
											padding-left: 18px;
											cursor: pointer;
											
											@media (max-width: $doubleTeaserBreakpoint) {
												padding-left: 65px;
											}
											
											@media (max-width: $doubleTeaserSecondBreakpoint) {
												padding-left: 13px;
											}
											
										}
										label{
											cursor: pointer;
											display:inline; 
										}			
									}
										
									&#listAction {
										text-align:right;
									
										.actualPrice {	
											font-size: $font-size-h2;
											font-weight: bolder;
											color: $orange4;			
										}										
										#tax {
											color: $dark-grey;										
										}								
										a {	
											width: 100%;									
											margin-top: 10px;
											margin-bottom: 6px;
											//background: $orange2;
										}																							
									}								
								}/* end of listEntry */
								
								.additionalConfiguration-listing {
								
									display:none;
									position: absolute;
									right: 351px;
									width: 350px;
									background: $white;
									border-radius: 8px 0px 8px 8px;
									
									@media (max-width: $doubleTeaserBreakpoint) {
											position:relative;
											width:auto;
											right:0;											
									}								
									.listEntry {
									
										
										.closer{
											cursor:pointer;
											height:	12px;		
											margin: 6px 0px;
											width: 12px;														
										}
										&:first-child {										
											width:auto;
											position:absolute;
											top:-34px;
											right:0px;
											padding-top: 5px;
											padding-bottom: 5px;
											border-radius: 8px 0px 0px 0px;
											@media (max-width: $doubleTeaserBreakpoint) {
												display:none;
											}	
										}
										&:nth-of-type(2) {
											border-radius: 8px 0px 0px 0px;
											@media (max-width: $doubleTeaserBreakpoint) {
												border-radius: 0px;
											}
										}
										&:nth-of-type(odd) {
											background-color: $white;
											@media (max-width: $doubleTeaserBreakpoint) {
												background: rgba(0, 159, 227, .05);
											}
										}
										&:nth-of-type(even) {
											background: rgba(0, 159, 227, .05);
											@media (max-width: $doubleTeaserBreakpoint) {
												background-color: $white;
											}											
										}
										&:last-child {
											border-radius: 0px 0px 8px 8px;
											@media (max-width: $doubleTeaserBreakpoint) {
												border-radius: 0px;
											}
										}	
									}
								}/*end additionalConfiguration-listing */
							
							} /* end configuration-listing */

							.estDelivery {
								font-weight: bold;
								font-size: .75rem;
								padding:4px 13px 0px 13px;
								line-height:1.2;
								text-align: center;
								color: $white;
								height: 45px;
								border-radius: 0px 0px 10px 10px;
								background-color: $topMenu-color;
									@media (max-width: $doubleTeaserBreakpoint) {
									border-radius: 0px 0px 5px 5px;									
								}								
								#delText { 
									display:inline-grid;
									vertical-align: middle;
									text-align: left;
									width: 183px;
									margin-top:3px;
									
									@media (max-width: $doubleTeaserBreakpoint) {
										display:inline-block;
										width: auto;
									}
									@media (max-width: $doubleTeaserSecondBreakpoint) {
										width:183px;		
										display:inline-grid;					
									}									
								}								
								img{
									margin-right:10px;
									margin-top:5px;
								}
							}/* end estDelivery */
							
						} /* end configuration-wrapper*/
						
						.image-margin {						
							display:none;
							@media (max-width:$doubleTeaserBreakpoint ) {
								display: block;
							}
						}
						#carousel-content-big {
							display: block;
							@media (max-width:$doubleTeaserBreakpoint ) {
									display: none;
							}
						}
						#carousel-content-small {
							display: none;
							@media (max-width:$doubleTeaserBreakpoint ) {
									display: block;
							}
							.carousel-content-container {
								display: none;
								height: auto;
								margin: 0 5% 0 5%;
								background: unset;
								padding: 0px 30px;
				
								@media (max-width: $tileBreakpoint) {
									margin: 0;
								}
				
								@media (max-width:$doubleTeaserBreakpoint ) {
									display: block;
									position:relative;
									/*top:300px;*/
									width: 515px;
									margin:auto;
									text-align:center;
									padding-top:13px;
								}
								@media (max-width:$doubleTeaserSecondBreakpoint ) {
									/*top: 160px;*/
									width: 340px;
								}
				
								&.containerWithoutBox{
									padding-bottom:1px;
								}
								.caption {
								}
								
								>button {
									margin: 15px;
									display: block;
									font-size: 18px;
								    padding: 0.75rem 3rem;
								    border-radius: 8px;
								}
							}
						} /* end carousel-content-small */
					} /* end carousel-slide*/
				} /* end carousel-item*/
			} /* end carousel-inner*/
			
			
			.carousel-indicators {
				z-index:0;
				position:absolute;
				height: 22px;
				bottom:0;
				>li {
					width: 20px;
					height: 20px;
					margin: 2px;
					border: 3px solid #fff;
					.active {
						width: 20px;
						height: 20px;
					}			
				}
				@media (max-width: $doubleTeaserBreakpoint) {
					top:0;
					margin-left:auto;
					width: 100%;
					left: auto;
					margin-right: auto;
				}
			}
			
			.carousel-control {
				width: 10%;
			
				&.right, &.left {
					background-image: unset;
				}
			}
			
			.left, .right {
				>i {
					position: relative;
					top: 50%;
					transform: perspective(1px) translateY(-50%);
					font-size: 5rem;
					@media (max-width: $doubleTeaserBreakpoint) {
						position:static;
						margin-top: 320%;									
					}
					
				}
			}
		}/* end carousel-slider*/
	} /* end carousel-wrapper*/
	
	.anchor {
	 	width: 200%;
	 	height: 60px;
	 	background-color: #009fe3;
	 	display: flex;
		position: relative;
	 	left: -50%;
		 
 		@media (max-width: $doubleTeaserBreakpoint) {
			display: none;
 		}

		 .anchorElementContainer {
		 	display: flex;
		 	flex-flow: row wrap;
		 	justify-content: center;
		 	width: 100%;
		 					 	
	 		@media (max-width: 820px) {
	 			justify-content: space-around;
	 		}

		 	.anchorElement {
		 		display: flex;
		 		padding: 20px 20px 20px 20px;
		 		min-width: 150px;
		 		height: 100%;
		 		justify-content: center;
			 	font-weight: bold;
			 	font-size: 0.75em;
	 			text-align: center;
				color: $white;
		 		text-decoration: none;
		 	
		 		@media (max-width: 1045px) {
		 			font-weight: 650;
		 			font-size: 0.7em;
		 			padding: 20px 10px 20px 10px;
		 			min-width: 120px;
		 		}
		 		@media (max-width: 820px) {
		 			flex: 1;
		 			min-width: unset;
		 		}
		 	}
	 		:hover {
	 			background-color: $brand-primary;
	 			cursor: pointer;
	 			text-decoration: none;
	 		}
		} /* end anchorElementContainer */
	} /* end anchor */
	
	
	.fiximages_entry{
		width:250px;
		height: 250px;
		float:left;
		margin: 0px 15px 15px 0px;
		box-sadow: 0px 0px 4px 1px #b0b2be;
		background:white;
				
		&:last-of-type{margin-right:0px;}
				
		@media (max-width: $doubleTeaserBreakpoint) {	
			&:nth-of-type(2){margin-right:0px;}
		}
		@media (max-width: $doubleTeaserSecondBreakpoint) {					
				width: 165px;
				height: 165px;
		}	
		.btn{
			width:100%;
		}
		img {
			width:100%
		}
		.fiximages_box{
			padding:16px 10px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				padding:6px 8px;
			}
		}
		.fiximages_btn{
			padding-top:12px;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				padding-top:10px;
			}
		}
		.fiximages_image{
			height: 169px;	
			@media (max-width: $doubleTeaserSecondBreakpoint) {		
				height: 105px;
			}
		}		
	}

	.containerImage{
		overflow:hidden;
		color:$brand-primary;
		position:relative;
		background-size:cover;	
		-webkit-background-size:cover;
		-moz-background-size:cover;
		background-position:center center;
		
	}
	
	.carousel-containerImage {
		overflow:hidden;
		color:$brand-primary;
		background-size:cover;	
		-webkit-background-size:cover;
		-moz-background-size:cover;
		background-position:center center;
	}
	
	.downArrow{
		position:absolute;
		left:10px;	
		//color:$white;
		cursor:pointer;
		display:none;
		bottom:10px;
		
		@include media-breakpoint-down(xs) {display:none !important}
		
	}
	.downArrow:hover{color:$black !important;}
	
	.content{
		max-width:1045px;
		margin:0 auto;
		
		
		//text-shadow:1px 1px #000;	
		//font-size:20px;
		
		.innerTableContainer{
		
		
			padding:95px 50px; //when change 95px also change it in rv_header.js open2ndmenu
			&.first {padding-top: 135px}	
			
			@media (max-width: $doubleTeaserBreakpoint) {
				padding:95px 10px;
			}
			@include media-breakpoint-down(xs) {
				padding:90px 0 40px 0 !important;
				&.first {padding-top:110px !important}	
			}
			@media (min-width: 1145px) {
				padding-left:0;
				padding-right:0;
			}
			
			display:table;
			height:100%;
			width:100%;
			
			@media (max-width: $doubleTeaserBreakpoint) {
				width:515px;
				margin:0 auto;
			}
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				margin:0 auto;
				width:340px;
			}
			
			.innerCell{
				display:table-cell;
				vertical-align: middle;
				overflow: visible;
				 
				 .iconBarContainer {
					display:block;
					max-width: 1045px;
					margin-bottom: 20px;
					border-radius: 5px;
					background-color: $brand-primary;
					list-style-type: none;
					
					.iconBarInnerContainer {
						padding: 15px;
						display: flex;
						flex-flow: row wrap;
						justify-content: space-around;
						
						.iconBarItem:nth-child(1), .iconBarItem:nth-child(2) {
							@media (max-width: $doubleTeaserBreakpoint) {
								margin-bottom: 15px;
							}
						}
						.iconBarItem:nth-last-child(1) {
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								margin-bottom: unset;
							}
						}
						
						.iconBarItem {
							display: flex;
							width: 201px;
							
							@media (max-width: $doubleTeaserBreakpoint) {
								flex: 1 50%;
								justify-content: center;
							}
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								margin-bottom: 15px;
							}
															
							>.elementIcon {
								display: inline-block;
								float: left;
								min-width: 50px;
								min-height: 50px;
								background-size: contain;
								background-color: white;
								margin-right: 10px;
								align-self: center;
							}
							
							.textBox {
								margin-left: 10px;
								color: white;
								display: inline-block;
								text-align: left;
								align-self: center;
								min-width: 130px;
								flex-grow: inherit; 
								
								@media (max-width: $doubleTeaserBreakpoint) {
									min-width: 150px;
								}
																
								>div {
   							 		line-height: 0.875em;
								}
								
								span {
									display: block;
									font-size: .75em;

									>div:nth-child(2) {
										margin-top: 5px;
									}
								}
												
								.headline {
									font-weight: bold;
								}
							}
						}/*end iconBarItem*/
					}/*end iconBarInnerContainer*/
				}/*end iconBarContainer*/
				 
				 h2.textAlignCenter, h2.textAlignLeft, h2.textAlignRight{ 
    				font-size: 1.3rem;
  				    font-weight: 700;
    				line-height: 1.6rem;
				 }

				.singleText, .doubleTeaser{
					.editorialBigHeadline{
						line-height:1.5;
					}
					h1, .editorialBigHeadline{
						font-size:2.25rem;
						font-weight:bold;
						//line-height: 1.3rem;
					}
					h2, .headline{
						//font-size:1.125rem;
						font-weight: 700;
						font-size: 1.3rem;
						line-height: 1.6rem;	
					}
					h1,h2, .editorialBigHeadline{margin:0;padding:0;}
					font-size:1rem;
					
					
					a{
                        &:hover{text-decoration:none !important;}
                    }
					
					@media (max-width: $tileBreakpoint) {
						h1, .editorialBigHeadline{font-size:1.875rem;}
						
					}
					
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						.uploadedImage{max-width:50%;max-height:50%}
						.text{word-break: break-word;}
					}
					
					@media (max-width: $doubleTeaserBreakpoint) {
						.teaserDoubleEmptyContainer{display:none !important}
					}
					
					
					.imageContainer, .text, h1, h2, .editorialBigHeadline{margin-bottom:20px;}
					
					.youtubeContainer{
						position:relative;
						cursor:pointer;
						
						.youtubeTextContainer{
							border-radius: 5px;
							-webkit-border-radius: 5px;
							-moz-border-radius: 5px;
							bottom: 20px;
							left: 20px;
							padding: 15px;
							position: absolute;
							right: 20px;
							text-align: center;
							background-color:rgba(255,255,255,1);
							color:$font-color;
							
							
							h2, .headline{
								font-weight:bold;
								font-size:1.125rem;
								padding:0;
								margin:0;
							}	
							
							>div{
								font-size:1rem;
								padding-top:10px;
								@media (max-width: $doubleTeaserSecondBreakpoint) {
									display:none;
								}
							}	
						}//end youtubeTextContainer
						
						img{max-width:515px;max-height:515px}
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							img{max-width:340px;max-height:340px}
						}
					}//youtubeContainer
					
					.doubleTeaserContainer{
						position:relative;
						cursor:pointer;
						.doubleTeaserTextContainer{
							border-radius: 5px;
							-webkit-border-radius: 5px;
							-moz-border-radius: 5px;
							bottom: 20px;
							left: 20px;
							padding: 15px;
							position: absolute;
							right: 20px;
							text-align: center;
							background-color:rgba(255,255,255,1);
							color:$font-color;
							
							
							h2, .headline{
								font-weight:bold;
								font-size:1.125rem;
								padding:0;
								margin:0;
							}	
							
							>div{
								font-size:1rem;
								padding-top:10px;

							}	
						}//end doubleTeaserTextContainer
						img{max-width:515px;max-height:515px}
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							img{max-width:340px;max-height:340px}
						}
					}//end doubleTeaserContainer
					
					.buttonContainer{
						a{
							display:inline-block;
							margin:5px 20px;
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								display:block;
								margin:20px 0 0 0;
								
							}
						}//a
					}//buttonContainer
					
					.table{
						display:table;
						width: 100%;
						//height: 100%;
					
						.tableCell{
							width:515px;
							display: table-cell;
							vertical-align:middle;
							&.innerPadding{padding:10px;}
							img.imageOnly{max-width:515px;max-height:515px}
							
							&.middle{
								padding-right:15px;
								&:nth-child(2n){padding-right:0;}
								@media (max-width: $doubleTeaserBreakpoint) {padding-right:0;}
							}
							&.top{
							    vertical-align:top;
								padding-right:15px;
								&:nth-child(2n){padding-right:0;}
								@media (max-width: $doubleTeaserBreakpoint) {padding-right:0;}							    
							}
													
							@media (max-width: $doubleTeaserBreakpoint) {
								display:block;
								width:515px;
								&.innerPadding{padding:0px;}
							}
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								img.imageOnly{width:max-340px;max-height:340px}
								width:340px;
							}
							
								.innerPadding{
									@media (min-width: $doubleTeaserBreakpoint) {width:515px;}							
								}	
						}// end tableCell
					}// end table
				}//end .singleText, .doubleTeaser	

				.tiptoiManagement {
					.textArea {
						margin: 10px 0;
						
						a:nth-child(2) {
							display: inline-block;
							margin-bottom: 10px;
						}
					}
				} /*end tiptoiManagement*/		
				
				.linkBox {
					.selectContainer{
						height:auto;
						margin-top:40px;
						margin-bottom:20px;
						border-radius: 5px;	
						cursor: pointer;
						background:$brand-primary;	
						color: $white;
						font-style: normal;
						font-weight: bold;
						
						.selectMain{
							padding:18px;
							background-image: url(/images/selectWhite@2x.png);
							background-position: center right 15px;
							background-repeat: no-repeat;
							background-size: 30px 13px;
						}
						
						.selectEntryContainer{
							width:100% !important;
							top:auto;
							height: auto;
							padding:18px;
							background-color: $white;
							color: $font-color;
							font-style: normal;
							font-weight: bold;
							border: none;
							outline: none;
						}
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							font-size: $font-size-sm;
						}				
					}
				}//end linkBox
				
				.faqQuestion {
					span, h3 {
						display: inline-block;
						vertical-align: middle;
						width:965px;
						
						@media (max-width: $doubleTeaserBreakpoint) {
							width: 418px;
						}
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							width: 244px;
						}				
					}
					h3 {
						color: $font-color;
						font-weight:700;
						font-size:1rem;
						margin-bottom:0px;
						line-height:1.5;
					}
					label{
						font-weight: bold;
						margin-bottom: 20px;
						border-radius: 5px;				
						background:$brand-primary;					
			    		color: $white;
			    		cursor: pointer;
						padding: 18px;
						width: 100%;				    
						border: none;
						outline: none; 
					}
					
					.faqBar {
						color: $faqBackground;
						
						span, h3  {margin-left:22px;}
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							font-size: $font-size-sm;
						}				
						input{
							position:absolute;
							opacity:0;
							z-index: -1;
							display:block;
		    				max-width:1045px;
						}
						input[type=checkbox]{width:initial;}
						label::before{
							display:inline-grid;
							vertical-align:middle;
						}
						
						& input[type=checkbox] + label{
							background:$border-color;					
		    				color: $font-color;
		    				transition: background 0.5s ease;
		    				.plus-minus-toggle{
		    					height:18px;
		    					width:18px;
		    					margin-left:0;
								vertical-align:middle;
								
								&::before, &::after {
									position:absolute;
									background:$brand-primary;
									content:'';
									height:4px;
									width:18px;	
									transition: transform 500ms ease;
				  				}
							    &::after{
									height:18px;
									width:4px;
									margin-left:7px;
								}
								&::before{
									margin-top:7px;
								}
			  				}
						}
						& input[type=checkbox]:checked + label {
							background-color:$white;
							transition: background-color 0.5s ease;
							.plus-minus-toggle{
								&::after{
									transform: rotate(-90deg);
								}
								
								&::before{
									transform: rotate(-180deg);
								}
							}
						}
					}
					
				}//end faqQuestion
					.noAnswer{
						background:$white;
						height: 200px;
						border-radius: 5px;
						text-align: center;
						padding:23px;
						font-weight: 700;
						margin-bottom: 20px;
						@media (max-width: $doubleTeaserBreakpoint) {
							height:auto;
						}

						.noAnswer-content{
							width:515px;
							margin:auto;
							margin-top:14px;
							@media (max-width: $doubleTeaserBreakpoint) {
									width:340px;
									margin-top:0;
							}
							@media (max-width: $doubleTeaserSecondBreakpoint) {
									width:280px;
							}
							
							img{
								float:left;
								clear:both;
								margin-top: -37px;
								@media (max-width: $doubleTeaserBreakpoint) {
									display:none;
								}
								
							}
							p:nth-of-type(2){
								color:$font-color;
								font-weight: normal;
								font-size: .875rem;
							}
							.btn {
								width:340px;
								font-size: .875rem;
				
								@media (max-width: $doubleTeaserSecondBreakpoint) {
									width:280px;
								}
							}
						}
					}
					
				.flip {
					 .editorialBigHeadline{
						font-size:2.25rem;
						font-weight:bold;
						line-height:1.1;
						margin-bottom: .5rem;
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							font-size:1.875rem;
						}
					}
				}
				
				.galleryFullpage{
	
					.editorialBigHeadline{
						font-size:2.25rem;
						font-weight:bold;
						margin-bottom:20px;
						line-height:1.1;
					}
				
					@media (max-width: $tileBreakpoint) {
						.editorialBigHeadline{font-size:1.875rem;margin-bottom:30px;}
					}
				}
				.tiles{
					.editorialBigHeadline{
						font-size:2.25rem;
						font-weight:bold;
						text-align:center;
						margin-bottom:20px;
						line-height:1.1;
						margin-top:0;						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							font-size:1.875rem;
						}
					}
				}
			}// end innerCell
		}// end innerTableContainer
	
		.background-circle-container {
			position:relative;
			width:515px;
			height:515px;
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:340px;
				height:340px;
			}
			
			.left {
				color: #2159ad;
				cursor: pointer;
				left: 8px;
				position: absolute;
				top: 228px;
			}
			.no-touchevents & .left:hover {
				color: #ccc;
			}
			.right {
				color: #2159ad;
				cursor: pointer;
				position: absolute;
				right: 8px;
				top: 228px;
			}
			.no-touchevents & .right:hover {
				color: #ccc;
			}
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				.left,.right{
					top:155px;
					>i{font-size:3rem}	
				}
				.left{left:4px;}
				.right{right:4px;}
				
			}
			
			.numberContainer{
				color:#7c7c7c;
				span{color:$brand-primary;}	
				position:absolute;
				text-align:center;
				left:0;
				right:0;
				bottom:0;
				font-size:1.125rem;
				font-weight:bold;
				padding-bottom:18px;
				@media (max-width: $doubleTeaserSecondBreakpoint) {padding-bottom:9px;}
			}

			.background-circle {
				background-color: rgba(255, 255, 255, 0.7);
				height: 515px;
				padding: 62px 0 0 62px;
				width: 515px;
				border-radius:515px;
				-webkit-border-radius: 515px;
				-moz-border-radius: 515px;
				
				
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width:340px;
					height:340px;
					border-radius:340px;
					-webkit-border-radius: 340px;
					-moz-border-radius: 340px;
					padding: 40px 0 0 40px;
				}
			}
			.inner-circle{
				border-radius: 390px;
				-webkit-border-radius: 390px;
				-moz-border-radius: 390px;
				height: 390px;
				overflow: hidden;
				width: 390px;
				background-color:#fff;
				
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width:260px;
					height:260px;
					border-radius:260px;
					-webkit-border-radius: 260px;
					-moz-border-radius: 260px;
				}
			}
			.innerElementContainer {
				margin: 0;
				padding: 0;
				width: 20000em;
				
				> div{
					display:table;
					
					> div{
						height: 390px;
						overflow: hidden;
						width: 390px;
						display:table-cell;
						vertical-align:middle;
						text-align:center;
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							width:260px;
							height:260px;
						}
						
						img {
							max-height: 265px;
							max-width: 265px;
							
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								max-width:180px;
								max-height:180px;
							}
						}//img
					}//div
				}//div
			}//innerElementContainer
		}//background-circle-container
		.oneCircle{
			position:relative;
			@media (max-width: $doubleTeaserBreakpoint) {margin-bottom:20px;}
			
			.inner-circle{
				border-radius: 390px;
				-webkit-border-radius: 390px;
				-moz-border-radius: 390px;
				height: 390px;
				overflow: hidden;
				width: 390px;
				background-color:#fff;
				margin:0 auto;
				z-index:2;
				position:relative;
				
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width:260px;
					height:260px;
					border-radius:260px;
					-webkit-border-radius: 260px;
					-moz-border-radius: 260px;
				}
				>div{
					display:table;
					width:100%;
					height:100%;
					>div{
						display:table-cell;
						text-align:center;
						vertical-align:middle;
						
						img {
							max-height: 265px;
							max-width: 265px;
							
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								max-width:180px;
								max-height:180px;
							}
						}//img
					}
				}//div
			}//inner-circle
			.shadow{
				background:url(/images/shadow-circle.png);
				width:313px;
				height:64px;
				background-size:313px 64px;
				position:absolute;
				bottom:-32px;
				left:97px;
				z-index:1;
				
				@media (max-width: $tileBreakpoint) {
					left:12px;
				}
			}
		}//oneCircle
		
		
		.tiles{
	
				text-align:left;
	
	/*
				@media (max-width: $doubleTeaserBreakpoint) {
					padding:95px 0px;
				}
		*/
				.tilesContainer>h1, .editorialBigHeadline{
					font-size:2.25rem;
					font-weight:bold;
					text-align:center;
					margin-bottom:20px;
					
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						font-size:1.875rem;
					}
				}
				
				h2 {
					margin-bottom:1rem;
				}				
				
				
				.tilesContainer{
					width:1045px;
					@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
					@media (max-width: $tileBreakpoint) {width:340px;}
					@media (max-width: $doubleTeaserSecondBreakpoint) {width:345px;}
						
					
					//reset rasterContainer for tiles
					&.rasterContainer{
						height:auto;
						
						.containerBlock{
							display:block;
						}
						
						.container_1_1{
							position:relative;
							margin:0 15px 15px 0;
							float:left;
							left:0;
							top:0;
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								width:165px;
								height:165px;
							}
							@media (max-width: $tileBreakpoint) {
								margin: 0 10px 10px 0;
							}
							
							
							&.last{margin-right:0;}
							@media (max-width: $doubleTeaserBreakpoint) {
								&.second{margin-right:0;}
							}
							
							//specific layout breakpoints for the 'tiles' flexmodule
							&.productStandard {
								>div {
									.upper {
										@media (max-width: $doubleTeaserSecondBreakpoint) {height:87px;}
										color: purple;
										
										.imageContainer {
											@media (max-width: $doubleTeaserSecondBreakpoint) {height:80px;}
										
											img {
												@media (max-width: $doubleTeaserSecondBreakpoint) {
													max-width:80px;
													max-height:80px;
												}
											}
										}//end imageContainer
									}//end upper
									.lower {
										.headline {
											@media (max-width: $doubleTeaserSecondBreakpoint) {height:15px;}
											a, a:hover{
												@media (max-width: $doubleTeaserSecondBreakpoint) {
													font-size:0.6875rem;
													height:26px;
												}
											}
										}
										.table {
											.table-row {
												.table-cell {
													.btn {
														@media (max-width: $doubleTeaserSecondBreakpoint) {
															padding:0.1rem 0.4rem;
															font-size: .8rem;
														}
													}
													.priceContainer{
														.newPrice{
															@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:0.875rem;}
														}
													}
												}
											}
										}
									}//end lower
								}//end div
							}//end productStandard
						}//end container1_1
					}//end rasterContainer
				}//end tilesContainer
		}//end tiles
		
	}// end content
	
	
	
	$sizeBigTeaser:		250px;
	$sizeSmallTeaser:	300px;
	$paddingTeaser:		15px;
	
	.flip{
		.flipContainer{
			margin: 25px 15px 25px 0;
			width: $sizeBigTeaser;
			height:$sizeBigTeaser;
			float:left;
			position:relative;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:$sizeSmallTeaser;
				height:$sizeSmallTeaser;
			}
			
			
			.card{
				width:$sizeBigTeaser;
				height:$sizeBigTeaser;
				background-color:$white;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width:$sizeSmallTeaser;
					height:$sizeSmallTeaser;
				}
				position:relative;
				text-align:left;
				
				.cardContainer{
					padding:15px;
					overflow:hidden;
					color:$brand-primary;
					
					.headline{
						height:110px;
						overflow:hidden;
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							height:145px;
						}
					}
					.closer{
						color:$brand-primary;
						float:right;
						margin:-5px 0 5px 5px;
					}
					
					.no-touchevents & .closer{display:none}
					
					a.headline, a.headline:hover{
						color:$brand-primary;
						font-size:1.5rem;	
						font-weight:bold;
						text-decoration:none;
						//display:inline-block;
						line-height:1.4rem;
					}
					a.headline:hover{color:$brand-dark}
					
					
					.cardInnerTable{
						display:table;
						width:100%;
							
						&.firstBlock{	
							height:52px;
							position:relative;
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								height:58px;
							}
						}
						
						&.secondBlock{
							height:58px;
							@media (max-width: $doubleTeaserSecondBreakpoint) {
								height:67px;
							}
						}
						
						.availableStatus{
							font-size:0.75rem;
							color:$menu-first-text-color;
							
							&.alignStatusTop{
								position: absolute;
								top: 3px;
								@media (max-width: $doubleTeaserSecondBreakpoint) {
									top: 8px;
								}
							}
						}
					}
					.cardInnerTableCell{
						display:table-cell;
						text-align:left;
						vertical-align:bottom;
					}
					
					
					
					.priceContainer{
						max-height:52px;
						.oldPrice{
							color:$dark-grey;
							font-size:1rem;
							text-decoration:line-through;
							white-space:nowrap;
						}
						.newPrice{
							font-weight:bold;
							color:$black;
							font-size:1.5rem;
							white-space:nowrap;
							&.orange{color:$orange4}
						}
						
						.familyAndFriends{
							position: absolute;
							bottom: -3px;
							right: 95px;
						}
					}//end priceContainer
					
					
					
				}//end cardContainer	
			}
			
			
			.inner-circle-container{
				width:$sizeBigTeaser;
				height:$sizeBigTeaser;
				position:relative;
				
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width:$sizeSmallTeaser;
					height:$sizeSmallTeaser;
				}
				
				.inner-circle{
					width:$sizeBigTeaser;
					height:$sizeBigTeaser;
					border-radius:$sizeBigTeaser;
					-webkit-border-radius: $sizeBigTeaser;
					-moz-border-radius: $sizeBigTeaser;
					overflow: hidden;
					background-color:$white;
					
					position:relative;	
					display:table;
					
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						width:$sizeSmallTeaser;
						height:$sizeSmallTeaser;
					}
					
					div{
						display:table-cell;
						text-align:center;
						vertical-align:middle;
					}
					
					img{
						max-width:170px;
						max-height:170px;
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							max-width:200px;
							max-height:200px;
						}
					}

				}//end inner-circle
				
				
				
			}//end inner-circle-container
			
			.shadow-circle{
				background:url(/images/shadow-circle.png);
				background-size:$sizeBigTeaser 50px;
				width:$sizeBigTeaser;
				height:50px;
				position:absolute;
				bottom:-44px;
				left:0;
				z-index:0;
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					width:$sizeSmallTeaser;
					background-size:$sizeSmallTeaser 50px;
				}
			}
			
		}//end flipContainer
		
		
		.flipContainer:hover, .flipContainer.focused{
			 .front{
				-webkit-animation: flip-vertical-left 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
			    animation: flip-vertical-left 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
			    z-index:-1;
			}
			.back{
				-webkit-animation: flip-vertical-right 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
				animation: flip-vertical-right 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
			}
		}

		.front{
			z-index: 1;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;	  
			transform: perspective(400px) rotateY(0deg);	
			-ms-transform: perspective(400px) rotateY(0deg); /* IE 9 */
		    -webkit-transform: perspective(400px) rotateY(0deg); /* Chrome, Safari, Opera */	
  			transition: z-index steps(3, jump-both) 0.5s;
      		opacity:1;
      		-webkit-animation: flip-vertical-right 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-vertical-right 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
		}
		.back{
			z-index: 2;
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;	  
			transform: perspective(400px) rotateY(-180deg);	
			-ms-transform: perspective(400px) rotateY(-180deg); /* IE 9 */
		    -webkit-transform: perspective(400px) rotateY(-180deg); /* Chrome, Safari, Opera */
			transition: 0.5s;
      		opacity:0;
      		-webkit-animation: flip-vertical-left 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
	        animation: flip-vertical-left 0.5s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
		}		
		
		
		@-webkit-keyframes flip-vertical-right {
  			0% {
    			-webkit-transform: rotateY(-180deg);
            	transform: rotateY(-180deg);
            	opacity: 0;
  			}
  			49%{
  				opacity: 0;
  			}
  			50%{
  				opacity: 1;
  			}
  			100% {
    			-webkit-transform: rotateY(0);
            	transform: rotateY(0);
            	opacity: 1;
  			}
		}
		@keyframes flip-vertical-right {
  			0% {
    			-webkit-transform: rotateY(-180deg);
            	transform: rotateY(-180deg);
            	opacity: 0;
  			}
  			49%{
  				opacity: 0;
  			}
  			50%{
  				opacity: 1;
  			}
  			100% {
    			-webkit-transform: rotateY(0);
            	transform: rotateY(0);
            	opacity: 1;
  			}
		}
		
		@-webkit-keyframes flip-vertical-left {
  			0% {
    			-webkit-transform: rotateY(0);
            	transform: rotateY(0);
            	opacity: 1;
  			}
  			49%{
  				opacity: 1;
  			}
  			50%{
  				opacity: 0;
  			}
 	 		100% {
    			-webkit-transform: rotateY(-180deg);
            	transform: rotateY(-180deg);
            	opacity: 0;
  			}
		}
		@keyframes flip-vertical-left {
  			0% {
    			-webkit-transform: rotateY(0);
            	transform: rotateY(0);
            	opacity: 1;
  			}
  			49%{
  				opacity: 1;
  			}
  			50%{
  				opacity: 0;
  			}
  			100% {
    			-webkit-transform: rotateY(-180deg);
            	transform: rotateY(-180deg);
            	opacity: 0;
  			}
		}
		
		.last{
			margin-right:0;
		}
		
		max-width:1045px;
		@media (max-width: $doubleTeaserBreakpoint) {
			width:515px;
			.second{margin-right:0}
			//padding:95px 0px;
			padding:10px 0;
		}
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:340px;
			.flipContainer{margin:35px auto !important;float:none}
		}
		
		text-align:center;
		
		h1, .editorialBigHeadline{
			font-size:2.25rem;
			font-weight:bold;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				font-size:1.875rem;
			}
		}

	
	}//end flip
	
	
	.tileflip{
		.tileflipContainer{
			margin: 0px 15px 15px 0;
			width: $sizeBigTeaser;
			height:$sizeBigTeaser;
			float:left;
			position:relative;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:165px;
				height:165px;
				margin: 0px 10px 10px 0;
				
			}
		}//end flipContainer
		
		.tileflipContainer:hover .tileFlipContainerInner {
  			transform: rotateY(180deg);
  			-webkit-transform: rotateY(180deg);
  			-moz-transform: rotateY(180deg);
		}

		.tileFlipContainerInner{
			position: relative;
			width: 100%;
			height: 100%;
			text-align: center;
			transition: transform 0.6s;
			transform-style: preserve-3d;
		}
		
		.front{
		
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;	  
			backface-visibility: hidden;
			
			transform: rotateY(0deg);
		}
		.back{
			
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;	  
			backface-visibility: hidden;
			transform: rotateY(180deg);
			-webkit-transform: rotateY(180deg);
  			-moz-transform: rotateY(180deg);
		}		
				
		.last{
			margin-right:0;
		}
		
		max-width:1045px;
		@media (max-width: $doubleTeaserBreakpoint) {
			width:515px;
			.second{margin-right:0}
			//padding:95px 0px;
			padding:10px 0;
		}
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:340px;
			.flipContainer{margin:35px auto !important;float:none}
		}
		
		text-align:center;
		
		h1, .editorialBigHeadline{
			font-size:2.25rem;
			font-weight:bold;
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				font-size:1.875rem;
			}
		}

	
	}//end tileflip
	
	.galleryFullpage{
	
		h1, .editorialBigHeadline{
			font-size:2.25rem;
			font-weight:bold;
			margin-bottom:20px;	
		}
				
		@media (max-width: $tileBreakpoint) {
			h1, .editorialBigHeadline{font-size:1.875rem;margin-bottom:30px;}
		}
		
		h2 {
			margin-bottom:1rem;
		}
		
		.galleryOuterContainer{
			width:1045px;
			height:515px;
			overflow:hidden;
			position:relative;
			
			@media (max-width: $doubleTeaserBreakpoint) {
				width:515px;
			}
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:340px;
				height:auto;
			}
			
			.galleryInnerContainer{
				width:20000em;
			}
			
			.galleryContainer{
				width:515px;
				margin-right:15px;
				float:left;
				position:relative;
				
				@media (max-width: $doubleTeaserBreakpoint) {
					margin-right:0;
				}
				@media (max-width: $doubleTeaserSecondBreakpoint) {
					margin-right:0;
					width:340px;
				}	
				
				a.imageContainer{
					background-size:cover;	
					-webkit-background-size:cover;
					-moz-background-size:cover;
					background-position:center center;
					background-repeat:no-repeat;
					width:515px;
					height:515px;
					display:block;
					position:relative;

					&.fourToThree{
					  width:515px;
					  height:384px;
					}
					
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						display:none;
					}
				}//end imageContainer	
								
				a.searchIcon{
					position:absolute;
					right:10px;
					bottom:10px;
					color:$white;
					text-shadow:2px 2px #000;
					display:block;
					z-index:1000;
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						display:none;
					}
				}
				
				.no-touchevents & a.searchIcon{display:none}
				
				.imageContainerSmall{
					display:none;
					width:340px;
					height:340px;
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						display:table;
						background-size: cover;
					}
					>div{
						display:table-cell;
						text-align:center;
						vertical-align:middle;
						img{
							max-width:340px;
							max-height:340px;
						}
					}
				}//end imageContainerSmall
				.textContainer{
					display:none;
				}
			}//end galleryContainer
		}//end galleryOuterContainer
	}//end gallery	
	
			.smallImageContainer{
				    display:block;
		            position:relative;
		            @media (max-width: $doubleTeaserBreakpoint) {
			        display:none;
		            }
				margin-top:15px;
				width:515px; 
				height:70px;
				
				.arrowLeftBig{
					float:left;
				}
				
				.arrowRightBig{
					float:left; 
				}
				
				.smallImagesOuterContainer{
					float:left;
					width:405px;
					overflow:hidden;
					position:relative;
					height:68px;
					
					.smallImagesInnerContainer{
						position:absolute;
						left:0;
						top:0;
						width:20000em;
						height:68px;
						
						//div:last-child{margin-right:0 !important;}
					}
					
					.smallImage{
						border:2px solid $light-grey;
						-webkit-border-radius: 4px;
						-moz-border-radius: 4px;
						border-radius: 4px;
						width:90px; 
						height:68px;
						float:left;
						margin-right:15px;
						cursor:pointer;
						display:table;
						
						&.active{border-color:$brand-secondary}
						&:hover{border-color:$brand-secondary-hover}
						
						>div{
							display:table-cell;
							vertical-align:middle;
							text-align:center; 
							
							img{
								max-width:82px;
								max-height:62px
							}
						}
					}
				}//smallImages
			}//smallImageContainer 
	
	.bigDotsContainer{
		display:block;
		position:relative;
		@media (max-width: $doubleTeaserBreakpoint) {
			display:none;
		}
	}
	
	.smallDotsContainer{
		display:none;
		position: absolute;
		width: 340px;
		bottom: 0px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			display:block;
		}
	}
	
	.middleDotsContainer{
		display:none;
		position:relative;
		
		@media (max-width: $doubleTeaserBreakpoint) {
			display:block;
		}
		
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			display:none;
		}
	}
				
	.galleryFullpage, .doubleTeaserGallery{
		.left{display:none}
		.left{
			position:absolute;
			left:0px;
			top:-520px;
			cursor:pointer;
			width:50px;
			height:515px;
			
			
			i{
				color:$white;
				padding:243px 0 0 10px;
				text-shadow:2px 2px #000;
				
				&.fourToThree{
			      		padding-top: 300px;
			    	}
				
				/*
				@media (max-width: $tileBreakpoint) {
					padding-top:156px;
				}*/
			}

			@media (max-width: $doubleTeaserSecondBreakpoint) {
				font-size:0.6875rem;
				height:340px;
				top:-300px;
				
				i{
					padding-top:156px;
				
				&.fourToThree{
			     		padding-top: 145px;
			    	}
				
				}
			}
		}
		
		//.no-touchevents & .left:hover{@include gradient-x(rgba(0,0,0,0.2),rgba(0,0,0,0))}
		
		.right{
			position:absolute;
			right:0px;
			top:-520px;
			cursor:pointer;
			width:50px;
			height:515px;
			text-align:right;
			
			
			i{
				color:$white;
				padding:243px 10px 0 0;
				text-shadow:2px 2px #000;
				
				&.fourToThree{
			      		padding-top: 300px;
			   	}
				
				/*
				@media (max-width: $tileBreakpoint) {
					padding-top:156px;
				}*/
			}
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				font-size:0.6875rem;
				height:340px;
				top:-300px;
				
				i{
				   padding-top:156px;
				   
				   &.fourToThree{
			     		 padding-top: 145px;
			    	   }
				
				}
			}
		}
		
		.textContainer{
			h3, .headlineGallery{
				font-weight:bold;
				font-size:1.5rem;
			}
		}
		
		//.no-touchevents & .right:hover{@include gradient-x(rgba(0,0,0,0),rgba(0,0,0,0.2))}
	
	}
	
	.doubleTeaserGallery{
		.bigDotsContainer{width:515px;}
	
		.doubleTeaserGalleryOuterContainer{
			width:515px;
			height:515px;
			overflow:hidden;
			position:relative;
			
			&.fourToThree{
			   height:384px;
			}
			
		 
			
			@media (max-width: $doubleTeaserSecondBreakpoint) {
				width:340px;
				height:auto;
				
				&.fourToThree{
					height:340px;
				}
			}
			
			.galleryInnerContainer{
				width:20000em;
				
				.galleryContainer{
					width:515px;
					margin-right:15px;
					float:left;
					position:relative;
					
					@media (max-width: $doubleTeaserBreakpoint) {
						margin-right:0;
					}
					@media (max-width: $doubleTeaserSecondBreakpoint) {
						margin-right:0;
						width:340px;
					}	
					
					a.imageContainer{
						background-size:cover;	
						-webkit-background-size:cover;
						-moz-background-size:cover;
						background-position:center center;
						background-repeat:no-repeat;
						width:515px;
						height:515px;
						display:block;
						position:relative;
						
						&.fourToThree{
					        	height:384px;
					    	}
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							display:none;
						}
					}//end imageContainer	
					
					a.searchIcon{
						position:absolute;
						right:10px;
						top:457px;
						color:$white;
						text-shadow:2px 2px #000;
						display:block;
						z-index:1000;
						
						&.fourToThree{
						 top:325px;												
						}
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							display:none;
						}
					}
					
					.no-touchevents & a.searchIcon{display:none}
					
					.imageContainerSmall{
						display:none;
						width:340px;
						height:340px;
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							display:table;
						}
						>div{
							display:table-cell;
							text-align:center;
							vertical-align:middle;
							img{
								max-width:340px;
								max-height:340px;
							}
						}
					}//end imageContainerSmall
			
					
					.textContainer{
						display:none;
					}
					.videoContainer{
						width:515px;
						height:515px;
						
						&.fourToThree{
					        height:384px;
					    }
						
						@media (max-width: $doubleTeaserSecondBreakpoint) {
							width:340px;
							height:340px;
						}
					}//end videoContainer
				}//end galleryContainer
			}//galleryInnerContainer
		}//doubleTeaserGalleryOuterContainer
	}//doubleTeaser 
	
	
	.bigImageContainer{
		width:1045px;
		position:relative;
		text-align:center;
		
		.bigImage, .middleImage, .smallImage{display:none;position:relative}
		.bigImage{display:block;}
		
		img{max-width:1045px;display:none}
		
		@media (max-width: $doubleTeaserBreakpoint) {
			width:515px;
			margin-bottom: 10px;
			.bigImage, .smallImage{display:none}
			.middleImage{display:block}
			img{max-width:515px;}
		}
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:340px;
			.bigImage, .middleImage{display:none}
			.smallImage{display:block;}
			img{max-width:340px;}
		}
		
		&.bigImageContainerYoutube{cursor:pointer}
		
	}//bigImageContainer
	
	.textContainerBigImage{
		position:absolute;
		bottom:70px;
		color:$font-color;
		left:195px;
		right:195px;
		background-color: rgba(255,255,255,1);
		border-radius:5px;
		-webkit-border-radius:5px;
		-moz-border-radius:5px;
		padding:20px 30px;
		
		&.fullsize{//when no images are available the container is in the middle of the page
			position:relative;
			left:0;
			right:0;
			bottom:0;
			text-align:center;
			width:655px;
			margin:0 auto;
			@media (max-width: $doubleTeaserBreakpoint) {width:475px}
			@media (max-width: $doubleTeaserSecondBreakpoint) {width:300px}
		}
		
		&.bigImageContainerYoutube{cursor:pointer}
		
		@media (max-width: $doubleTeaserBreakpoint) {
			bottom:20px;
			padding:15px;
			left:20px;
			right:20px;	
		}
		
		a{
			display:block;
			position:relative;
			color:$font-color;
		}
		
		a:hover{text-decoration:none;}
		
		h1, h2, h3{
			font-weight:bold;
			font-size:1.625rem;
			margin-bottom:0;
			@media (max-width: $doubleTeaserBreakpoint) {
				font-size:1.125rem;
				line-height: 1.6rem;	
			}
		}
		.text{
			font-size:1rem;
			@media (max-width: $doubleTeaserSecondBreakpoint) {&.disable{display:none}}
		}
	}
	
	//special behavior for editorial on category.jsp
	&.belowCategory .content .innerTableContainer{
		background: white;
		padding:10px !important;
	}
	
	.doubleTeaserContainer{
		position:relative;
		cursor:pointer;
		.doubleTeaserTextContainer{
			border-radius: 5px;
			-webkit-border-radius: 5px;
			-moz-border-radius: 5px;
			bottom: 20px;
			left: 20px;
			padding: 15px;
			position: absolute;
			right: 20px;
			text-align: center;
			background-color:rgba(255,255,255,1);
			color:$font-color;
			
			
			h2, .headline{
				font-weight:bold;
				font-size:1.125rem;
				padding:0;
				margin:0;
			}	
			
			>div{
				font-size:1rem;
				padding-top:10px;
			}	
		}//end doubleTeaserTextContainer
	}
	
//	.videoImageBackground {
//		@media (max-width: $doubleTeaserBreakpoint) {background-image: none;}
//	}

	.video-background {
		background: #000;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: -9999;
		box-sizing: border-box;
		@media (max-width: $doubleTeaserBreakpoint) {display:none;}
	}
	.video-foreground,
	.video-background iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		box-sizing: border-box;
	}
	
	@media (min-aspect-ratio: 16/9) {
		.video-foreground { height: 300%; top: -100%; }
	}
	@media (max-aspect-ratio: 16/9) {
		.video-foreground { width: 300%; left: -100%; }
	}
	
	.rasterContainer{
		@media (min-width: 535px) {
			width:515px;
		}
		@media (min-width: 1146px) {
			width:1005px;
		}
	}//end rasterContainer
	
	.editorialFragment.carousel{
		.carousel-controls{
			position: absolute;
			bottom: 50px;
			margin-bottom: 8px;
			text-align: center;
			width: 100%;
			z-index: 15;
			
			@media (max-width: $tileBreakpoint) {
				bottom: 0;
			}
		}

		.carousel-controls:before {
			content: '\200B';
			display: inline-block;
			height: 100%;
			vertical-align: middle;
			margin-right: -0.25em; /* Adjusts for spacing */
		}


		.carousel-indicators{
			bottom: auto;
			margin-bottom: auto;
			margin-left: auto;
			width: auto;
			left: auto;
			max-width: 515px;
			z-index: auto;
			position: static;
			display: inline-block;
			vertical-align: middle;
			margin-right: auto;
			li {
				width: 16px;
				height: 16px;
				margin-left: 8px;
				margin-right: 8px;
				border: none;
				border-radius: 8px;
				background-color: rgba(255,255,255,.5);
				display: inline-block;
			}
			li:hover,
			li:active,
			li.active {
				background-color: #fff;
			}
		}

		.carousel-control{
			display: inline-block;
			vertical-align: middle;
			top: auto;
			bottom: auto;
			background-image: none;
			text-shadow: none;
			position: static;
			width: auto;

			&.left{
				margin-right:20px;
			}
			&.right{
				margin-left:20px;
			}
		}

	}// end editorialFragment.carousel
	
	.productDetail {
		color:black;
		padding: 15px 0;
		
		.productImagePriceContainer {
		    display: inline-flex;
			@media (max-width: $doubleTeaserBreakpoint) {position:relative;}
			
			.productImageContainer {
				margin-top:4%;
				
				.smallImageContainer {
					margin-left: 0px;
				}	
			}
			.productPriceContainer {
				.productPriceHeadline{
					font-size:1.625em;
					font-weight:bold;
					line-height: 1.1;
					margin-bottom: .5rem;
					@media (max-width: $doubleTeaserBreakpoint) {font-size:1.5em;}	
				}
				.mobileSmallImageContainer {
					border-bottom: none; 
					padding-top: 60px;
					
					.dots-vertical-container {
						position:absolute; 
						padding-top:25px; 
						width:100% !important;
					}
					
					.arrowLeft, .arrowRight {
						padding-top:60px;
					}
					
					.searchIconContainer {
						position:absolute;
						right: 5%;
						padding-top: 20px;
						z-index: 1000;
					}
				}
				
				.description {
					width:auto;
					@media (max-width: $doubleTeaserBreakpoint) {padding:36px 0 0 0;}
					
					.descContainer {
						padding:0;
						
						.morecontent span {
							display: none;
						}
						.morelink {
							display: inline;
							text-decoration: underline;
						}
						
						a:focus {outline:none;}
					}
				}
				
				.rasterContainerProductDetail {
					width:auto;
					margin: 22px 0px;
					
					@media (max-width: $doubleTeaserSecondBreakpoint) {display:none;}
					
					.containerBlock.container_2_1 {
						top:0;
						left:0;
						position: relative;
						width: 475px;
						
						.table.icons {
							height: 130px;
						}
					}
				}
				
				#showCharacteristicsInMobile{
					width: auto;
					height:auto;
					.containerBlock {
						padding:0;
						width: auto;
						
					}
				}
				
				.mobileSmallImageContainerPD{
					display:none;
					@media (max-width: $doubleTeaserBreakpoint) {display:block}	
					border-bottom:$border-light-grey-2px;
					padding-bottom:30px;
					position:relative;
					
					.imageOuterContainer{
						position:relative;
						height:240px;
						width:475px; //515-40 padding from container
						@media (max-width: $doubleTeaserSecondBreakpoint) {width:300px} //340-40 padding from container
						overflow:hidden;
						
						.imageInnerContainer{
							width:20000em;
							position:absolute;
							left:0;
							top:0;
							
							.tableContainer{
								display:table;
								
								.tableCell{
									display:table-cell;
									position:relative;
									vertical-align:middle;
									text-align:center;
									width:475px;
									@media (max-width: $doubleTeaserSecondBreakpoint) {width:300px}
									img{
										max-height:240px;
										max-width:240px;
									}
								}//tableCell
							}//tableContainer
						}//imageInnerContainer
					}//imageOuterContainer
					
					.arrowLeft, .arrowRight{
						position:absolute;
						top:105px;
					}
					.arrowLeft{
						left:-25px;
					}
					
					.arrowRight{
						right:-25px;
					}
					
					.dots-vertical-container.orange{
						width:372px;
						margin:0 auto;
						@media (max-width: $doubleTeaserSecondBreakpoint) {width:201px;}
					}
					
					.searchIconContainer{
						/*
						position:absolute;
						bottom:30px;
						right:0;
						*/
						text-align:center;
						
						&>a{
							display:inline-block;
							.fa-search-plus.fa-3x{font-size:1.8rem}	
						}
					}
					
				}//mobileSmallImageContainer
			}
			
		}
	}/*end productDetail*/
	
	

}// editorial

.assist-wrapper{
	
	@media (max-width: $doubleTeaserBreakpoint) {display:none;}
	
	position: fixed;
	top: 27%;
	z-index: 1991;
	
	.assist-sticky{
		font-weight: bold;
	   	position: fixed;
		right: 0vw;
		overflow:hidden;
		background: $brand-dark-rvMy;
		width:100px;
		height:145px;
		text-align: center;
		color: $white;
		padding: 5px;
		font-size:$font-size-xs;
		z-index:1000;
		border-top-left-radius: 3px;
		border-bottom-left-radius: 3px;
		cursor:pointer;
		
		transition: all .7s ease;
		
		&:hover{
			width:120px;
		}			
	}

	.modal {
	   	display: none; 
		position: fixed; 			
    	z-index: 500; 		   
		background-color: rgb(0,0,0); 
		background-color: rgba(0,0,0,0.4); 				
	}

	.assist-content{
		z-index: 1000;
	    position: fixed;
		right:0px;
		background:white;
		padding: 28px 0;
		height: 650px;
		width: 0px;
		overflow:scroll;
		outline: none;
		transition: all .7s ease;
				
		.closer { 
			position:absolute;
			right:5px;
			top:15px;
			cursor:pointer;
		}

		.headline { 
			width:915px;
			font-size: 2.25rem;
			font-weight: bold;
		}
		.subheadline {
			width:915px;
			font-size: 1.3rem;
			font-weight: 700;
			line-height: 1.6rem;
		}
		
		.image-content {
			display:inline-flex;
			margin-top: 10px;
			
			img{
				margin-right:15px;
				&:last-child {margin:0;}
			}
		}
		.assist-list{
			width:915px;
			font-size: 14px;
			font-weight: bold;
			margin-top: 20px;
			
			ul {
				padding-left: 20px;
				
				>li {
					padding-left:30px;
				}
			}
		}
		.videoContainer {
			border: none;
			margin-top: 10px;
		}
		
	}
}//end assist

.tileFilterMargin {
	margin: 0px 125px !important; 
	@media (max-width: $doubleTeaserBreakpoint) {
		margin: 0px 0px !important;
	}
}
//end tileFilterMargin


//tile filter
.tileFilter{
	max-width:1045px;
	margin:0 auto;
	font-size:0.875rem;
	color:$font-color;
	
	h1{
		font-weight:bold;
		font-size:1.625rem;
		@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:1rem}
	}

	@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {width:345px;}
	
	@media (max-width: $tileBreakpoint) {width:340px;}
	
	&.chooseFilter{
		.filterContainer{
			.filterInnerContainer{
				width:945px;
				float:left;
				@media (max-width: $doubleTeaserBreakpoint) {width:100%;}
				
				.valueContainer{
					width:362px;
					margin:0 auto 60px auto;
					@media (max-width: $doubleTeaserBreakpoint) {
						width:300px;
						margin:0 auto 50px auto;	
					}
					
					.inputContainer{
						width:136px;
						float:left;
						@media (max-width: $doubleTeaserBreakpoint) {width:120px}
						input{
							text-align:center;
							font-weight:bold;
							color:$brand-primary;
						}
						.fakeInput{
							font-weight:bold;
							color:$brand-primary;
							text-align: center;
							padding: 10px;
							line-height: 1.2rem;
							border: 2px solid #d7d7d7;
							border-radius: 4px;
							-moz-appearance: none;
						}
					}//inputContainer
					
					.textContainer{
						width:90px;
						text-align:center;
						float:left;
						font-size:1.5rem;
						padding-top:5px;
						@media (max-width: $doubleTeaserBreakpoint) {width:60px}
					}//textContainer
					
				}//valueContainer
				
				.slider{
					.ui-widget-header{
						background-color:#e7000e;
						border:2px solid $light-grey;
						height:20px;
					}
					
					span.ui-slider-handle{
						background-color:#e7000e;
						border:6px solid $white;
						width:48px;
						height:48px;
						border-radius:24px;
						-webkit-border-radius:24px;
						-moz-border-radius:24px;
						-webkit-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.17);
						-moz-box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.17);
						box-shadow: 0px 0px 9px 3px rgba(0,0,0,0.17);
						top:-0.95em;
						cursor:pointer;
					}
					
					
					&.ui-widget-content{
						background:none transparent;
						border:none transparent;
					}
					
				}//slider
				
			}//filterInnterContainer
			
			.smallViewSlider{
				width:300px;
				background:url(/images/sliderBar300.png) no-repeat;
				height:20px;
				display:none;
				margin:0 auto;
				@media (max-width: $doubleTeaserBreakpoint) {display:block}
				>div{width:270px;}
			}
			
			.bigViewSlider{
				width:520px;
				height:20px;
				background:url(/images/sliderBar520.png) no-repeat;
				display:block;
				margin:0 auto;
				@media (max-width: $doubleTeaserBreakpoint) {display:none}
				>div{width:490px;}
			}
			
			.leftArrow, .rightArrow{
				height:140px;
				a{
					i{
						padding-top:44px;
					}
				}
			}
		}//filterContainer
	}//chooseFilter
}
//end tile filter

.textAlignCenter{text-align:center !important}
.textAlignRight{text-align:right !important}
.textAlignLeft{text-align:left !important}
.verticalTextTop{vertical-align:top !important}
.verticalTextBottom{vertical-align:bottom !important}
.verticalTextMiddle{vertical-align:middle !important}
.bigHide{display:none !important;}
.desktopOnly{display:block !important}
.tabletOnly{display:none !important}
.smartphoneOnly{display:none !important}

.table {
	.table-row {
		.table-cell {
			.divAlignVerticalHorizontal {
			    width: fit-content;
			    height: fit-content;
			    margin: auto;
			    position: absolute;
			    top: 0;
			    bottom: 0;
			    left: 0;
			    right: 0;
			    
			    @media (max-width: $doubleTeaserSecondBreakpoint) {
			    	position: relative;
				    height: 145px;
				    max-width: 145px;
				    display: table-cell;
				    vertical-align: middle;
			    }
			}
		}
	}
}


@media (max-width: $doubleTeaserBreakpoint) {
	.mobileTextAlignCenter{text-align:center !important}
	.mobileTextAlignLeft{text-align:left !important}
	.mobileTextAlignRight{text-align:right !important}
	.smallShow{display:block !important}
	.smallHide{display:none !important}
	.desktopOnly{display:none !important}
	.tabletOnly{display:block !important}
}

@media (max-width: $doubleTeaserSecondBreakpoint) {
	.tabletOnly{display:none !important}
	.smartphoneOnly{display:block !important}
}

