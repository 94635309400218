.author{
	.content{
		max-width:1045px;
		margin:0 auto;
		font-size:0.875rem;
		@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
		@media (max-width: $tileBreakpoint) {width:340px;}		
		
		h1{
			font-weight:bold;
			font-size:1.625rem;
			@media (max-width: $tileBreakpoint) {font-size:1rem}
		}
		
		h3{
			@media (min-width: 120px) {font-size:1rem}
			@media (min-width: $tileBreakpoint) {font-size: 1.625rem; width: 450px;}	
		}		
	
		#eventbtn.btn{
			margin-bottom:15px;
			@media (min-width: 120px) {margin-left:100px;font-size: smaller;}
			@media (min-width: $tileBreakpoint) {margin-left:170px;font-size: large;}
			@media (min-width: $doubleTeaserBreakpoint) {margin-left:450px}			
	 	}
	 	
		.productlist{
			@media (min-width: $tileBreakpoint) {width: 780px;}
			@media (min-width: $doubleTeaserBreakpoint) {width: 1310px;}
			
			.textAlignRight{
				@media (min-width: $tileBreakpoint) {width: 515px;}
				@media (min-width: $doubleTeaserBreakpoint) {width: 1045px;}			
			}			
		}	 	

		.rasterContainer{width:auto;}
		
		.containerBlock{
			position:relative;
			left:0;
			top:0;
		}
		
		.container_2_2{
			float:left;
			margin-bottom:15px;
			&:first-of-type{margin-right:15px;}
			
			@media (max-width: $doubleTeaserBreakpoint) {
				float:none;
				margin-bottom:10px;
			}
		}
		
		.likeh3{
			font-size: 1.625rem;
			font-weight: 700;
			color: #1a5dae;
			@media (min-width: 120px) {padding-left:10px;}
			@media (min-width: $doubleTeaserBreakpoint) {padding-left:0;}			
			
		}		
		
		.filterContainerBig{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
			background-color:$authorBoxBackground;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			padding:15px;
			text-align:center;
			
			
			.table{
				margin:0 auto;
				width:auto;
				display:table;
				background:none;
				
				.table-row{
					display:table-row;
				
					.table-cell{
						display:table-cell;
						&.center{text-align:center}
						&.middle{vertical-align:middle}
					}
				}
			}//table
			
			.filterBoxContainer ul li{
				cursor:pointer;
				
				a.filterbox{padding:9px 13px 8px 13px}
			}
			.searchOuterContainer{padding:0;height:auto}
			.searchInnerContainer{
				border-color:$white;
				input[type='text']{width:250px;}
			}
		}//filterContainerBig
		
		.filterContainerSmall{
			display:none;
			width:auto !important;
			@media (max-width: $doubleTeaserBreakpoint) {display:block;}
			
			.filterHeadline{
				width:auto;
				float:none;	
				display:block !important;	
				&.opened{
					border-radius: 3px 3px 0px 0px !important;
					-moz-border-radius: 3px 3px 0px 0px !important;
					-webkit-border-radius: 3px 3px 0px 0px !important;
					border-bottom:2px solid $light-grey;
				}
				
				.table{
					.table-cell{
						&.first{
							width:auto;
						}
						&.last{
							width:auto;
						}
					}//table-cell
				}//table
			}//filterHeadline
			
			.filterContentHeadline{
				-webkit-border-radius: 0px;
				-moz-border-radius: 0px;
				border-radius: 0px;
				margin:0 !important;
				border-bottom:2px solid $light-grey;
				@media (min-width: $tileBreakpoint) {width:515px;}
			}
			
			.filterContent{
				border-top:none !important;
				border-bottom:2px solid $light-grey !important;
			}
			
		}//filterContainerSmall
		
		
		.rasterContainer{
			margin:10px 0;
			@media (max-width: $tileBreakpoint) {margin:10px 0;}

			
			
			.container_1_1{
				float:left;
				margin:0 15px 15px 0;
				@media (max-width: $tileBreakpoint) {margin:0 10px 10px 0;}
				
				&:nth-child(4n){margin-right:0;}
				@media (max-width: $doubleTeaserBreakpoint) {
					&:nth-child(2n){margin-right:0;}
				}
			}
		}
		
		
		
		
		//detailpage
		
		.authorDetailContainer{
			background-color:$white;
			border-radius: 3px;
			-moz-border-radius:3px;
			-webkit-border-radius:3px;
			padding:30px;
			margin-bottom:25px;
			margin-top: 17px;
			font-size:1.125rem;
			@media (max-width: $tileBreakpoint) {font-size:0.875rem}
			
			@media (max-width: $doubleTeaserBreakpoint) {
				padding:0px;
				&.authorTopBox{
					border-radius:0 0 3px 3px;
					-moz-border-radius:0 0 3px 3px;
					-webkit-border-radius:0 0 3px 3px;
				}
				margin-bottom:20px;
				margin-top: 17px;
			}
			
			h1{
				color:$brand-primary;
				font-weight:bold;
				font-size:2.25rem;
				@media (max-width: $tileBreakpoint) {font-size:1.875rem}
			}
			
			h3{
				color:$brand-primary;
				font-weight:bold;
				font-size:1.625rem;
				height: 85px;
				@media (max-width: $tileBreakpoint) {font-size:1rem}
			}
			
			.secondHeadline{
				font-size:1.125rem !important;
				margin:10px 0 15px 0;
			}
			
			.singleTeaser_main{
				@media (min-width: $doubleTeaserBreakpoint) {float:left;}
	
				img.author{
					max-width:450px;
					max-height:450px;
					float:left;
					margin:12px 45px 5px 0;
					@media (max-width: $doubleTeaserBreakpoint) {max-width:515px;max-height:515px;margin:0;padding: 10px;float:none}
					@media (max-width: $tileBreakpoint) {max-width:340px;max-height:340px;}
				}
				
				.copyright_photo{				
					@media (min-width: 120px) {padding:0 10px 0 10px ;}
					@media (min-width: $tileBreakpoint) {padding:0 10px 0 10px ;font-size: 14px;}
					@media (min-width: $doubleTeaserBreakpoint) {
						padding-left:0px;
						font-size: 14px;
						width: 200px;
					}				
				}
			}
			
			.googleMapContainer{
				float:right;
				max-width:480px;
				max-height:300px;
				margin: -90px 0 45px 45px;
				@media (max-width: $doubleTeaserBreakpoint) {max-width:495px;max-height:310px;margin:10px;float:none}
				@media (max-width: $tileBreakpoint) {max-width:320px;max-height:200px;}
				
				.map_container{		
					@media (min-width: 120px) {width:320px;}				
					@media (min-width: $tileBreakpoint) {width:490px;}
				}
			}
			
			.showSmall{
				display:none;
				padding:10px;
				@media (max-width: $doubleTeaserBreakpoint) {display:block}
			}
			
			.textContainer{
				padding:0px;
				@media (max-width: $doubleTeaserBreakpoint) {padding:10px;}				
				@media (max-width: $tileBreakpoint) {&.textContainerHidden{max-height:250px;overflow:hidden;}}
				
				table{	
					float:left;
					width:450px;
					@media (max-width: $tileBreakpoint) {width: initial;}
				}
				.overlap{
					display:none;
					position:absolute;
					bottom:0;
					left:0;
					width:340px;
					height:50px;
					@include gradient-y(rgba(255,255,255,0.1),rgba(255,255,255,1));
					@media (max-width: $tileBreakpoint) {display:block;}
				}
				.more{
					display:none;
					@media (max-width: $tileBreakpoint) {display:block;}
						
						.morecontent span {
							display: none;
						}
						
						.morelink {
					    	display: block;
						}
				}
				.less{
					display:block;
					@media (max-width: $tileBreakpoint) {display:none;}
				}
				
				.showBig{
					display:block;
					@media (max-width: $doubleTeaserBreakpoint) {display:none}
				}
			}//textContainer
			
			.morePressText{
				display:none;
				@media (max-width: $tileBreakpoint) {display:block;margin:0 0 20px 10px}
			}
			
			
			
		}//authorDetailContainer
		
	#filterContainerAuthor{
		display:none;	
		
		.filterContentHeadline{	
			@media (min-width: $bigPagerBreakpoint) {
				//display: block !important; TODO filtermainContainer
				margin-top:15px;
			}
		
			.badge{
				background-color:$brand-primary;
				color:$white;
				margin-left:10px;
			}		
			
			.fa-chevron-down, .fa-chevron-up, .fa-times{font-size:1.5rem;}
			.fa-chevron-up{display:none}
			.fa-times{
				position:absolute;
				left:20px;
				top:17px;
				display:none;
			}
			.table{padding:10px 20px;}
			//.table-cell.deleteOption{padding-left:32px;}
			.table-cell.first{line-height:auto;}
				
		}//filterContentHeadline	
		
		.filterContent{
			display:none;
			background-color:$white;
			padding:20px;
			border-radius:0px 0px 3px 3px ;
			-moz-border-radius: 0px 0px 3px 3px;
			-webkit-border-radius: 0px 0px 3px 3px;
			font-size:0.875rem;
			border-top:2px solid $light-grey;
			border-bottom:none;
			overflow-y:auto;
			max-height:265px;
			
			@media (max-width: $bigPagerBreakpoint - 1){
				border-top:none;
				border-radius: 0px;
				-moz-border-radius: 0px;
				-webkit-border-radius: 0px;
				
				border-bottom:2px solid $light-grey;
				font-size:1rem;
			}
			
			.filterContentTable{
				display:table;
				width:100%;
				
				.filterContentTableRow{
					display:table-row;
					
					.filterContentTableCell{
						display:table-cell;
						
						&:first-child{width:30px;}
						&.cursor{cursor:pointer;}
						&.active{background:rgb(26, 93, 174);color:white;padding:2px 5px;}
					}
				}
			}
			
			
			
		}//filterContent
		
		.filterEnd{
			background-color:$white;
			padding:20px;
			border-radius:0px 0px 3px 3px ;
			-moz-border-radius: 0px 0px 3px 3px;
			-webkit-border-radius: 0px 0px 3px 3px;
			
			&>div:first-child{margin-bottom:20px;}
		}

	}//filterContainerAuthor
		
		
	}//content
}//author