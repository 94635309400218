#mobileMenu{
	position:fixed;
	top:0;
	left:-360px;
	width:300px;
	z-index:9000;
	background-color:$white;
	bottom:0;
	overflow:hidden;

	#blackOverlayFlag{
		position:fixed;
		left:0;
		top:0;
		width:0;
		height:0;
		background-color:rgba(0,0,0,0);
		z-index:8900;
	}
		
	#blackOverlayFlag.blackOverlayActive{
		width:inherit;
		height:100%;
		background-color:rgba(0,0,0,0.5);
	}
		
	.scrollable{
		height: 100%;
		margin-right: -40px;
		overflow-y: scroll;
		padding-right: 40px;
	
		#menuContainer{
			width:300px;
		}

		#displayFlagMobile{
			position: relative;
			z-index: 9000;
			top: 0;
		}
 
		ul{
			margin:0;
			padding:0;
		
			li.flagEntryContainer{
				    color: #424242;
				    background-color: #fff;
				    cursor: default;	
			}
			
			li{
				min-height:60px;
				border-bottom:1px solid $menu-border;
				list-style:none;
					
				font-weight:bold;
				background-color:$brand-primary;
				color:$white;
				cursor:pointer;
				position:relative;
				
				&>div{padding:17px 35px 17px 20px;}
				&.directLink{padding:0}
				
				a, a:hover{
					color:$white;
					text-decoration:none;
					padding:17px 20px;
					display:block;
				}
				
				&#menuHeader{
					background-color:$white;
					color:$menu-first-text-color;
					cursor:auto;
					padding:17px 35px 17px 20px;
					z-index: 9000;	
				}
				
				//secondMenus
				ul.secondMenuEntryContainer{
					display:none;
				
					li{
						background-color:$light-grey;
						color:$brand-primary;
						
						&>div{padding:17px 35px 17px 35px;}		
						a, a:hover{
							color:$brand-primary;
							padding:17px 20px 17px 35px;
						}
						
						//thirdMenus
						ul.thirdMenuEntryContainer{
							display:none;
						
							li{
								background-color:$white;
								color:$brand-primary;
								
								&>div{padding:17px 35px 17px 50px;}		
								a, a:hover{
									color:$brand-primary;
									padding:17px 20px 17px 50px;
								}
								
								ul.fourthMenuEntryContainer{
									display:none;
								
									li{
										background-color:$white;
										color:$black;
										font-weight:normal;
										
										&>div{padding:17px 35px 17px 65px;}		
										a, a:hover{
											color:$black;
											padding:17px 20px 17px 65px;
											font-weight:normal;
										}
									}
								}// end ul.fourthMenuEntryContainer
							}
						} // end ul.thirdMenuEntryContainer
					}
				}//end ul.secondMenuEntryContainer
			}
		}// end ul
	}// end scrollable
}// end #mobileMenu

#close{
	position:fixed;
	left:300px;
	top:-60px;
	cursor:pointer;
	width:60px;
	height:60px;
	background-color:$light-grey;
	z-index:9000;
}

#blackOverlay{
	position:fixed;
	left:0;
	top:0;
	width:0;
	height:0;
	background-color:rgba(0,0,0,0);
	z-index:8900;
}

#blackOverlay.blackOverlayActive{
	width:100%;
	height:100%;
	background-color:rgba(0,0,0,0.5);
}


header{
	background-color:$brand-primary-header;
	color:$white;
	position:fixed;
	left:0;
	top:0;
	width:100%;
	z-index:8990;
	
	.divider{
		border-right:2px solid $header-border-color;
		float:left;
		display:block;
		height:80px;
		@include media-breakpoint-down(xs) {
		  height:60px;
		}
		
		&.searchContainerDivider{
			float:right;
			@media (max-width: $secondSearchBreakpoint) {
				display:none;
			}	
		}
	}
	
	#mainMenu{
		min-width:334px;
		/*
		-webkit-box-shadow: 0 0 4px 2px rgba(0,0,0,0.5);
		-moz-box-shadow: 0 0 4px 2px rgba(0,0,0,0.5);
        box-shadow: 0 0 4px 2px rgba(0,0,0,0.5);
		*/
		
		border-bottom:2px solid $header-border-color;
		
		.divider.first{
			display:none;
			@media (max-width: $menuBreakpoint) {
				display:block;
			}
		}
		
		#mainMenuLogos{
			float:right;
			padding:0 10px;
			
			@include media-breakpoint-down(xs) {
			 // float: left;
			  padding:0;
			}
			
		}
		
		#languageSelectorContainer {
			position: relative;
			float: right;
			width: 70px;
			height: 70px;			
			margin-top: 10px;			
			font-size: .875rem;			
			padding: 0 10px;
			
			@include media-breakpoint-down(xs) {
				  display: none;
			}
	
				.languageSelector {
					position: relative;
					cursor: pointer;
					top: 10px;
					text-align: center;
					
					.flagSphere { 
						display: inline-block;
						background-color: $white;
						border-radius: 50%;
						height: 32px;
						width: 32px;
						margin: 2px 5px 0 0;
					}
					
					.caret {
						float: right;
						font-size: 1.5em;
						top: 5px;
						position: relative;
						display: inline-block;
						color: #1a5dae;
					}
				} /*end languageSelector*/
				
				.whiteHoverBackground {
					padding: 30px 20px 30px 20px;
					z-index: 10;
					
					@media (max-width: $secondSearchBreakpoint) {
						right:-60px;
					}
					
					.selectEntryContainer {
						width: 100% !important;
					}
					
					.submitLanguage {
						margin-top: 20px;
						width: 310px;
					}
					
					.selectEntry{
						padding:.75em 1em .5em 1em;
						
						&:hover, &.active{
							background-color:$brand-secondary;
							color:$black;
						}
					}
				}
		}/*end languageSelectorContainer*/
		
		#menuEntryContainer{
			float:left;
			display:block;
		
			@media (min-width: 1270px) {
				display: block !important;
			}
	
			.menuEntry{
				position:relative;
				float:left;
				text-align:center;
				height:80px;
				display:block;
				font-size:0.875rem;
				font-weight:bold;
				border-right:2px solid $header-border-color;
				
				&>a, &>a:hover{
					color:$brand-primary;
					text-decoration:none;
					padding:30px 20px;
					display:block;
					height:80px;
				}
				
				&>a:hover, &:hover{
					background-color:$brand-dark;
					color:$white;
				}
				
				&>a.active, &>a.active:hover, &>a.tempActive, &>a.tempActive:hover{
					color:$white;
					background-color:$brand-dark;
				}
				
				.menuEntryHoverContainer{
					position:absolute;
					left:0;
					top:82px;
					background-color:$white;
					display:none;
					text-align:left;
					font-weight:normal;
					border-bottom:1px solid $header-border-color;
					
					.secondContainer{
						max-width:1575px;
						padding:20px 15px;
						color:$font-color;
						margin:0 auto;
						
						ul{
							margin:0;
							padding:0;
							list-style-type:none;
							
							li{
								float:left;
								position:relative;
								margin-right:40px;
								
								a, a:hover{
									text-decoration:none;
									color:$font-color;
									line-height:1.5rem;
								}
								a:hover{color:$black;}
								a.active{font-weight:bold;}
								
								&.flyout{

									.flyoutContainer{
										display:none;
										position:absolute;
										border-top:1px solid $flyout-border-color;
										border-bottom:1px solid $flyout-border-color;
										background-color:$white;
										z-index:9001;
										//top:44px;
										left:0;
										
										.flyoutInnerContainer{
											max-width:1075px;
											color:$font-color;
											margin:0 auto;
											padding:15px;
											
											.categoryContainer{
												float:left;
												width:250px;
												
												h2, .flyoutCategoryHeadline {
													font-size:0.875rem;
													font-weight:bold;
													color:$brand-primary;
													margin-bottom: .5rem;
													line-height:1.1;
												}
												a{display:block}
											}
											
											
											
											.teaserContainer{
												float:left;
												width:515px;
												margin-left:15px;
												
												@media (max-width: 1085px) {
													display:none;
												}
												
												.container_2_1, .container_1_1{
													position:relative;
													top:0;
													left:0;
												}
											}
										}
									}
								
								
								}//flyout
							}//li
						}//ul
						.productStandard{
							.headline{
								a, a:hover{
									color:$brand-primary;
								}
								a:hover{color:black}
							}
							.btn{color:#fff;}
						}
					}//secondContainer
				}//menuEntryHoverContainer
				
			}//end menuEntry
			
			@media (max-width: 1064px) {
			  display:none;
			}
		}//end menuEntryContainer
	}
	
	#burger{
		cursor:pointer;
		//border-right:2px solid $header-border-color;
		float:left;
		display:none;
		margin-top:10px;
		@media (max-width: 1064px) {
		  display: block;
		}
		@include media-breakpoint-down(xs) {
		  margin-top:0;
		}
	
		&.showTouch {		
			@media (min-width: 1270px) {
				display:none !important;	
			}
		}
	}
	
	
	
	.showTouch{display:block !important}
	.hideTouch{display:none !important}

	.whiteHoverBackground {
		overflow-y: auto;
	}
}//end header


#smallSearchContainer, #searchContainer, .tilesContentSearchContainer, .newsletterInputOuterContainer, .searchOuterContainer{
	height:60px;
	
	padding:10px;
	color:$dark-grey;
	display:none;
	position:relative;
	
	#smallSearchInputContainer, #searchInputContainer, .tilesSearchInputContainer, .newsletterInputContainer, .searchInnerContainer{
		border:2px solid $border-color;
		-webkit-border-radius: 4px;
		-moz-border-radius: 4px;
		border-radius: 4px;
		height:40px;
		width:100%;
		background-color:$white;
		
		form{position:relative;}
		
		input[type="text"]{
			border:0 none;
			font-style:italic;
			width:100%;
			padding:5px 52px 0 10px;
			outline: none; 
		}
		
		
		button{
			position:absolute;
			top:-2px;
			right:-2px;
			height:40px;
			width:46px;
			border:0 none;
			background-color:$orange2;
			-webkit-border-top-right-radius: 4px;
			-webkit-border-bottom-right-radius: 4px;
			-moz-border-radius-topright: 4px;
			-moz-border-radius-bottomright: 4px;
			border-top-right-radius: 4px;
			border-bottom-right-radius: 4px;
			padding-left:9px;
		}
	}
	.newsletterInputContainer {
		input[type="text"]{
		@media (max-width: $doubleTeaserSecondBreakpoint){
			font-size: 0.9rem;
		}
		}
		
		button {
			background-color:$bg-icon-newsletter;
		}
	}
		
	#searchInputContainer{border-color:$white;}
	
	.deleteButton{
		position:absolute;
		cursor:pointer;
		top:5px;
		right:57px;
		
		i{color:$brand-primary;font-size:1.5rem;}
	}//deleteButton
}
#smallSearchContainer{
	background-color:$white;
	/*
	@include media-breakpoint-down(xs) {
	  display: none;
	}*/
}

#searchContainer{
	display:block;
	float:right;
	width:380px;
	margin-top:9px;
	@media (max-width: $firstSearchBreakpoint) {
	  width:250px;
	}
	
	@media (max-width: 1266px) {
	  display:none;
	}
	
	&.forBigTouchDivices{
		display:block !important;
		width:250px;
	}
	
}

.tilesContentSearchContainer{
	display:block;
	width:70%;	
	
	input[type="text"]{padding:8px 52px 0 10px !important}
}

// end searchContainer

.newsletterInputOuterContainer, .searchOuterContainer{display:block;}


.numberCircle{
	color:$white;
	background-color:$orange;
	width:20px;
	height:20px;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	text-align:center;
	border:2px solid $brand-primary-header;
	font-size:0.6875rem;
	font-weight:bold;
}


#search_suggest_big,#search_suggest_small{
	position:absolute;
	display:none;
	left:532px;
	top:80px;
	z-index:999;
	background-color:$brand-primary-header;
	//padding:15px;
	color:$font-color;
	-webkit-box-shadow: 0px 5px 7px -2px rgba(0,0,0,0.23);
	-moz-box-shadow: 0px 5px 7px -2px rgba(0,0,0,0.23);
	box-shadow: 0px 5px 7px -2px rgba(0,0,0,0.23);
	
	overflow-y:auto;
	overflow-x:hidden;
	
	
	.productContainer{
		margin-bottom:5px;
		padding:10px 10px 5px 10px;
		.imageContainer{
			float:left;
			width:90px;
			padding-right:15px;
			text-align:center;
			img{
				max-width:75px;
				max-height:75px;
			}
		}
		
		.descriptionContainer{
			float:left;
			width:135px;
			font-size:0.6875rem;
			a{font-weight:bold}
			
			.priceContainer{
				.oldPrice{
					color:$dark-grey;
					text-decoration:line-through;
				}
				.newPrice{
					font-weight:bold;
					color:$black;
					&.orange{color:$orange4}
				}
			}
		}
	}//productContainer
	
	
}

#search_suggest_big{
	//display:block !important; //delete later
	//width:650px;//delete later
	
	width:350px;
	
	.searchBigcontainer{
		width:350px;
		margin:0 auto;
		
		>div.clearfix{
			margin:5px 0 10px 0;
		}
		
		
	}//searchBigContainer
		
	#categoriesAndBrandContainer{
		float:left;
		padding:15px;
		border-right:1px solid  $header-border-color;
		width:275px;
		a{display:block}
	}
	
	#productContainer{
		float:left;
		width:275px;
		padding:15px;
		
		
		
		
	}//#productContainer
	
	.suggestionContainer{background-color:$searchFlyoutSuggestionBackground;}
	#suggestionContainer{
		padding:15px;
		a{display:block}
	}

	.editorialContainer{background-color:$searchFlyoutSuggestionBackground;}
	#editorialContainer{
		padding:15px;
		a{
			display:block;
			color: $brand-primary;
			text-decoration: none;
			font-size:0.6875rem;
			span{
				font-weight: bold;
			}
		}
	}

}//search_suggest_big

#search_suggest_small{
	left:0px;
	top:38px;
	
	@media (max-width: $tileBreakpoint) {top:114px;}
	
	
	-webkit-border-radius: 3px;
	-moz-border-radius: 3px;
	border-radius: 3px;
	padding-top:10px;
	
	hr{margin:0;}
	
	#smallCategoriesContainer, #smallBrandContainer,#smallSuggestionContainer,#smallEditorialContainer{
		a{display:block}
		padding:10px;
	}
	
	.productContainer{padding:10px 10px 5px 10px;}
	
	#smallSuggestionContainer{
		background-color:$searchFlyoutSuggestionBackground;
	}

	#smallEditorialContainer{
		
	}

}//serach_suggest_small

.selectContainer {
	&:hover .selectEntryContainer {
		display:block;
	} 
}

