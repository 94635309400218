.searchResultList{
	
	 #filterContainer{
	 	.filterContentHeadline{
	 		margin-top:15px;
	 		margin-bottom:0;
	 	}
	 } 

	.redEntries{
		
		max-width:1045px !important;
		
	}//redentries

	.editorials{
		&.smallContainer{
			background-color: transparent;
		}
		h3{
			font-size: 20px;
			em{
				font-weight: bold;
				font-style: inherit;
			}
		}
		p{
			em{
				font-weight: bold;
				font-style: inherit;
			}
		}
		div.buttonContainer{

		}
	}
}


.gameInstructions{
	
	color:$font-color;
	margin:20px 0;
	max-width:1045px;
	
	@media (max-width: $tileBreakpoint){
		width:340px;
	}
	
	
	.smallLanguagesContainer{
		display:none;
		@media (max-width: $doubleTeaserBreakpoint){display:block}
	}
	
	.bigLanguagesContainer{
		display:block;
		@media (max-width: $doubleTeaserBreakpoint){display:none}
	}
	
}