.faq{
	.content{
		max-width:1045px;
		margin:0 auto;
		font-size:0.875rem;
		
		h1{
			font-weight:bold;
			font-size:1.625rem;
		}
	
		@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
		
		@media (max-width: $tileBreakpoint) {width:340px;}
		
		.searchOuterContainer{
			padding:10px 0;
			position:relative;
			
			input[type="text"]{padding: 5px 81px 0 10px;}
			
			#faqSuggestions{
				position:absolute;
				left:0;
				right:0;
				top:50px;
				background-color:$white;
				padding:10px;
				border:1px solid $light-grey;
				display:none;
			}//faqSuggestions
		}//searchOuterContainer
		
		
		.noAnswerBox{
			width:100%;
			background-color:$white;
			-webkit-border-radius: 4px;
			-moz-border-radius: 4px;
			border-radius: 4px;
			padding:15px;
			text-align:center;
			
			h2{
				font-weight:bold;
				color:$brand-primary;
				font-size:1rem;
			}			
		}//noAnswerBox
		
		.categoryContainer{
			.categoryBox{
				width:250px;
				margin-right:15px;
				float:left;
				
				@media (max-width: $doubleTeaserBreakpoint) {
					width:515px;
					margin:0;
					float:none;
					margin-bottom:10px;
				}
				@media (max-width: $tileBreakpoint) {width:340px;}
				
				
				.selectContainer{
					background-color:$brand-primary;
					color:$white;
					font-style:normal;
					font-weight:bold;
					height:50px;
					
					.selectMain{
						padding:.95em 3em .5em 1em;
					}
					
					.selectCloserWhite, .selectOpenerWhite {top:20px}
					
					.selectEntryContainer{
						top:50px;
						background-color:$brand-primary;
						border-top-color:$white;
					}
				}//selectContainer
				
				
				.category{
					background-color:$white;
					padding:15px;
					display:none;
					margin-top:10px;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
				}
				
				/*
				.categoryChooseBox{
					background-color:$brand-primary;
					width:100%;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
					color:$white;
					padding:15px;
					font-weight:bold;
					
					.table{
						display:table;
						margin-bottom:0;
						.table-cell{
							display:table-cell;
							
							&.second{
								
								width:25px;
								padding-left:10px;
								>i{
									line-height:1rem;
									font-size: 1.5rem;
									margin-top:-1px;
								}	
							}
						}
					}//table
				}//categoryChooseBox
				*/
				
				
			}//categoryBox
			
			.faqEntriesContainer{
				width:780px;
				float:left;
				
				@media (max-width: $doubleTeaserBreakpoint) {float:none;width:515px;}
				@media (max-width: $tileBreakpoint) {width:340px;}
				
				
				.faqEntry{
					background-color:$faqBackground;
					-webkit-border-radius: 4px;
					-moz-border-radius: 4px;
					border-radius: 4px;
					margin-bottom:15px;
					@media (max-width: $doubleTeaserBreakpoint) {margin-bottom:10px;}
					
						
					.table{
						display:table;
						width:100%;
						background:none;
						margin:0;
						padding:15px;
						
						@media (max-width: $doubleTeaserBreakpoint) {padding:10px;}
						cursor:pointer;
						
						
						.table-row{
							display:table-row;
							
							
							.table-cell{
								display:table-cell;
								text-align:left;
								vertical-align:middle;
								
								&.icons{color:$brand-primary;font-weight:bold;width:25px;}
								
								&.headline{font-weight:bold;color:$black}
							}
						}
					}
					
					.text{
						display:none;
						padding:0 15px 15px 15px;
						@media (max-width: $doubleTeaserBreakpoint) {padding:0 10px 10px 10px;}
					}
					
					&.active, &:hover{background-color:$white;}
				}//faqEntry
				
				
				
			}//faqEntriesContainer
		}//categoryContainer
		
		
		
	}//content
}//faq