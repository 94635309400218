.btn-primary{
  @include button-variant-true-background($btn-primary-color, $btn-primary-bg, $btn-primary-bg-hover, $btn-primary-border);
}
.btn-default {
  @include button-variant($btn-default-color, $btn-default-bg, $btn-default-border);
}
.btn-blue{
  @include button-variant($btn-blue-bg, $btn-blue-border);
}

.btn-orange {
  @include button-variant-true-background($btn-orange-color, $btn-orange-bg, $btn-orange-bg-hover, $btn-orange-border);
}
//Used to differentiate between hardcoded and alfresco orange buttons
//Do not use this class manually. It will only be used by custom alfresco buttons
.btn-orangeNormal {
  @include button-variant-true-background($btn-orange-color, $btn-orange-bg, $btn-orangeNormal-bg-hover, $btn-orange-border);
}
.btn-grey {
  @include button-variant($btn-grey-color, $btn-grey-bg, $btn-grey-border);
}
.btn-dis{
  @include button-variant($btn-dis-color, $btn-dis-bg, $btn-dis-border);
}
.btn-dis2{
  @include button-variant($btn-dis2-color, $btn-dis2-bg, $btn-dis2-border);
}
.btn-white{
  @include button-variant($btn-white-color, $btn-white-bg, $btn-white-border);
}
.btn-white-blue{
  @include button-variant($brand-primary, $btn-white-bg, $brand-primary);
}
.btn-blue-white{
  color: #1a5dae;background-color: #fff;border-color: #1a5dae;
}
.btn-green{
  @include button-variant-true-background($btn-green-color, $btn-green-bg, $btn-green-bg-hover, $btn-green-border);
}
.btn-orangeSpieleland{
  @include button-variant-true-background($btn-orangeSpieleland-color, $btn-orangeSpieleland-bg, $btn-orangeSpieleland-bg-hover, $btn-orangeSpieleland-border);
}
.btn-greenSpieleland{
  @include button-variant-true-background($btn-greenSpieleland-color, $btn-greenSpieleland-bg, $btn-greenSpieleland-bg-hover, $btn-greenSpieleland-border);
}
.btn-blueSpieleland{
  @include button-variant-true-background($btn-blueSpieleland-color, $btn-blueSpieleland-bg, $btn-blueSpieleland-bg-hover, $btn-blueSpieleland-border);
}
.btn-redSpieleland{
  @include button-variant-true-background($btn-redSpieleland-color, $btn-redSpieleland-bg, $btn-redSpieleland-bg-hover, $btn-redSpieleland-border);
}