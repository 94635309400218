@mixin desktopMobileSwitch() {
	&.showInMobile{display:none;}
	&.showInDesktop{display:block;}
	
	@media (max-width: $doubleTeaserBreakpoint) {
		&.showInMobile{display:block;}
		&.showInDesktop{display:none;}
	}
}

//big orange arrows
.arrowLeftBig{
	width:40px;
	margin:10px 15px 0 0;
	
	i{font-size:1.6rem;margin:8px 0 0 9px}
}

.arrowRightBig{
	width:40px;
	margin:10px 0 0 15px;
	
	i{font-size:1.6rem;margin:8px 0 0 14px}
}

.arrowRoundBackBig{
	width:40px;
	height:40px;
	-webkit-border-radius: 20px;
	-moz-border-radius: 20px;
	border-radius: 20px;
	background-color:$orange2;
	cursor:pointer;
	
	&:hover{background-color:$brand-secondary-hover}
	
	&.dis{
		background-color:$dis !important;
		cursor:auto !important;
	}
}


//small orange arrows
.arrowLeft, .arrowRight{
	width:30px;
	height:30px;
	
	.arrowRoundBack{
		width:30px;
		height:30px;
		-webkit-border-radius: 15px;
		-moz-border-radius: 15px;
		border-radius: 15px;
		background-color:$orange2;
		cursor:pointer;
		
		&:hover{background-color:$orange2-hover}
		
		&.dis{
			background-color:$dis !important;
			cursor:auto !important;
		}
	}
}
.arrowLeft{
	i{font-size:1.4rem;margin:5px 0 0 5px}
}

.arrowRight{
	i{font-size:1.4rem;margin:5px 0 0 9px}
}


.productDetail{

	max-width:1075px;
	margin:0 auto;
	padding:0 15px 15px 15px;

	h2, .bigHeadline {
		background-color: $light-grey;
		display: inline-block;
	}

	.showMobile{display:none}
	.showDesktop{display:block;}


	@media (max-width: $doubleTeaserBreakpoint) {
		width:515px;
		padding:0 0 15px 0;
		.showMobile{display:block}
		.showDesktop{display:none;}
	}
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		width:340px;
		padding:0 0 15px 0;
	}
	
	div.reviewLink, a.reviewLink{
		display:block;
		text-decoration:underline;
	}
	div.reviewLink{cursor:pointer;color:$brand-primary}
	
	.stars{
		.icon.star0, .icon.star1, .icon.star2{display:inline-block; margin-right:5px}
	}	
	

	.productImagePriceContainer{
		background-color:$white;
		padding:30px 0px;
		
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		
		@media (max-width: $doubleTeaserBreakpoint) {padding:30px 20px;}
		
		.productImageContainer, .productPriceContainer{
			padding:0 20px;
			width:515px;
			float:left;
			@media (max-width: $doubleTeaserBreakpoint) {
				float:none;
				width:100%;
				padding:0;
			}
		}
		.productImageContainer{
			width:530px;
			
			.imageTopTableOuterContainer{
			
				width:460px;
				height:360px;
				overflow:hidden;
				position:relative;
				margin-left:55px;
				
				.imageTopTableInnerContainer{
					width:20000em;
					height:360px;
					position:absolute;
					left:0;
					top:0;
				}
			
				.imageTopTableContainer{
					display:table;
					position:relative;
					height:360px;
					width:360px;
					float:left;
					
					.imageTopTableCell{
						display:table-cell;
						vertical-align:middle;
						text-align:center;
						
						img{
							max-width:360px;
							max-height:360px;
						}
						.ilightboxBigImage{
							.portrait{
								position:relative;
								left:53px;
								top:15px;
										    							
    							&.big{
    								left:0px;	
    							}
							}
							.landscape{
								position: relative;
    							top: 55px;
    						}
						}
					}//imageTopTableCell
					
					
				}//imageTopTableContainer
					
				
					
				.searchIconBigContainer{
					float:left;
					width:45px;
					height:360px;
					position:relative;
					margin-left:16px;
					margin-right:55px;
					
					a{
						width:45px;
						margin-bottom:5px;
						text-align:center;
					}
					
					a.searchIcon, a.searchIcon:hover{
						position:absolute;
						bottom:10px;
						left:0;
						display:block;
						text-decoration:none;
						text-shadow: 2px 2px 2px #fff;
						
						.fa-search-plus.fa-3x{font-size:1.8rem}	
					}
					
					.iconText{
						display:block;
						margin:auto;
						text-align: center;
						font-size: 8px;
						font-weight: 700;
					}
					
					.moreIconsAtTheTopContainer{
						position:absolute;
						top:0;
						left:0;
						width:35px;
						
						a{
							display:block;
							.fa-3x{font-size:1.8rem}	
						}
					}
				
				}//searchIconBigContainer
				
				
				
			}//imageTopTableOuterContainer
			
			.smallImageContainer{
				margin-top:15px;
				width:475px;
				height:60px;
				
				.arrowLeftBig{
					float:left;
				}
				
				.arrowRightBig{
					float:left;
				}
				
				.smallImagesOuterContainer{
					float:left;
					width:365px;
					overflow:hidden;
					position:relative;
					height:62px;
					
					.smallImagesInnerContainer{
						position:absolute;
						left:0;
						top:0;
						width:20000em;
						height:62px;
						
						//div:last-child{margin-right:0 !important;}
					}
					
					.smallImage{
						border:2px solid $light-grey;
						-webkit-border-radius: 4px;
						-moz-border-radius: 4px;
						border-radius: 4px;
						width:80px;
						height:62px;
						float:left;
						margin-right:15px;
						cursor:pointer;
						display:table;
						
						&.active{border-color:$orange2}
						&:hover{border-color:$orange2-hover}
						
						>div{
							display:table-cell;
							vertical-align:middle;
							text-align:center;
							
							img{
								max-width:72px;
								max-height:54px
							}
							.portrait{
								position:relative;
								left:7px;
								top:3px;
								
								&.big{
									left:0px;
								}
							}
							.landscape{
								position:relative;
								top:3px;
							}
						}
					}
				}//smallImages
			}//smallImageContainer
		}//productImageContainer
		
		.productPriceContainer{
			h1{
				font-size:1.625rem;
				font-weight:bold;
				@media (max-width: $doubleTeaserBreakpoint) {font-size:1.5rem;}	
			}
			div{
				a.category, a.category:hover{
					color:$font-color;
					font-size:0.875rem;
					display:block;
				}
				a.category:hover{color:$black;}
			}
			.starContainer{
				margin:20px 0 30px 0;
				.stars{float:left;}	
				.reviewsTotalNumber{float:left}
				div.reviewLink,a.reviewLink{
					float:left;
					margin-left:10px;
					@media (max-width: $doubleTeaserBreakpoint) {float:right}	
				}
			}
			
			.puzzleCustomizeContainer{
				border-top: 2px solid $light-grey;
				@media (max-width: $doubleTeaserBreakpoint){border:none;}
				
				.inputContainer{
					margin-top: 15px;
					
					.selectContainer{
						.selectMain{
							border:none;
							outline:none;
							user-select: none;
							cursor: pointer;
							padding-bottom:0;
						}
						.selectEntryContainer{
							top:40px;
							
							.selectEntry{
								width:475px;
								height:38.5px;
								@media (max-width: $doubleTeaserSecondBreakpoint){width:300px;}	
							}
						}
					}		
				}
				.smallImagesOuterContainer{
					margin:20px 0 5px 0;
					height:196px;
					@media (max-width: $doubleTeaserSecondBreakpoint){height:377px;}
										
					.smallImagesInnerContainer{
						div:nth-child(n+11) {
					 	display:none;
					 	}
					 		
						.smallImage{
							display:inline-block;
							float: left;
							height:83px;
							width:83px;
							margin-right: 15px;
							margin-bottom: 15px;
						    border: 2px solid $light-grey;
						    border-radius: 4px;
						    cursor: pointer;
						    text-align:center;
						    display: flex;
						    flex-direction: column;
						    align-items: center;
						    justify-content: space-around;

							&.last{
								margin-right:0;
								@media (max-width: $doubleTeaserSecondBreakpoint){margin-right:15px;}
							}
							&.active{
								border-color: $available-yellow;								
							}
						   	&.mobileFirst{
						   		margin-left:0;
							   	@media (max-width: $doubleTeaserSecondBreakpoint){margin-left:10.5px;}					
							}
							&.mobileLast{
							   	@media (max-width: $doubleTeaserSecondBreakpoint){margin-right:0;}					
							}
							img{
								padding-top:7px;
								padding-left: 3px;
    							padding-right: 3px;
						    	max-height:79px;
						    	max-width:79px;
						    }
						}
					}	
				}
			}
		
			.puzzlePointContainer{
				border-top:$border-light-grey-2px;
				border-bottom:$border-light-grey-2px;
				padding:20px 0;
				
				a.puzzlePointsCircle, a.puzzlePointsCircle:hover{
					display:block;
					text-decoration:none;
					color:$white;
				}
				
				&.showMobile{border-top:0 none;}
			}
			
			.puzzleBallOrderDateContainer{
				border-bottom:$border-light-grey-2px;
				padding:10px 0;
			}
			
			.mobileSmallImageContainer{
				display:none;
				@media (max-width: $doubleTeaserBreakpoint) {display:block}	
				border-bottom:$border-light-grey-2px;
				padding-bottom:30px;
				position:relative;
				
				.imageOuterContainer{
					position:relative;
					height:240px;
					width:475px; //515-40 padding from container
					@media (max-width: $doubleTeaserSecondBreakpoint) {width:300px} //340-40 padding from container
					overflow:hidden;
					
					.imageInnerContainer{
						width:20000em;
						position:absolute;
						left:0;
						top:0;
						
						.tableContainer{
							display:table;
							
							.tableCell{
								display:table-cell;
								position:relative;
								vertical-align:middle;
								text-align:center;
								width:475px;
								@media (max-width: $doubleTeaserSecondBreakpoint) {width:300px}
								img{
									max-height:240px;
									max-width:240px;
								}
								.ilightboxMobile{
									.portrait{
										position:relative;
										left:39px;
										top:5px;
										
										&.big{
											left:0px;	
										}
									}
									.landscape{
										position:relative;
										top:62px;
										
										&.big{
											top:55px;	
										}
									}
								}
							}//tableCell
						}//tableContainer
					}//imageInnerContainer
				}//imageOuterContainer
				
				.arrowLeft, .arrowRight{
					position:absolute;
					top:105px;
				}
				.arrowLeft{
					left:-25px;
				}
				
				.arrowRight{
					right:-25px;
				}
				
				.dots-vertical-container.orange{
					width:372px;
					margin:0 auto;
					@media (max-width: $doubleTeaserSecondBreakpoint) {width:201px;}
				}
				
				.searchIconContainer{
					/*
					position:absolute;
					bottom:30px;
					right:0;
					*/
					text-align:center;
					
					&>a{
						display:inline-block;
						.fa-search-plus.fa-3x{font-size:1.8rem}	
					}
				}
				
			}//mobileSmallImageContainer
			
			.priceContainer{
				border-bottom:$border-light-grey-2px;
				padding:20px 0;
				font-size:0.75rem;
				
				.oldPrice{
					color:$dark-grey;
					font-size:1rem;
					text-decoration:line-through;
					white-space:nowrap;
				}
				.newPrice{
					font-weight:bold;
					color:$black;
					font-size:1.5rem;
					white-space:nowrap;
					&.orange{
						color:$orange4;
						
						.infoFamilyAndFriends{font-size: 0.875rem; margin-left: -5px; vertical-align:middle;}
					}
				}
				a{text-decoration:underline}
			}//priceContainer
			
			.sellerContainer{
				padding:20px 0;
				border-bottom:$border-light-grey-2px;
				font-size:0.75rem;
				
				div{white-space:nowrap}
				div:first-child{font-weight:bold;float:left}
				div:last-child{
					float:right;
					@media (max-width: $doubleTeaserSecondBreakpoint) {float:left}
				}
			}
			
			.buttonContainer{
				padding:20px 0;
				border-bottom:$border-light-grey-2px;
				font-size:0.875;
				font-weight:bold;
				
				.hideWishlistButton{
					height:1px;
					
					.btn{
						display:none;
					}
				
				}
				
				&.showMobile{
					.hideWishlistButton{
						display:none;
					}
					
					>div{margin-bottom:20px;}
					>div:last-child{margin-bottom:0px;}
				}
			}
			
			.iconContainer{
				padding-top:20px;
			}
		}//productPriceContainer
	}//productImagePriceContainer
	
	.editorial {
		margin-top: 55px;
		
		@media (max-width: $doubleTeaserSecondBreakpoint) {margin-top: 10px;}
		.content .innerTableContainer{padding:0 !important}
	}

}//productDetail


.additionalMarginInMobile{
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		padding:100px 0 15px 0;
	}
}

#moreInfos{
	position:relative;
	left:0;
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		position:absolute;left:-9999px;
	}
}

#moreInfosSmall{
	position:absolute;left:-9999px;
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		position:relative;
		left:0;
	}	
}

#moreInfosSpieleanleitungenSmall {
	display: none;
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		display: block;
	}
}

.rasterContainerProductDetail{
	width:340px;
	@media (min-width: $doubleTeaserSecondBreakpoint + 1) {
		width:515px;
	}
	@media (min-width: $doubleTeaserBreakpoint + 1) {
		width:1045px;	
	}

	
	.container_2_1{
		width:515px;
		height:250px;
		position:absolute;
		padding:15px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:340px;
			height:185px;
			padding:10px;
			position:static;
			margin-top:20px;
		}
	}
	
	.container_2_2{
		width:515px;
		height:515px;	
		position:absolute;
		padding:15px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:340px;
			padding:10px;
		}
	}
	
	.container_1_1{
		width:250px;
		height:250px;
		position:absolute;
		padding:15px;
		
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			width:165px;
			height:165px;
			padding:10px;
			display: inline-block;
			margin-top: 20px;	
		}
	}
	
	.gameManuals{
		font-size:1rem;
		@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:0.875rem}
		.headline{
			font-weight:bold;
			font-size:1.125rem;
			@media (max-width: $doubleTeaserSecondBreakpoint) {font-size:1rem;}
		}
	}
	
	.variableHeight{
		height: auto;
	}
}
	
.whiteBox20_3 .authorDetailContainer{

	.singleTeaser_main {
	 @media (min-width: $doubleTeaserSecondBreakpoint) {float:left;}
	 
		img.author{
			margin: 5px 45px 5px 0;
			@media (min-width: $doubleTeaserBreakpoint) {width: 200px;}
			@media (max-width: $doubleTeaserBreakpoint) {max-width: 475px;}
			@media (max-width: $doubleTeaserSecondBreakpoint) {max-width: 300px;}
		} 
		.copyright_photo{
			margin-bottom: 5px;	
			font-size: 14px;
			@media (min-width: $doubleTeaserBreakpoint) {max-width: 200px;}
		}
	}
	 
	.element{
		font-size: .875rem;
		font-weight: 700;
	 	
	 	.authorRight {
		    transform: rotate(-45deg);
		    margin-bottom: 2px;
		}

		i {
			  border: solid;
			  border-width: 0 2.5px 2.5px 0;
			  display: inline-block;
			  padding: 2.5px;
		}
	}
	
	.textContainer{
		
		.less {
			@media (min-width: 120px) {display: none;}
			@media (min-width: $doubleTeaserBreakpoint) {display: block;}
		}
		
		.more {
			@media (min-width: 120px) {display: block;}		
			@media (min-width: $doubleTeaserBreakpoint) {display: none;}
			
			.morecontent span {
				display: none;
			}
			
			.morelink {
		    	display: block;
			}
	 	}	
	}	
	
	.morePressText {
		@media (min-width: 120px) {display: block;}
		@media (min-width: $doubleTeaserBreakpoint) {display: none;}	
	}
	
}	

.description, #reviews, .moreProductsOuterContainer{

	max-width:1075px;
	margin:0 auto;
	//padding:0 15px 15px 15px;
	
	@media (max-width: $doubleTeaserBreakpoint) {
		width:515px;
		//padding:0 0 15px 0;
	}
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		width:340px;
		//padding:0 0 15px 0;
	}


	h2{
		font-size:1.625rem;
		font-weight:bold;
		margin-top:55px;
		@media (max-width: $doubleTeaserSecondBreakpoint) {margin-top:30px;}
	}
	
	.descContainer, .reviewsContainer{
		background-color:$white;
		padding:30px 20px;
		
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		
		font-size:0.875rem;
		
		h3{
			font-size:1rem;
			font-weight:bold;
			margin:25px 0 10px 0;
		}
		
		.leadIn{font-size:1.125rem;}
		
		.specificationContainer{
			margin-top:20px;
			a{
				color:$font-color;
				text-decoration:underline;
				&:hover{color:$brand-primary}	
			}
			
		}
	}//descContainer
	
}//description


.moreProductsOuterContainer{
	.moreProductsContainer{
		background-color:$white;
		-webkit-border-radius: 3px;
		-moz-border-radius: 3px;
		border-radius: 3px;
		font-size:0.875rem;
		font-weight:bold;
		
		
		
		.bigContainer, .middleContainer, .smallContainer{
			position:relative;
			div.outerContainer{height:250px;overflow:hidden;position:relative}
			display:none;
			
			
			.reducedPrice{
				font-weight:normal;
				text-decoration:line-through;
				color:$dark-grey;
			}
			
			.innerContainer{
				position:absolute;
				left:0;
				top:30px;
				height:250px;
				width:20000em;
				//margin-left:1px;
				
				
				.elementTable{
					display:table;
					
					.elementRow{
						display:table-row;
						
						&.linkText a{
							height:70px;
							width:145px;
							display:block;
							margin-top:25px;
						}
						
						.element{
							width:145px;
							padding-right:10px;
							display:table-cell;
							text-align:center;
							
							img{
								max-width:145px;
								max-height:145px;
							}
						}
					}
				}
				
			}//innerContainer
		}//.bigContainer, .middleContainer, .smallContainer
		
		.bigContainer{
			div.outerContainer{height:200px;}
			&.recommendationContainer{height:515px;}
			padding-bottom:10px;
			
			.innerContainer{
				top:0px;
				
				.elementTable{
					.elementRow{
					
						&.image{.element{padding-top:15px;}}
					
						&.linkText a{
							height:50px;
							width:210px;
							display:block;
							margin-top:15px;
						}
					
						.element{
							width:265px;
							padding:0 35px 0 20px;							
							
							img{
								max-width:120px;
								max-height:120px;
							}
							
						}//element
					}//elementRow
				}//elementTable
			}//innerContainer
			
			.arrowLeft, .arrowRight{
				position:absolute;
				top:103px;
			}
			.arrowLeft{left:-10px;}
			
			.arrowRight{right:-10px;}
			
		}//bigContainer
		
		.middleContainer{
			div.outerContainer{height:200px;}
			&.recommendationContainer{height:515px;}
			padding-bottom:10px;
			
			.innerContainer{
				top:0px;
			
				.elementTable{
					.elementRow{
					
						&.image{.element{padding-top:15px;}}
					
						&.linkText a{
							height:50px;
							width:210px;
							display:block;
							margin-top:15px;
						}
					
						.element{
							width:265px;
							padding:0 35px 0 20px;
							
							img{
								max-width:120px;
								max-height:120px;
							}
						}//element
					}//elementRow
				}//elementTable
			}//innerContainer
			
			
			.arrowLeft, .arrowRight{
				position:absolute;
				top:103px;
			}
			.arrowLeft{left:-10px;}
			
			.arrowRight{right:-10px;}
			
			
		}//middleContainer
		
		.smallContainer{
			width:300px;
			margin:0 20px;
			//padding-top:30px;
			padding-bottom:10px;
			
			.arrowLeft, .arrowRight{
				position:absolute;
				top:128px;
			}
			.arrowLeft{left:-25px;}
			
			.arrowRight{right:-25px;}
		}//smallContainer
		
		
		
		//show hide blocks
		.bigContainer{display:block;}
		@media (max-width: $doubleTeaserBreakpoint) {
			.bigContainer{display:none;}
			.middleContainer{display:block;}
		}
		@media (max-width: $doubleTeaserSecondBreakpoint) {
			.middleContainer{display:none;}
			.smallContainer{display:block;}
		}
		
		
	}//
}//moreProductsOuterContainer

#showCharacteristicsInMobile{
	display:none;
	
	width:340px;

	@media (min-width: $doubleTeaserSecondBreakpoint + 1) {
		width:515px;
	}
	@media (min-width: $doubleTeaserBreakpoint + 1) {
		width:1045px;	
	}
	
	@media (max-width: $doubleTeaserSecondBreakpoint) {display:block}
	
	>div{//overwrite normal behavior because this box is not in the tiles container
		height:auto;
		position:relative;
		left:0;
		top:0;
	}
}


#reviews{
	margin:0 auto 20px;
	
	.reviewsContainer{
		h3{font-weight:0.875rem;}
		
		.averageHeadline{
			float:left;
			font-weight:bold;
			margin-right:10px;
			@media (max-width: $doubleTeaserBreakpoint) {
				float:none;
				margin-right:0;
			}
		}
		
		.stars, .reviewsTotalNumber{
			float:left;
			margin-right:5px;
		}
		
		div.reviewLink, a.reviewLink{
			float:right;
		}	
		
		.barOuterContainer{
			border-bottom:$border-light-grey-2px;
			padding-bottom:30px;
		
			.barContainer{
				display:table;
				margin-top:30px;
				
				
				.barRow{
					display:table-row;
					height:25px;
					
					.barFirstCell, .barSecondCell, .barThirdCell{
						display:table-cell;
						vertical-align:middle;
					}
					
					.barFirstCell{
						width:70px;
						a{text-decoration:underline;color:$black;}	
					}
					
					.barSecondCell{
						width:160px;
						
						.graphContainer{
							height:20px;
							width:160px;
							overflow:hidden;
							-webkit-border-radius: 5px;
							-moz-border-radius: 5px;
							border-radius: 5px;
							@include gradient-y-three-colors(rgba(215,215,215,1),rgba(231,231,231,1), 50%,rgba(229,229,229,1));
							box-shadow:inset 0 3px 5px rgba(0,0,0,.15);
							
							.graphInnerContainer{
								height:20px;
								-webkit-border-radius: 5px;
								-moz-border-radius: 5px;
								border-radius: 5px;
								@include gradient-x(rgba(26,92,171,1),rgba(24,86,161,1));
								width:0;	
								-webkit-transition: width 0.5s ease 0s;
								-moz-transition: width 0.5s ease 0s;
								-o-transition: width 0.5s ease 0s;
								transition: width 0.5s ease 0s;
							}
						}	
					}
					
					.barThirdCell{
						width:40px;
						text-align:right;
						a{color:$black;}
						a:hover{text-decoration:none}
					}
				}
			}//barContainer
		}//barOuterContainer

		.reviewOuterContainer{
			border-bottom:$border-light-grey-2px;
			padding:20px 0;
			
			.helpFullTextContainer{
				color:$dark-grey;
				@media (max-width: $doubleTeaserBreakpoint) {margin-bottom:5px;}
			}
			
			.icon.arrowColoredDown, .icon.arrowColoredUp{
				float:right;
				@include desktopMobileSwitch();
			}
			.icon.arrowColoredDown{
				display:none;
				@include desktopMobileSwitch();
			}
			
			.reviewHeadline{
				font-weight:bold;
				margin-bottom:12px;	
			}
			.userInformation{}
			
			.reviewInnerContainer{
				padding-top:15px;
				display:none;				
				
				.reviewQuestion{margin:30px 0 15px;}
				
				.thankYou{
					display:none;
					color:$brand-primary;
				}
				@include desktopMobileSwitch();
			}
		}//reviewOuterContainer
		
		.moreReviewButtonContainer{
			padding-top:30px;
			text-align:center;
			.btn{font-size:0.875rem;}
		}
		
		.reviewSelector{
			float: right;
			margin-top: -212px;
			@media (max-width: $doubleTeaserBreakpoint) {
				float:none;
				margin-top:0;
				padding: 20px 0;
				border-bottom: 2px solid $light-grey;
				.reviewLink{float:none;}			
			}	
			
			
		}//reviemSelector
	}//reviewsContainer
	.reviewsContainer, .whiteBox20_3{
		.reviewSelector{
			
			.btn{
				width:340px;
				font-size:.875rem;
				padding: 0.5rem 1rem;
				@media (max-width: $doubleTeaserBreakpoint) {
				width:100%;
				
				}
			}
			.reviewLink{
				text-decoration:none;
				color: $font-color;
				font-weight: bold;	
			}
			.selectContainer{
				width: 340px;
				margin-top:15px;
				border-radius: 5px;	
				cursor: pointer;
				background:$white;	
				border: $dis 1px solid;
				
				@media (max-width: $doubleTeaserBreakpoint) {
					font-size: $font-size-sm;
					width:100%;
				}		
				&.selection {
					font-style: normal;
					font-size: 1rem;
					color: $font-color;
					.selectMain{padding: .5em 3em .5em 1em;}
				} 
				.selectMain{padding: .6em 3em .6em 1em;}					
				.selectOpener{background-image: $icon-selectClose;}
				.selectCloser{background-image: $icon-select;}
				.selectCloser, .selectOpener { 
					width: 34px;
					background-position: center right 6px;
					background-size: 27px 12px;
				}
				.selectEntryContainer{
					width: 100% !important;
					font-style: normal;
					font-size: 1rem;
					top:39px;
					color: $font-color;
					border: none;
					outline: none;
					border: $dis 1px solid;
					.selectEntry:hover,.selectEntry.active {color:$font-color;}
				}
									
			}
		}		
	}
	.whiteBox20_3 .reviewSelector {
	border-top: 2px solid $light-grey;	
			padding: 15px 0;
			margin-top:15px;
				
	}
}//reviews


.ilightbox-holder.dark {
	.ilightbox-container {
		iframe{
			width: 360px;
			height: 360px;
			margin-left: calc(50% - 177px);
			position: absolute;
			top: calc(50% - 177px);
		}
	}
}

