.newsletter{
	
	.shortenedContent{
		max-width:550px;
	}//shortenedContent
	
	.flagBig{
		display:block;
		position:absolute;
		left:710px;
		top:-20px;
		@media (max-width: $doubleTeaserBreakpoint) {display:none}
	}
	
	.flagSmall{
		text-align:center;
		margin:10px 0;
		display:none;
		img {width:100px;}
		@media (max-width: $doubleTeaserBreakpoint) {display:block}
	}
	
}//newsletter

.singleColumnForm{max-width:475px}