

.iconCharacters { 
	background: url('/images/spriteCharacter.png'); 
	background-size: 360px 180px;   //if you change the values also change them at the end of the file
}

.iconCharacters.iconCharacter{
	width:60px;
	height:60px;
	margin:0 auto;
	margin-bottom:10px;
}

.iconCharacters.iconCharacter.author{background-position:0 0;}
.iconCharacters.iconCharacter.genre{background-position:-60px 0;}
.iconCharacters.iconCharacter.numberOfPlayers{background-position:-120px 0;}
.iconCharacters.iconCharacter.age{background-position:-180px 0;}
.iconCharacters.iconCharacter.numberOfPages{background-position:-240px 0;}
.iconCharacters.iconCharacter.cover{background-position:-300px 0;}

.iconCharacters.iconCharacter.puzzleContourCut{background-position:0px -60px;}
.iconCharacters.iconCharacter.specialSerie{background-position:-60px -60px;}
.iconCharacters.iconCharacter.size{background-position:-120px -60px;}
.iconCharacters.iconCharacter.numberOfPieces{background-position:-180px -60px;}
.iconCharacters.iconCharacter.playingTime{background-position:-240px -60px;}
.iconCharacters.iconCharacter.puzzleClassical{background-position:-300px -60px;}

.iconCharacters.iconCharacter.levelOfDifficulty{background-position:0px -120px;}
.iconCharacters.iconCharacter.gsLogo{background-position:-60px -120px;}
.iconCharacters.iconCharacter.box{background-position:-120px -120px;}


@media only screen and (-webkit-min-device-pixel-ratio: 2) { 
  .iconCharacters { 
    background: url('/images/spriteCharacter@2x.png'); 
    background-size: 360px 180px; 
  } 
}