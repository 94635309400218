#breadcrumbOuterContainer{
	//special behaviour on themenspecial
	&.themenspecial{
		position:absolute;
		left:0;
		top:95px;
		z-index:1;
		@include media-breakpoint-down(xs) {
		  top:70px !important;
		  right:0;
		}
	}


	#breadcrumb{
		margin:0 auto;
		margin-bottom:15px;
		
		
		@include media-breakpoint-down(xs) {
		  width:340px;
		}
		
		
		/*
		width:340px;
		
		@media (min-width: 535px) {
			width:515px;
		}
		@media (min-width: 820px) {
			width:780px;
		}
		@media (min-width: 1085px) {
			width:1045px;	
		}
		@media (min-width: 1350px) {
			width:1310px;	
		}
		@media (min-width: 1615px) {
			width:1575px;	
		}
		@media (min-width: 1880px) {
			width:1840px;	
		}
		
		@media (min-width: 2145px) {
			width:2105px;	
		}
		@media (min-width: 2410px) {
			width:2370px;	
		}
		@media (min-width: 2675px) {
			width:2635px;	
		}
		*/
		
		.breadcrumbContainer{
			background-color:$white;
			-webkit-border-radius: 3px;
			-moz-border-radius: 3px;
			border-radius: 3px;
			padding:7px 10px;
			font-size:0.75rem;
			color:$font-color;
			display:inline-block;
			margin-left:10px;
			@include media-breakpoint-down(xs) {margin-left:0}
			
			a, a:hover{
				text-decoration:none;
				font-weight:bold;
			}
			
			.gt{
				display:inline-block;
				margin:auto 7px;
				font-weight:bold;
			}
			
			//show only 2 last entries on mobile
			span{		
				display:inline-block;
				@include media-breakpoint-down(xs) {
					display:none !important;
					&.showSmall{display:inline-block !important;}
				}
			
				a{
					&.hidePuzzleShelfLink{
        				pointer-events: none;
        				color:$font-color;
        				font-weight:400;
					}
				}
			}		
		}//breadcrumbContainer
	}//breadcrumb
}//breadcrumbOuterContainer