.checkoutContainer{
	max-width:1045px;
	margin:0 auto;
	font-size:0.875rem;
	
	h1{
		font-weight:bold;
		font-size:1.625rem;
		@media (max-width: $tileBreakpoint) {font-size:1rem}
		background-color: $light-grey;
		display: inline-block;
	}

	.whiteBox, .whiteBox20_3 {
		h1 {
			background-color: white;
		}
	}

	@media (max-width: $doubleTeaserBreakpoint) {width:515px;}
	
	@media (max-width: $tileBreakpoint) {width:340px;}
	
	.table{
		margin-bottom:3px;
		.table{
			background-color:transparent;
			a.filterBoxSmallContainer{
				display:inline-block;
				text-decoration:none !important;
				line-height:1rem;
				
				&:hover .filterBoxSmall, &.active .filterBoxSmall{
					border-color:$brand-primary;
					text-decoration:none !important;
				}
				
				&:hover i, &:hover .smallText, &.active i, &.active .smallText{
					color:$brand-primary;
				}
				
				
				
				.smallText{
					font-size:0.625rem;
					text-decoration:none !important;
					color:$checkout-text-grey;
				}
			
			}// a
			
			div.filterBoxSmallContainer{
				display:inline-block;
				line-height:1rem;
				
				&.passed .filterBoxSmall{border-color:$brand-primary;}
				&.active .blueBackgroundNumber{background-color:$brand-primary;}
				
			    &.active i, &.active .smallText{color:$brand-primary;}
					
				
				.smallText{
					font-size:0.625rem;
					text-decoration:none !important;
					color:$font-color;
					background: $light-grey;
				}
			
			}// a
			
		}
		display:table;
		
		.table-row{
			display:table-row;
			
			.table-cell{
				display:table-cell;
			}
		}
	}
	
	.topNavigationDesktop, .loginDesktopContainer, .descriptionTop{
		display:block;
		@media (max-width: $doubleTeaserBreakpoint) {display:none}
	}
	
	.topNavigation, .loginContainer{
		display:none;
		@media (max-width: $doubleTeaserBreakpoint) {display:block}
	}
	
	
	.filterBoxContainer{
		font-weight:normal;
		
		a.filterbox:hover .blueBackgroundNumber, div.filterbox.active .blueBackgroundNumber{background-color:$brand-primary}
	}
	
	
	
	.loginDesktopContainer{
		font-size:0.875rem;
		//line-height:1rem;
	
		.leftColumn{
			width:515px;
			margin-right:15px;
			float:left;
		}//leftColumn
		
		.rightColumn{
			float:left;
			width:515px;
		}//rightColoumn
	}//loginDesktopContainer
	
//-----------------------------------------------------------------------------------------
	
	.checkoutAddress, .checkoutPaying, .checkoutSummary{
	
	
		.whiteBox20_3_top.third{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
		}
		
		.whiteBox20_3_bottom.first{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
		}
	
		.whiteBox>div{padding:0 20px;}
		
		
		.table{
			.table-row{
				.table-cell.first, .table-cell.third{width:515px}
				.table-cell.second{width:15px;}
			}
		}
		
		@media (max-width: $doubleTeaserBreakpoint) {
			.table.outerTableContainer{
				display:block;
				.table-row{
					display:block;
					.table-cell{
						display:block;
						width:100%;
						&.second{display:none}
					} 
				}
			}
		}
		
		
		
		.inputContainer{margin-top:15px;}
		.inputContainerWithoutHeadline{margin-top:15px;}
		
		.descriptionBottom{
			margin:15px 0;
		}
	
	
	}//checkoutAddress checkoutPaying checkoutSummary
	
	
	
	.checkoutAddress{
	
		.addressHeadline{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			font-weight:bold;
			color:$font-color;
			font-size:1rem;
			padding-bottom:10px;
			
			hr{
				margin:0;
				//padding:20px 0 10px 0; 
			}
		}
	
		
		
		.descriptionTop{
			margin:15px 0;
			margin-top:10px;
			font-weight:bold;
		}
		
		.firstname{
			float:left;
			width:230px;
			margin-right:15px;
			@media (max-width: $tileBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.lastname{
			float:right;
			width:230px;
			@media (max-width: $tileBreakpoint) {
				width:100%
			}
		}
		
		.street{
			float:left;
			width:330px;
			margin-right:15px;
			@media (max-width: $tileBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.hnr{
			float:right;
			width:130px;
			@media (max-width: $tileBreakpoint) {
				width:100%
			}
		}
		
		.plz{
			float:left;
			width:100px;
			margin-right:15px;
			@media (max-width: $tileBreakpoint) {
				margin-right:0;
				width:100%
			}
		}
		
		.city{
			float:right;
			width:360px;
			@media (max-width: $tileBreakpoint) {
				width:100%
			}
		}
		
		.geb{
			float:left;
			width:100px;
			margin-right:15px;
			@media (max-width: $tileBreakpoint) {
				width:85px;
			}
		}
						
		.selectContainer{
			@media (min-width: 535px) {
		    	width: 475px;
		  	}  
		}
		
		.deliveryAddressContainer{
            select:disabled{
            	background-image:url('/images/select-gray.png');
            	color: #a1a0a0;
            }
        }        
		
	
	}//checkoutAddress
	
	
	.checkoutPaying{
	
		hr{margin:11px 0}
	
		.whiteBox20_3_bottom.smallview,.whiteBox20_3_top.smallview{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
		}
		.whiteBox20_3_bottom.smallview{margin-bottom:15px}
	
		.table.description{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
		}
		
		.paymentHeadline{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			font-weight:bold;
			font-size:1rem;
			padding-bottom:10px;
		}
		
		.paymentDescription{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			font-weight:bold;
			padding-bottom:10px;
		}
		
		.radioCheckContainer{
			label{width:100%}
			span.firstline, span.secondline, span.image{display:block}
			span.secondline{float:left;}
			span.image{float:right}
		}
		
		.voucherContainer{
			.voucherInputContainer{
				float:left;
				width:200px;
				margin-right:10px;
				@media (max-width: $tileBreakpoint) {width:100px;}
				
				input{
					height:38px;
					width: 190px;
					}
			}
			.voucherButtonContainer{
				float:left;
				width:150px;
				@media (max-width: 534px) {float:none;}
			}
		}
		.hideSmall{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
		}
		
		.showSmall{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
		}
		.productContainer{
		
			hr.long{padding-bottom:10px;}
			.table{
				.table-row{
					&.products{
						display:block;
						@media (max-width: $doubleTeaserBreakpoint) {display:table-row;}
						
						.product-wrapper{display:table-row;}
					}
					.table-cell{
						&.image{
							width:140px;
							@media (max-width: $doubleTeaserBreakpoint) {display:table-cell;}
							
							img{vertical-align:top;}
						}
						&.desc{width:auto;}
						&.price{
							display:table-cell;width:275px;padding-right:160px;text-align:right;
							@media (max-width: $doubleTeaserBreakpoint) {display:none}
						}
						&.number{
							display:table-cell;width:60px;text-align:center;
							@media (max-width: $doubleTeaserBreakpoint) {display:none}
						}
						&.totalPrice{
							display:block;width:auto;text-align:right;
						}
						&.alignDescLeft{
							width:auto; 
							float:left;
							@media (max-width: $tileBreakpoint) {width:173px;}
						}
						&.alignPriceRight{width:auto; float:right;}
						
					}//table-cell
				}//table-row
			}//table
		}//productContainer
				
	}//checkoutPaying
	
	
	.checkoutSummary{
		hr.first{
			display:none;
			margin:0;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
		}
	
		.content{
			@media (max-width: $doubleTeaserBreakpoint) {padding:15px 0;}
		}
	
		.table{
			margin-bottom:1rem;
			@media (max-width: $doubleTeaserBreakpoint) {margin-bottom:0}
		}
		
		
		//just for rvFR
		&.checkoutSummarySecond{
			
			.content{
				@media (max-width: $doubleTeaserBreakpoint) {padding:0px;}
			}
			.table.description{
				display:block;
				@media (max-width: $doubleTeaserBreakpoint) {display:none}
			}
			.whiteBox20_3_bottom.smallview,.whiteBox20_3_top.smallview{
				display:none;
				@media (max-width: $doubleTeaserBreakpoint) {display:block}
			}
			.whiteBox20_3_bottom.smallview{margin-bottom:15px}
			
			.productContainer{
				hr.long{margin: 11px 0px;}
				.table{
					.table-row{
						&.products{
							display:block;
							@media (max-width: $doubleTeaserBreakpoint) {display:table-row;}
							
							.product-wrapper{display:table-row;}
						}
						
						.table-cell{
							&.image{
								@media (max-width: $doubleTeaserBreakpoint) {display:table-cell;}
								
								img{vertical-align:top;}
							}
							&.desc{width:auto;}
							&.totalPrice{width:auto; display:block;}
							&.totalPriceWide{text-align:left;}
							&.headline{font-weight:normal;}
							&.alignDescLeft{
								width:auto; 
								float:left;
								@media (max-width: $tileBreakpoint) {width:173px;}
							}
							&.alignPriceRight{width:auto; float:right;}
						}//table-cell
					}//table-row
				}//table
			
			}//productContainer
		}
		
		.table-row.bigview{
			display:table-row;
			@media (max-width: $doubleTeaserBreakpoint) {display:none !important}
		}
		
		.productContainer{
			.whiteBox20_3_top{
				display:block;
				@media (max-width: $doubleTeaserBreakpoint) {display:none}
			}
			
			hr{margin:0;padding-bottom:10px;margin-top:13px;}
			
			hr.first{@media (max-width: $doubleTeaserBreakpoint) {margin:0;}}
		
			>.whiteBox{
				@media (max-width: $doubleTeaserBreakpoint) {padding-top:13px;}
			}
		
		
			.table{
				margin-bottom:0;
				.table-row{
					.table-cell{
						&.image{width:140px;}
						&.desc{width:300px;}
						&.price{
							display:table-cell;width:275px;padding-right:160px;text-align:right;
							@media (max-width: $doubleTeaserBreakpoint) {display:none}
						}
						&.number{
							display:table-cell;width:60px;text-align:center;
							@media (max-width: $doubleTeaserBreakpoint) {display:none}
						}
						&.totalPrice{
							display:table-cell;width:230px;text-align:right;
							@media (max-width: $doubleTeaserBreakpoint) {display:none}
						}
						
						&.text{
							width:755px;padding-left:555px;
							@media (max-width: $doubleTeaserBreakpoint) {padding-left:0;width:200px;}
						}
						&.totalPriceWide{width:150px;text-align:right}
						
						&.headline{font-weight:bold;}
						
					}//table-cell
				}//table-row
			}//table
			
			hr.short{
				margin-left:555px;
				@media (max-width: $doubleTeaserBreakpoint) {margin-left:0}
			}
			hr.long{margin: 11px 0px;}
		
		
		}//productContainer
		
		.hideSmall{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
		}
		
		.showSmall{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
		}
		
		.radioCheckContainer{
			label{width:100%}
			span.firstline, span.secondline, span.image{display:block}
			span.secondline{float:left;}
			span.image{float:right}
		}
		
	}//checkoutSummary
	
	.thankyou{
		margin-bottom:20px;
		h1.first{
			text-align:center;margin:40px 0;
			@media (max-width: $doubleTeaserBreakpoint) {margin:20px 0}
		}
	}
	
	
	.shoppingCart{
		
		.whiteBox{padding:0 20px;}
	
		.oldPrice{
			color:$dark-grey;
			text-decoration:line-through;
			white-space:nowrap;
		}
		.newPrice{
			font-weight:bold;
			color:$black;
			font-size:1rem;
			white-space:nowrap;
			&.orange{color:$orange4}
		}
	
		.bigView{
			display:block;
			@media (max-width: $doubleTeaserBreakpoint) {display:none}
			
			.table{
				margin-bottom:0;
				
				&.headline, &.entry{
					width:100%;
					table-layout:fixed;
					.table-cell{
						&.article{
							width:40%;
							text-align:left;
						}
						&.price, &.amount, &.priceAll{
							width:20%;
							text-align:center;
							vertical-align:middle;
						}
						
						&.priceAll{text-align:right;font-weight:bold}
					}
				}
				&.headline .table-cell.article{text-align:center}
			}
		}//bigView
		
		.smallView{
			display:none;
			@media (max-width: $doubleTeaserBreakpoint) {display:block}
			
			.productSmallContainer{
				.imageContainer{
					width:125px;
					padding-right:10px;
					float:left;
				}
				
				.textContainer{
					width:calc(100% - 125px);
					float:left;
				}
			}//productSmallContainer
		}//smallView
		
		
		.voucherContainer{
			.iconContainer{
				float:left;
				@media (max-width: $doubleTeaserBreakpoint) {display:none}
				width:125px;
			}
			
			.desciptionBox{
				float:left;
				width:400px;
				@media (max-width: $tileBreakpoint) {position:initial !important}			
				@media (max-width: $doubleTeaserBreakpoint) {float:none;width:auto;margin-top: 12px;position:absolute}
				.headline{
					font-weight:bold;
					padding-top:10px;
					@media (max-width: $doubleTeaserBreakpoint) {padding-top:0;}
				}
				.desc{
					display:none;
					@media (max-width: $doubleTeaserBreakpoint) {display:block}
				}
			}
			
			.buttons{
				float:left;
				width:480px;
				text-align:right;
				@media (max-width: $doubleTeaserBreakpoint) {float:none;width:100%;}
				#voucherCode{
					width:230px;
					@media (max-width: $tileBreakpoint) {width:193px;}
				}
			}
		}//voucherContainer
		
		.vouchers{
			padding:10px 0;
		
			.amount{
				float:right;
				width:105px;
				font-weight:bold;
				text-align:right;
				padding-top:5px;
			}
			
			.description{
				float:right;
				font-weight:bold;
				color:$brand-primary;
				text-align:right;
				width:calc(100% - 105px);
			}
		}//vouchers
		
		
		.sumContainer{
			.sumDescription{
				float:right;
				padding-right:20px;
				@media (max-width: $doubleTeaserBreakpoint) {float:left;}
			}
			
			.sum1,.sum2{float:right}
			.sum1{display:block;@media (max-width: $doubleTeaserBreakpoint) {display:none;}}
			.sum2{display:none;@media (max-width: $doubleTeaserBreakpoint) {display:block;}}
			.mwst{float:left}
			.mwstLong{float:right;text-align:right;padding-top:5px}
		}//sumContainer
		
		
		.textOder{
			text-align: right;
			margin: 10px 0px 10px 15px;
			max-width: 45px;
			float: right;
			@media (max-width: $tileBreakpoint) {margin-top:5px;margin-bottom:0px;}
		}
		
	}//shoppingCart
	
	
	.giftVoucherContainer{
		.chooseKindOfVoucher{
			.descriptionContainer{
				text-align:center;
				margin-bottom:50px;
				@media (max-width: $doubleTeaserBreakpoint) {
					text-align:left;
					margin-bottom:20px;	
				}
			}
			
			@media (max-width: $doubleTeaserBreakpoint) {
				.table-cell{
					display:block;
					margin-bottom:20px;
				}
			}
			
			.radioCheckContainer{
				text-align:left;
				width:400px;
				margin:0 auto;
				@media (max-width: $doubleTeaserBreakpoint) {
					width:100%;
					margin:0;
				}
				span{
					display:block;
				}
			}
			
			
			.buttonRight{
				float:right;
				margin-top:40px;
				@media (max-width: $doubleTeaserBreakpoint) {margin-top:10px;}
			}
			
		}//chooseKindOfVoucher
	}//giftVoucherContainer

	.giftVoucherChooseTheme{
	
		#showDesktop{
			display:block;
			@media (max-width: $tileBreakpoint) {display:none;}
			

			#marginContainer{
				margin:0 auto;
				position:relative;
				width:695px;
				@media (max-width: $doubleTeaserBreakpoint) {width:417px;}		
			}			
			#fixedOuterContainer{
				position:relative;
				overflow:hidden;
				height:100px;
				width:695px;
				@media (max-width: $doubleTeaserBreakpoint) {width:417px;}
			}
			
			#fixedContainer{
				position:absolute;
				left:0;
				top:0;
				width:20000em;
			}
			
			.leftArrow, .rightArrow{
				position:absolute;
				top:30px;
				cursor:pointer;
				color:$brand-primary;
			}
			.leftArrow{
				left:-60px;
				@media (max-width: $doubleTeaserBreakpoint) {left:-30px;}	
			}
			.rightArrow{
				right:-60px;
				@media (max-width: $doubleTeaserBreakpoint) {right:-30px;}	
			}
			
			
		}
		#showSmartphone{
			display:none;
			@media (max-width: $tileBreakpoint) {display:block;}
			position:relative;
			height:180px;
			z-index:10;
			
			#fixedOuterContainer{
				position:relative;
				overflow:hidden;
				z-index:11;
				height:180px;
				margin-left:-20px;
				width:340px;
			}
			
			#fixedContainer{
				position:absolute;
				left:47px;
				top:0;
				width:20000em;
				z-index:11;
			}
			
			.leftArrow, .rightArrow{
				position:absolute;
				top:80px;
				z-index:12;
				cursor:pointer;
				color:$brand-primary;
			}
			.leftArrow{left:-20px}
			.rightArrow{right:-20px;}
		}
	
		ul#voucherListDesktop{
			margin:0;
			padding:0;
			li{
				list-style-type:none;
				display:inline-block;
				margin:3px;
				border:4px solid transparent;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				&.active{border-color:$brand-primary}
				cursor:pointer;
				
				img{
					width:125px;
					height:auto;
					
				}
			}
		}
		
		ul#voucherListSmall{
			margin:0;
			padding:0;
			li{
				list-style-type:none;
				display:inline-block;
				margin:3px;
				border:4px solid transparent;
				-webkit-border-radius: 4px;
				-moz-border-radius: 4px;
				border-radius: 4px;
				&.active{border-color:$brand-primary}
				cursor:pointer;
				
				img{
					width:232px;
					height:auto;
					
				}
			}
		}
	}//giftCoucherChooseTheme
	
	.giftVoucherData{
		>div{
			text-align:center;
			//padding:10px;
			@media (max-width: $doubleTeaserBreakpoint) {text-align:left}	
		}
	
		.valueContainer{
			text-align:center;
			margin-bottom:30px;
			>div{
				display:inline;
				padding-top:10px;
				@media (max-width: $tileBreakpoint) {display:block}
			}
			>input{
				display:inline;
				width:70px;margin:0 15px;
				@media (max-width: $tileBreakpoint) {display:block;margin:10px auto 0 auto;}
			}
		}
		
		.emailContainer{
			width:580px;
			text-align:left;
			margin:0 auto 20px auto;
			@media (max-width: $doubleTeaserBreakpoint) {width:auto;margin:0 0 10px 0}
		}
		
		.personalInputContainer{
			width:580px;
			margin:0 auto;
			@media (max-width: $doubleTeaserBreakpoint) {width:100%}
			
			.leftContainer{
				float:left;
				width:280px;
				margin-right:20px;
				@media (max-width: $doubleTeaserBreakpoint) {
					float:none;
					width:calc(100% - 22px);
					margin-right:0;
					margin-left:22px;	
				}
			}
			
			.rightContainer{
				float:left;
				width:280px;
				@media (max-width: $doubleTeaserBreakpoint) {
					float:none;
					width:calc(100% - 22px);
					margin-left:22px;
				}
			}
		}
		
		.backButtonContainer{
			@media (max-width: $tileBreakpoint) {
				.btn{font-size:0.75rem;}
			}
		}
		
		.nextButtonContainer{
			@media (max-width: $tileBreakpoint) {
				.btn{font-size:0.75rem;}
			}
		}

	}//giftVoucherData
	
	.giftVoucherSuccess{
		@media (max-width: $tileBreakpoint) {
			.btn{font-size:0.75rem;}
		}
	}//giftVoucherSuccess
	
	&.giftVoucherDesc{
		color:$font-color;
		
		.image{
			display:block;
			width:1005px;
			height:171px;
			@media (max-width: $doubleTeaserBreakpoint) {
				display:none;
			}
		}
		
		.imagesmall{
			display:none;
			width:319px;
			height:222px;
			margin:0 auto;
			@media (max-width: $doubleTeaserBreakpoint) {
				display:block;
			}
			@media (max-width: $tileBreakpoint) {
				max-width:300px;
				background-size:300px auto;	
			}
		}
	}//giftVoucherDesc
	
	.backButtonContainer{
		float:left;
		
		@media (max-width: $tileBreakpoint) {
			width:50%;
			padding-right:5px;
			.btn{display:block}
		}
	}
	
	.nextButtonContainer{
		float:right;
		
		@media (max-width: $tileBreakpoint) {
			width:50%;
			padding-left:5px;
			.btn{display:block}
		}
	}
	
	&.success{
		#gutscheinconnection-container1 img{width:100%;height:auto;max-width:756px}
	}
	
	.estDelivery {
		color:$brand-primary;
		font-weight: bold; 
		
		#delText {
			display: inline-block;
    		vertical-align: text-top;
		}
	}
	
	.voucherContainer{
			
		.desciptionBoxPoints{
			float:left;
			width:400px;
			@media (max-width: $doubleTeaserBreakpoint) {width:380px;}
			@media (max-width: $tileBreakpoint) {width:230px;position:initial !important}			
			.headline{
				padding-top:3px;
			}
			.desc{
				display:none;
				@media (max-width: $doubleTeaserBreakpoint) {display:block}
			}
		}

	}//voucherContainer  
   
}//checkoutContainer

.countrySwitchLightbox{
	@media (max-width: $doubleTeaserSecondBreakpoint) {
		height:300px;
	}
}

.blueBackgroundNumber{
	background-color:#757575;
	display:inline-block;
	width:24px;
	height:24px;
	-webkit-border-radius: 12px;
	-moz-border-radius: 12px;
	border-radius: 12px;
	text-align:center;
	color:$white;
	padding-right:0 !important;
	&.active{background-color:$brand-primary}
	em{
		font-style:normal;
		font-weight:bold;
		font-size:1rem;
	}
}//blueBackgroundNumber

.filterBoxSmall{
	background-color: $white;
    border-bottom: 4px solid $pressFilterGrey;
    -webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
    color: $pressFilterGrey;
    display: block;
    font-weight: bold;
    padding: 9px 15px 8px 13px;
    text-decoration: none !important;
    white-space: nowrap;
    width:50px;
    height:50px;
    margin:0 auto;
    
    .blueBackgroundNumber em{
    	display:inline-block;
    	padding-top:4px;
    }
}//filterBoxSmall
   
   
   
   
   